import { BufferAttribute, BufferGeometry, Loader, LoadingManager, Sphere, TypedArray, Vector3, Vector3Tuple } from 'three'
import isEmpty from 'lodash-es/isEmpty'
import vtkWorker from './vtk.worker?worker'

type Attribute = {
  array: TypedArray
  itemSize: number
  normalized: boolean
}
type Group = {
  start: number
  count: number
  materialIndex: number
}
type TransferableJSON = {
  type: string
  index: Attribute
  attributes: Record<string, Attribute>
  groups: Group[]
  drawcalls: Group[]
  offsets: Group[]
  boundingSphere: {
    center: Vector3Tuple
    radius: number
  }
}

export class VTKLoader extends Loader<BufferGeometry> {
  worker = new vtkWorker()

  constructor(manager?: LoadingManager) {
    super(manager)
  }

  override load(url: string, onLoad: (data: BufferGeometry) => void, onProgress?: (event: ProgressEvent) => void, onError?: (err: unknown) => void) {
    try {
      if (isEmpty(url)) throw new Error('url cannot be empty!')
      this.worker.onmessage = (event: MessageEvent<{ data: TransferableJSON; type: string }>) => {
        const { data } = event
        if (data.type === 'BufferGeometry') {
          onLoad(this.parseTransferableJSON(data))
        } else {
          onError?.('unsupported data type')
        }
      }
      this.worker.postMessage({ url })
    } catch (err) {
      this.worker.terminate()
      onError?.(err)
    }
  }

  parseTransferableJSON = (json: { data: TransferableJSON }) => {
    var geometry = new BufferGeometry()
    var index = json.data.index

    if (index) {
      geometry.setIndex(
        new BufferAttribute(index.array, index.itemSize, index.normalized)
      )
    }

    var attributes = json.data.attributes
    for (var key in attributes) {
      var attribute = attributes[key]
      geometry.setAttribute(
        key,
        new BufferAttribute(
          attribute.array,
          attribute.itemSize,
          attribute.normalized
        )
      )
    }

    var groups = json.data.groups || json.data.drawcalls || json.data.offsets

    if (groups) {
      for (var i = 0, n = groups.length; i !== n; ++i) {
        var group = groups[i]
        geometry.addGroup(group.start, group.count, group.materialIndex)
      }
    }

    var boundingSphere = json.data.boundingSphere
    if (boundingSphere) {
      var center = new Vector3()
      if (boundingSphere.center) {
        center.fromArray(boundingSphere.center)
      }

      geometry.boundingSphere = new Sphere(center, boundingSphere.radius)
    }

    return geometry
  }
}
