import { Box3, Box3Helper, BoxGeometry, BufferGeometry, CylinderGeometry, DoubleSide, ExtrudeGeometry, Mesh, MeshBasicMaterial, Object3D, Raycaster, Shape, Vector2Like, Vector3, Vector3Like } from "three"
import store, { AppStore } from "~/store"
import { Door, ElevationPoint, Obstruction, Product, Roof, UtilityBox, Wall, WallSegment, ElevationLine } from "~/store/objects/types"
import { weakMapMemoize } from "@reduxjs/toolkit"
import { ApolloClient, NormalizedCache } from "@apollo/client"
import { graphql } from "~/gql"
import client from "~/client"
// @ts-expect-error another PR will introduce this
import cdt2d from 'cdt2d'
import Primitives from "./primitives"
import { uiToModel } from "../util/units"

type Tuple<T, L extends number> = L extends L ? number extends L ? T[] : _TupleOf<T, L, []> : never
type _TupleOf<T, L extends number, R extends unknown[]> = R['length'] extends L ? R : _TupleOf<T, L, [T, ...R]>

function slidingWindow<T, L extends number>(array: T[], windowSize: L): Tuple<T, L>[] {
  return Array.from(
    { length: array.length - (windowSize - 1) },
    (_, idx) => array.slice(idx, idx+windowSize)
  ) as Tuple<T, L>[]
}

function negate({ x, y, z }: Vector3Like) {
  return { x: -x, y: -y, z: -z }
}
function add({ x: x1, y: y1, z: z1 }: Vector3Like, { x: x2, y: y2, z: z2 }: Vector3Like) {
  return { x: x1+x2, y: y1+y2, z: z1+z2 }
}
function sub(a: Vector3Like, b: Vector3Like) {
  return add(a, negate(b))
}
function magnitude({ x, y, z }: Vector3Like) {
  return Math.sqrt(x*x + y*y + z*z)
}

function pointInPolygon(point: Vector2Like, points: Vector2Like[]) {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  const x = point.x
  const y = point.y

  let inside = false
  let i = 0
  for (
    let j = points.length - 1;
    i < points.length;
    j = i - 1
  ) {
    const xi = points[i].x
    const yi = points[i].y
    const xj = points[j].x
    const yj = points[j].y

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside

    i += 1
  }

  return inside
}

function isWithinFacility(position: Vector3Like, walls: Readonly<Record<string, Wall>>, segments: Readonly<Record<string, WallSegment>>): boolean {
  for (const wall of Object.values(walls)) {
    // if it's not a wall we don't care
    if (wall.layerKey !== "EXTERIOR_WALLS") {
      continue
    }
    // if it's not enclosed we also don't care
    if (!wall.isEnclosed) {
      continue
    }
    const segmentIDs = wall.segments

    // search for a discontinuity
    const windows = slidingWindow(segmentIDs, 2)
    let discontinuity: [string, string] | undefined = undefined
    for (const [prevID, nextID] of windows) {
      const nextSegment = segments[nextID]
      const prevSegment = segments[prevID]

      if (magnitude(sub(prevSegment.endPoint, nextSegment.startPoint)) >= 0.01) {
        discontinuity = [prevID, nextID]
        break
      }
    }

    // if there's a discontinuity we obviously can't test it for inclusion
    if (discontinuity !== undefined) {
      continue
    }

    // we reconstruct the walls the way the DrawingCanvas/lib/wall.js does them
    const polygonPoints: Vector3Like[] = []

    segmentIDs.forEach(segment => {
      polygonPoints.push(segments[segment].startPoint)
    })

    const lastSegment = segmentIDs[segmentIDs.length - 1]
    if (lastSegment) {
      polygonPoints.push(segments[lastSegment].endPoint)
    }

    if (pointInPolygon(position, polygonPoints)) {
      return true
    }
  }
  return false
}

function segmentMesh(segment: Readonly<WallSegment>) {
  const shape = new Shape()
    .moveTo(segment.insetPoints.start.x, segment.insetPoints.start.y)
    .lineTo(segment.insetPoints.end.x, segment.insetPoints.end.y)
    .lineTo(segment.outsetPoints.end.x, segment.outsetPoints.end.y)
    .lineTo(segment.outsetPoints.start.x, segment.outsetPoints.start.y)
    .closePath()

  const mesh = new Mesh(new ExtrudeGeometry(shape, {
    steps: 1,
    depth: segment.height,
    bevelEnabled: false,
  }))

  mesh.userData["segmentID"] = segment.id

  return mesh
}

const memoizedSegmentMesh = weakMapMemoize(segmentMesh)

function wallMeshes(segments: Readonly<Record<string, WallSegment>>) {
  return new Map(Object.values(segments).map(segment => [segment.id, memoizedSegmentMesh(segment)]))
}

const memoizedWallMeshes = weakMapMemoize(wallMeshes)

async function productMesh(client: ApolloClient<NormalizedCache>, product: Readonly<Product>): Promise<Object3D> {
  if (product.category === 'HEAT') {
    const { data } = await client.query({
      query: graphql(`
        query ProductDistanceEngineHeaterData($variationID: ID!) {
          ProductVariation(id: $variationID) {
            id
            heaterData {
              id
              angle
              blowerDepthE
              boxHeightA
              boxWidthB
              burnerBoxClearanceWidth
              burnerBoxWidth
              irhClearanceB
              irhClearanceC
              irhClearanceD
              minTubeLength
              tubeDiameter
              uhClearanceAccessPanel
              uhClearanceBottom
              uhClearanceFlueConnector
              uhClearanceNonAccessSide
              uhClearanceRear
              uhClearanceTop
            }
          }
        }
      `),
      variables: {
        variationID: product.variationId,
      }
    })

    const HeaterData =
      product.model === 'Unit Heater'
        ? data.ProductVariation!.heaterData![0]
        : data.ProductVariation!.heaterData!.find(
            h =>
              h.angle === Math.abs(product.rotation.x) ||
              (h.angle === 90 && 0 === product.rotation.x)
          )!
    const width =
      product.model === 'Unit Heater'
        ? HeaterData.uhClearanceNonAccessSide! +
            HeaterData.boxWidthB! +
            HeaterData.uhClearanceAccessPanel!
        : 6 + // 6 inch clearance on end
            HeaterData.minTubeLength! +
            HeaterData.burnerBoxWidth! +
            HeaterData.burnerBoxClearanceWidth!
    const depth =
      product.model === 'Unit Heater'
        ? HeaterData.uhClearanceFlueConnector! +
            HeaterData.blowerDepthE! +
            HeaterData.uhClearanceRear!
        : HeaterData.irhClearanceB! +
            HeaterData.tubeDiameter! +
            HeaterData.irhClearanceD!
    const height =
      product.model === 'Unit Heater'
        ? HeaterData.uhClearanceTop! +
            HeaterData.boxHeightA! +
            HeaterData.uhClearanceBottom!
        : HeaterData.irhClearanceC!

    const clearanceBox = new BoxGeometry(width, depth, height, 32)

    if (product.rotation.y === 90 || product.rotation.y === 270)
      clearanceBox.rotateZ(Math.PI / 2)
    const mesh = new Mesh(clearanceBox)
    mesh.position.copy(product.position)
    mesh.geometry.computeBoundingSphere()
    mesh.updateMatrixWorld()

    return mesh
  } else {
    const { data } = await client.query({
      query: graphql(`
        query ProductDistanceEngineFanData($variationID: ID!) {
          ProductVariation(id: $variationID) {
            id
            size
          }
        }
      `),
      variables: {
        variationID: product.variationId,
      }
    })

    const radius = data.ProductVariation!.size / 2
    const clearanceHeight = data.ProductVariation!.size * 2
    const clearanceCylinder = new CylinderGeometry(
      radius,
      radius,
      clearanceHeight,
      32
    )

    clearanceCylinder.rotateX(Math.PI / 2)
    const mesh = new Mesh(clearanceCylinder)
    mesh.position.copy(product.position)
    mesh.geometry.computeBoundingSphere()
    mesh.updateMatrixWorld()

    return mesh
  }
}

const memoizedProductMesh = weakMapMemoize(productMesh)

async function productMeshes(client: ApolloClient<NormalizedCache>, products: Readonly<Record<string, Product>>) {
  const entries =
    await Promise.all(
        Object.values(products)
          .map(async product => [product.id, await memoizedProductMesh(client, product)] as const)
        )
  return new Map(entries)
}

const memoizedProductMeshes = weakMapMemoize(productMeshes)

function doorMesh(door: Readonly<Door>, wall: Readonly<WallSegment>) {
  const { x, y } = door.position
  const z = door.height / 2

  const { width, height } = door
  const thickness = wall.thickness + 0.2

  if (door.doorType === "rollbackDoor") {
    const offset = Math.abs(height / 2.0)

    const geometry = new BoxGeometry(width, height, thickness / 2)
    const mesh = new Mesh(geometry)
    mesh.position.set(x - offset, y, z + offset)

    return mesh
  } else if (door.doorType === "verticalDoor") {
    const offset = Math.abs(thickness / 2.0)

    const geometry = new BoxGeometry(thickness / 2, width, height)
    const mesh = new Mesh(geometry)
    mesh.position.set(x - offset, y, z + height)

    return mesh
  } else {
    return new Object3D()
  }
}

const memoizedDoorMesh = weakMapMemoize(doorMesh)

function doorMeshes(doors: Readonly<Record<string, Door>>, walls: Readonly<Record<string, WallSegment>>) {
  return new Map(
    Object.values(doors)
      .filter(door => (door.doorType === "rollbackDoor" || door.doorType === "verticalDoor") && door.wallSegmentId in walls)
      .map(door => [door.id, memoizedDoorMesh(door, walls[door.wallSegmentId])])
  )
}

const memoizedDoorMeshes = weakMapMemoize(doorMeshes)

function obstructionMesh(obstruction: Readonly<Obstruction>) {
  return Primitives.getCustomMesh(obstruction.positions, obstruction.height)
}

const memoizedObstructionMesh = weakMapMemoize(obstructionMesh)

function obstructionMeshes(obstructions: Readonly<Record<string, Obstruction>>) {
  return new Map(
    Object.values(obstructions)
      .map(obstruction => [obstruction.id, memoizedObstructionMesh(obstruction)])
  )
}

const memoizedObstructionMeshes = weakMapMemoize(obstructionMeshes)

const UTILITY_BOX_DEFAULT_THICKNESS = 24

function utilityBoxMesh(box: Readonly<UtilityBox>) {
  const thickness = (box.thickness || UTILITY_BOX_DEFAULT_THICKNESS) / 2 + 24
  const {width, height} = box

  const geometry = new BoxGeometry(
    width,
    height,
    thickness
  )

  const mesh = new Mesh(geometry)
  mesh.position.set(box.position.x, box.position.y, box.centerPointHeight)

  const z = 'z' in box.rotation ? box.rotation.z : box.rotation._z
  mesh.rotation.set(0, 0, z)

  return mesh
}

const memoizedUtilityBoxMesh = weakMapMemoize(utilityBoxMesh)

function utilityBoxMeshes(boxes: Readonly<Record<string, UtilityBox>>) {
  return new Map(
    Object.values(boxes)
      .map(box => [box.id, memoizedUtilityBoxMesh(box)])
  )
}

const memoizedUtilityBoxMeshes = weakMapMemoize(utilityBoxMeshes)

// TODO: unify this with the PR
function roofMesh(roof: Readonly<Roof>, lines: Readonly<Record<string, ElevationLine>>, points: Readonly<Record<string, ElevationPoint>>) {
  const perimeterPoints = roof.perimeterPoints.map(([x, y]) => [x, y, roof.height])
  perimeterPoints.pop() // Remove duplicate start/end point
  const edgeIndices = perimeterPoints.reduce<[number, number][]>((indices, _, i, { length }) => {
    indices.push([i, (i + 1) % length])
    return indices
  }, [])
  const elevationPointIndices = new Map(
    Object.values(points).map(({ id }, i) => [id, i + perimeterPoints.length])
  )
  Object.values(lines).forEach(({ elevationPointIds }) => {
    const [idA, idB] = elevationPointIds
    const indexA = elevationPointIndices.get(idA ?? '')
    const indexB = elevationPointIndices.get(idB ?? '')
    if (!indexA || !indexB) return
    edgeIndices.push([indexA, indexB])
  })
  const elevationPoints = Object.values(points).map<[number, number, number]>(
    ({ position }) => {
      const { x, y, z } = position
      return [uiToModel(x), uiToModel(y), uiToModel(z)]
    }
  )

  const allPoints = perimeterPoints.concat(elevationPoints)
  const points3d = allPoints.map(([x, y, z]) => new Vector3(x, y, z))
  const triangles = cdt2d(allPoints, edgeIndices, { exterior: false })

  const geometry = new BufferGeometry().setFromPoints(points3d)
  geometry.setIndex(triangles.flat())
  geometry.computeVertexNormals()

  return new Mesh(geometry, new MeshBasicMaterial({ side: DoubleSide }))
}

const memoizedRoofMesh = weakMapMemoize(roofMesh)

function roofMeshes(roofs: Readonly<Record<string, Roof>>, lines: Readonly<Record<string, ElevationLine>>, points: Readonly<Record<string, ElevationPoint>>) {
  return new Map(
    Object.values(roofs)
      .map(roof => [roof.id, memoizedRoofMesh(roof, lines, points)])
  )
}

const memoizedRoofMeshes = weakMapMemoize(roofMeshes)

const DIMENSIONS = [
  new Vector3(0, 1, 0),
  new Vector3(0, -1, 0),
  new Vector3(1, 0, 0),
  new Vector3(-1, 0, 0),
  new Vector3(1, 1, 0),
  new Vector3(1, -1, 0),
  new Vector3(-1, 1, 0),
  new Vector3(-1, -1, 0),
].map(v => v.normalize())

export type IntersectionData = [id: string, distance: number, location: Vector3]

class ProductDistanceEngine {
  walls: Readonly<Record<string, Wall>>
  segmentMeshes: Readonly<Map<string, Mesh>>
  segments: Readonly<Record<string, WallSegment>>
  productMeshes: Readonly<Map<string, Object3D>>
  doorClearanceMeshes: Readonly<Map<string, Object3D>>
  obstructionMeshes: Readonly<Map<string, Object3D>>
  utilityBoxMeshes: Readonly<Map<string, Object3D>>
  roofMeshes: Readonly<Map<string, Object3D>>

  constructor(store: AppStore) {
    const { objects, segments, doors, obstructions, utilityBoxes, roofs, elevationLines, elevationPoints } = store.getState().objects.present

    this.walls = objects
    this.segments = segments
    this.segmentMeshes = memoizedWallMeshes(this.segments)
    this.productMeshes = new Map()
    this.doorClearanceMeshes = memoizedDoorMeshes(doors, this.segments)
    this.obstructionMeshes = memoizedObstructionMeshes(obstructions)
    this.utilityBoxMeshes = memoizedUtilityBoxMeshes(utilityBoxes)
    this.roofMeshes = memoizedRoofMeshes(roofs, elevationLines, elevationPoints)
    memoizedProductMeshes(client, store.getState().objects.present.products).then(it => {
      this.productMeshes = it
    })

    store.subscribe(() => {
      const { objects, segments, doors, obstructions, utilityBoxes, roofs, elevationLines, elevationPoints } = store.getState().objects.present

      this.walls = objects
      this.segments = segments
      this.segmentMeshes = memoizedWallMeshes(this.segments)
      this.doorClearanceMeshes = memoizedDoorMeshes(doors, this.segments)
      this.obstructionMeshes = memoizedObstructionMeshes(obstructions)
      this.utilityBoxMeshes = memoizedUtilityBoxMeshes(utilityBoxes)
      this.roofMeshes = memoizedRoofMeshes(roofs, elevationLines, elevationPoints)
      memoizedProductMeshes(client, store.getState().objects.present.products).then(it => {
        this.productMeshes = it
      })
    })
  }
  isWithinFacility(position: Vector3Like): boolean {
    return isWithinFacility(position, this.walls, this.segments)
  }
  nearestWallIntersection(pos: Vector3Like): [string, number, Vector3] | null {
    const position = new Vector3().copy(pos)
    const ray = new Raycaster(position)

    let distance = Infinity
    let target = new Vector3()
    let objectID: undefined | string = undefined

    this.segmentMeshes.forEach((segment, id) => {
      DIMENSIONS.forEach(direction => {
        ray.set(position, direction)

        const intersections = ray.intersectObject(segment)
        for (const intersection of intersections) {
          if (intersection.distance <= distance) {
            target.copy(intersection.point)
            distance = intersection.distance
            objectID = id
          }
        }
      })
    })

    if (objectID === undefined) {
      return null
    }
    return [objectID, distance, target]
  }
  nearestProductIntersection(pos: Vector3Like, ignoring?: string): [string, number, Vector3] | null {
    const position = new Vector3().copy(pos)
    const ray = new Raycaster(position)

    let distance = Infinity
    let target = new Vector3()
    let objectID: undefined | string = undefined

    this.productMeshes.forEach((product, id) => {
      if (id === ignoring) {
        return
      }
      DIMENSIONS.forEach(direction => {
        ray.set(position, direction)

        const intersections = ray.intersectObject(product)
        for (const intersection of intersections) {
          if (intersection.distance <= distance) {
            target.copy(intersection.point)
            distance = intersection.distance
            objectID = id
          }
        }
      })
    })

    if (objectID === undefined) {
      return null
    }
    return [objectID, distance, target]
  }
  nearestObstructionIntersection(pos: Vector3Like): [string, number, Vector3] | null {
    const position = new Vector3().copy(pos)
    const ray = new Raycaster(position)

    let distance = Infinity
    let target = new Vector3()
    let objectID: undefined | string = undefined

    for (const meshCollection of [this.doorClearanceMeshes, this.obstructionMeshes, this.utilityBoxMeshes, this.roofMeshes]) {
      meshCollection.forEach((product, id) => {
        DIMENSIONS.forEach(direction => {
          ray.set(position, direction)

          const intersections = ray.intersectObject(product)
          for (const intersection of intersections) {
            if (intersection.distance <= distance) {
              target.copy(intersection.point)
              distance = intersection.distance
              objectID = id
            }
          }
        })
      })
    }

    if (objectID === undefined) {
      return null
    }
    return [objectID, distance, target]
  }
}

export const productDistanceEngine = new ProductDistanceEngine(store)
