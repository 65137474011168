export const LAYERS_PANEL = 'LAYERS_PANEL'
export const SNAPSHOTS_PANEL = 'SNAPSHOTS_PANEL'
export const AIRFLOW_PANEL = 'AIRFLOW_PANEL'
export const CFD_PANEL = 'CFD_PANEL'
export const DEFAULT_SELECTED_PANEL = 'DEFAULT_SELECTED_PANEL'
export const DETAILED_OBSTRUCTION_PANEL = 'DETAILED_OBSTRUCTION_PANEL'
export const SELECTED_WALL_PANEL = 'SELECTED_WALL_PANEL'
export const SELECTED_WALL_SEGMENT_PANEL = 'SELECTED_WALL_SEGMENT_PANEL'
export const SELECTED_PRODUCT_PANEL = 'SELECTED_PRODUCT_PANEL'
export const SELECTED_OBSTRUCTION_PANEL = 'SELECTED_OBSTRUCTION_PANEL'
export const SELECTED_ROOF_PANEL = 'SELECTED_ROOF_PANEL'
export const SELECTED_ROOF_SECTION_PANEL = 'SELECTED_ROOF_SECTION_PANEL'
export const SELECTED_CEILING_PANEL = 'SELECTED_CEILING_PANEL'
export const SELECTED_ELEVATION_POINT_PANEL = 'SELECTED_ELEVATION_POINT_PANEL'
export const SELECTED_ELEVATION_LINE_PANEL = 'SELECTED_ELEVATION_LINE_PANEL'
export const GET_QUOTE_PANEL = 'GET_QUOTE_PANEL'
export const SELECTED_COMFORT_ZONE_PANEL = 'SELECTED_COMFORT_ZONE_PANEL'
export const SELECTED_DOOR_PANEL = 'SELECTED_DOOR_PANEL'
export const SELECTED_UTILITY_BOX_PANEL = 'SELECTED_UTILITY_BOX_PANEL'
export const SELECTED_BACKGROUND_IMAGE_PANEL = 'SELECTED_BACKGROUND_IMAGE_PANEL'
export const PERFORMANCE_METRICS_PANEL = 'PERFORMANCE_METRICS_PANEL'
export const DOCK_ITEM_PANEL = 'DOCK_ITEM_PANEL'
export const CONTROLS_PANEL = 'CONTROLS_PANEL'
export const HEAT_MAP_PANEL = 'HEAT_MAP_PANEL'
