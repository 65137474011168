import {
  string,
  number,
  instanceOf,
  bool,
  shape,
  array,
} from 'prop-types'
import isArray from 'lodash-es/isArray'

import { SYSTEMS } from 'store/units/constants'
import { Distance } from 'store/units/types'

export const CFDUrl = shape({
  fileProps: shape({
    type: string,
    level: string,
  }),
  fileExtension: string,
  url: string,
})

const Geometry = shape({
  attributes: shape({
    velocity: shape({
      array: instanceOf(Float32Array),
      count: number,
    }),
  }),
})

export const CFDModel = shape({
  geometry: Geometry,
  isValid: bool,
  validTypes: array,
  isLoading: bool,
  url: string,
})

const getDistanceValue = (value, distanceUnits, both = true) =>
  new Distance({
    value,
    system: SYSTEMS.IMPERIAL,
  }).formattedValue(distanceUnits, {
    both,
    roundCentimeters: true,
    round: true,
  })

export const getSuggestedImageData = (
  distanceUnits = SYSTEMS.IMPERIAL,
  image
) => {
  let parsedNumber = 0
  const types = [
    'streamlinesFine',
    'streamlinesCoarse',
    'destrat',
    'UftByMin_x',
    'UftByMin_y',
    'T_x',
    'T_y',
  ]
  const match = /_z(.*?)inch/.exec(image)
  const temperatureMatch = /T_z(.*?)inch/.exec(image)
  const intensityMatch = /intensity_zNormal_([A-Za-z]+)/.exec(image)
  const height = match ? match[1] : null
  const key = height || types.find(type => image.includes(type))

  if (temperatureMatch) {
    const temperatureHeight = temperatureMatch[1]
    const turnoverMatch = /T_z[\d]+inch-([\d]+)turnover/.exec(image)?.[1]
    const turnoverValue = parseInt(turnoverMatch) / 100
    const turnoverLabel = isNaN(turnoverValue) ? "": ` (${turnoverValue} Air Turnover${turnoverValue === 1 ? '':'s'})`
    return {
      title: `${getDistanceValue(temperatureHeight, distanceUnits, false)}`,
      description: `Temperature at ${getDistanceValue(
        temperatureHeight,
        distanceUnits,
        false
      )} above floor${turnoverLabel}`,
    }
  }

  if (intensityMatch) {
    const type = intensityMatch[1]
    const defaultLabel = "IRH Intensity"
    const isoLabel = "IRH Intensity - Isometric"
    const planLabel = "IRH Intensity - Plan"
    const description = "Heat Intensity on a scale of 1 to 10"
    if (!type) {
      return {
        title: defaultLabel,
        description
      }
    } else {
      return {
        title: type === 'planX' ? planLabel : isoLabel,
        description
      }

    }
  }

  if (height) {
    return {
      title: `${getDistanceValue(
        height,
        distanceUnits,
        false
      )} Overhead Airflow`,
      description: `Airflow at ${getDistanceValue(
        height,
        distanceUnits,
        false
      )} above floor`,
    }
  }

  switch (key) {
    case 'streamlinesFine':
      return {
        title: 'Streamline (Fine)',
        description:
          '3D view of air movement (20 streamlines) throughout the space',
      }
    case 'streamlinesCoarse':
      return {
        title: 'Streamline (Coarse)',
        description:
          '3D view of air movement (5 streamlines) throughout the space',
      }
    case 'destrat':
      return {
        title: 'Destratification',
        description: 'Destratification airflow at roof level',
      }
    case 'UftByMin_x':
      parsedNumber = image[image.indexOf(key) + 10]
      return {
        title: `Fan ${parsedNumber} - Section`,
        description: `Show a side view of Fan ${parsedNumber}, oriented along the X-axis`,
      }
    case 'UftByMin_y':
      parsedNumber = image[image.indexOf(key) + 10]
      return {
        title: `Fan ${parsedNumber} - Section`,
        description: `Show a side view of Fan ${parsedNumber}, oriented along the Y-axis`,
      }
    case 'T_x':
      parsedNumber = image[image.indexOf(key) + 3]
      return {
        title: `Fan ${parsedNumber} - Section`,
        description: `Show a side view of Fan ${parsedNumber}, oriented along the X-axis`,
      }
    case 'T_y':
      parsedNumber = image[image.indexOf(key) + 3]
      return {
        title: `Fan ${parsedNumber} - Section`,
        description: `Show a side view of Fan ${parsedNumber}, oriented along the Y-axis`,
      }
    default:
      return { title: '', description: '' }
  }
}

// Ensure backwards compatibility.
// Old way CFD is an object. New way it's an array of cooling and destrat
export function convertCFDDataToArray(cfdData) {
  let cfds = []
  if (isArray(cfdData)) cfds = cfdData
  else cfds.push({ ...cfdData, type: 'COOLING' })
  return cfds
}