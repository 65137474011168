import React, { Component } from 'react'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import {
  oneOfType,
  object,
  objectOf,
  arrayOf,
  shape,
  bool,
  func,
  string,
  number,
  any,
} from 'prop-types'
import isEqual from 'lodash-es/isEqual'
import find from 'lodash-es/find'
import get from 'lodash-es/get'
import omit from 'lodash-es/omit'

import { withFans, withObjects, withWireframe } from './selectors'
import { getBase64FromImage } from './utils'

import PreviewPDF from '../PreviewPDF'

import { withPDFNames } from './selectors'

import Loader from 'components/UIKit/Loader'

import { CFDModel } from 'lib/cfd'

import withPDFFileName from 'client/decorators/withPDFFileName'
import withFacility from 'client/decorators/withFacility'
import withUser from 'client/decorators/withUser'

import { withUnits } from 'store/units/decorators'

import { showAlert } from 'store/alert'

import cloudinary from 'config/cloudinary'

const IMAGE_FILE_TYPES = ['png', 'jpeg']
class Preview extends Component {
  static defaultProps = {
    showSchedule: true,
    cfdLevel: '43',
    cfdType: 'overhead',
    setExportFunction: () => {},
  }

  state = {
    rendering: false,
    rendered: false,
    loading: true,
  }

  async componentDidMount() {
    await this.checkLoadingData()
    this.setState({ loading: this.isLoading() })
  }

  async componentDidUpdate() {
    await this.checkLoadingData()
    this.setState({ loading: this.isLoading() })
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !this.state.rendered &&
      (!isEqual(this.props, nextProps) ||
        !isEqual(omit(this.state, ['rendered']), omit(nextState, ['rendered'])))
    )
  }

  async checkLoadingData() {
    if (this.props.showObjectInventory && !this.state.objectImages) {
      await this.getBase64ObjectImages(this.props.objectsToShow)
    }
  }

  isLoading() {
    return (
      (this.props.showObjectInventory && !this.state.objectImages) ||
      !this.props.fans ||
      (this.props.showAdditionalImages && !this.props.additionalImages)
    )
  }

  base64ImageValid(base64) {
    const dataPrefix = base64.split(';')[0]
    const fileType = dataPrefix.split('/')[1]
    return IMAGE_FILE_TYPES.includes(fileType)
  }

  async getBase64ObjectImages(objects) {
    const images = {}
    const promises = []
    Object.entries(objects).forEach(([type, objectGrouping]) => {
      Object.entries(objectGrouping).forEach(([key, object]) => {
        const objData = this.props.objects[type][key]
        images[key] = []
        if (objData && objData.metadata && objData.metadata.images) {
          objData.metadata.images.forEach(({ cloudinaryId, width, height }) => {
            const maxHeight = 100
            const maxWidth = 150
            const multiplier = maxHeight / height
            const actualWidth = Math.min(width * multiplier, maxWidth)
            const url = cloudinary.url(cloudinaryId, {
              height: maxHeight,
              width: actualWidth,
              crop: 'limit',
              secure: true,
            })
            const originalUrl = cloudinary.url(cloudinaryId, {
              secure: true,
            })

            const promise = getBase64FromImage(url).then(base64 => {
              if (this.base64ImageValid(base64)) {
                images[key].push({
                  base64,
                  width: actualWidth,
                  url,
                  originalUrl,
                })
              }
            })
            promises.push(promise)
          })
        }
      })
    })

    await Promise.all(promises)

    this.setState({ objectImages: images })
  }

  handlePdfDidRender = () => {
    this.setState({ rendered: true })
  }

  get name() {
    const facility = get(this.props, 'facility')
    // We're doing this because `facility.floor.name` is
    // not changing when switching facilities.
    const currentArea =
      find(get(facility, 'floors'), floor => floor.id === get(this.props, 'floorId')) ||
      get(facility, 'floor')

    const areaName = get(currentArea, 'name', '')
    const numberOfAreas = get(facility, 'floors', []).length
    const hasOneArea = numberOfAreas <= 1
    const hasDefaultName =
      areaName.match(/area 1/i) || areaName.match(/floor 1/i) || areaName === ''
    const ignoreArea = hasOneArea && hasDefaultName
    if (ignoreArea) return get(facility, 'name')
    return `${get(facility, 'name')} - ${areaName}`
  }

  render() {
    const { rendering, loading } = this.state
    if (loading || rendering) return <Loader centered label="Loading PDF assets…" />
    return (
      <PreviewPDF
        additionalImages={this.props.showAdditionalImages ? this.props.additionalImages : []}
        imagesPerPage={this.props.imagesPerPage}
        comfortZones={this.props.showComfortZoneMetrics ? this.props.comfortZones : undefined}
        contactInfo={this.props.customContactInfo}
        comments={this.props.comments}
        customerName={this.props.customerName}
        fans={this.props.fans}
        fileName={this.props.fileName}
        handlePdfDidRender={() => this.handlePdfDidRender()}
        logoSrc={`${window.location.origin}/android-chrome-512x512.png`}
        mainImage={this.props.mainImage}
        objects={this.props.objects}
        objectsToShow={this.props.objectsToShow}
        objectImages={this.state.objectImages}
        pdfType={this.props.docType}
        rendered={this.state.rendered}
        salesRepEmail={this.props.salesRepEmail}
        salesRepName={this.props.salesRepName}
        savedMainImageSnapshot={this.props.savedMainImageSnapshot}
        setExportFunction={this.props.setExportFunction}
        showComments={this.props.showComments}
        showAdditionalImages={this.props.showAdditionalImages}
        showDocumentExplanation={this.props.showDocumentExplanation}
        showDetailedView={this.props.showDetailedView}
        detailedType={this.props.detailedType}
        showFPMGrid={this.props.showFPMGrid}
        showObjectInventory={this.props.showObjectInventory}
        showDeckHeight={this.props.showDeckHeight}
        facilityUnits={this.props.facilityUnits}
        showSchedule={this.props.showSchedule}
        versionId={this.props.versionId || get(this.props, 'facility.floor.version.id')}
        name={this.name}
        showMountStructureLines={this.props.showMountStructureLines}
        showDimensionLines={this.props.showDimensionLines}
        coolingImagesPerPage={this.props.coolingImagesPerPage}
        destratImagesPerPage={this.props.destratImagesPerPage}
        heatingImagesPerPage={this.props.heatingImagesPerPage}
        selectedCoolingImages={this.props.selectedCoolingImages}
        selectedDestratImages={this.props.selectedDestratImages}
        selectedHeatingImages={this.props.selectedHeatingImages}
      />
    )
  }
}

Preview.propTypes = {
  fileName: string,
  showAlert: func,
  mainImage: string,
  showSchedule: bool,
  showFPMGrid: bool,
  showObjectInventory: bool,
  showAdditionalImages: bool,
  showDeckHeight: bool,
  facilityUnits: string,
  customContactInfo: object,
  showComments: bool,
  comments: string,
  showDocumentExplanation: bool,
  showDetailedView: bool,
  detailedType: string,
  showComfortZoneMetrics: bool,
  showMountStructureLines: bool,
  showDimensionLines: bool,
  comfortZones: arrayOf(
    shape({
      zone: any,
      height: string,
    })
  ),
  additionalImages: arrayOf(
    shape({
      base64: string,
    })
  ),
  imagesPerPage: number,
  savedMainImageSnapshot: shape({
    url: string,
    base64: string,
    naturalWidth: number,
    naturalHeight: number,
  }),
  cfdLevel: string,
  cfdType: string,
  facility: object,
  cfdModels: object,
  objectsToShow: object,
  fans: arrayOf(
    shape({
      tag: oneOfType([string, number]),
      quantity: oneOfType([string, number]),
      description: string,
    })
  ),
  objects: objectOf(shape({})),
  setExportFunction: func,
  cfd: shape({
    models: objectOf(CFDModel),
  }),
  distanceUnits: string,
  velocityUnits: string,
  temperatureUnits: string,
  customerName: string,
  salesRepName: string,
  facilityName: string,
  salesRepEmail: string,
  mainSnapshotImage: string,
  facilityRefNumber: string,
  docType: string,
  versionId: string,
  coolingImagesPerPage: number,
  destratImagesPerPage: number,
  heatingImagesPerPage: number,
  selectedCoolingImages: arrayOf(string),
  selectedDestratImages: arrayOf(string),
  selectedHeatingImages: arrayOf(string),
}

const mapStateToProps = ({ cfd }) => ({
  cfdModels: cfd.models,
  cfd,
})

const mapDispatchToProps = dispatch => ({
  showAlert,
})

export default compose(
  withWireframe,
  withPDFFileName,
  withFacility,
  withUser,
  withPDFNames,
  withFans,
  withObjects,
  withUnits,
  appConnect(mapStateToProps, mapDispatchToProps)
)(Preview)
