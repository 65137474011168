import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import Icon from 'components/UIKit/Icon'
import InfoTooltip from 'components/UIKit/InfoTooltip'
import Label from 'components/UIKit/Label'
import Loader from 'components/UIKit/Loader'
import { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'
import Select from 'components/UIKit/Select'

import Cooling from './styled/Cooling'
import CoolingValue from './styled/CoolingValue'
import Item from './styled/Item'
import StyledLabel from './styled/Label'
import List from './styled/List'

import { Temperature } from 'store/units/types'
import { updateComfortZone } from 'store/objects'

import { getVelocityUnits } from '~/store/units/selectors'
import { useCoolingVtkWorker } from '~/components/PerformanceMetrics/hooks/useVtkWorker'
import { getCoolingThreshold, HEIGHTS } from '~/config/cfd'
import { useFacilityVtkResults, vtkResult } from '~/hooks/useFacilityVtkResults'
import { getComfortZoneEdges } from '~/components/PerformanceMetrics/util/comfortZone'
import { ImperialTemperature, MetricTemperature } from '~/store/units/types/temperature'
import { ImperialVelocity } from '~/store/units/types/velocity'
import { getThermalComfort } from '~/lib/thermalComfortTool'
import { useFormatTemperature } from '~/components/PerformanceMetrics/hooks/useFormatTemperature'
import { useAppSelector } from '~/store/hooks'
import PMVScale from '~/components/PMVScale'

const ELEVATION_OPTIONS = [HEIGHTS.seated, HEIGHTS.standing]

type BerkeleyDataProps = {
  comfortZone: any
  primaryUse: any
  primaryType: any
  airTemp: any
  humidity: any
}
export default function BerkeleyData(props: BerkeleyDataProps) {
  const { data, error, loading } = useFacilityVtkResults()
  const selectedVtkFiles = data?.vtkResults.find(it => it.type === "cooling")

  if (loading) {
    return <Loader />
  } else if (!selectedVtkFiles) {
    return <>CFD data unavailable. Generate a CFD to get results.</>
  } else if (error) {
    return <>Failed to load CFD data.</>
  }
  return <BerkeleyDataView {...props} selectedVtkFiles={selectedVtkFiles} clothingType={data.facility.clothingType} metabolicRate={data.facility.activityLevel} />
}

type BerkeleyDataViewProps = BerkeleyDataProps & {
  selectedVtkFiles: vtkResult
  clothingType: any
  metabolicRate: any
}
function BerkeleyDataView({
  comfortZone,
  primaryUse,
  primaryType,
  airTemp,
  humidity,
  selectedVtkFiles,
  clothingType,
  metabolicRate,
}: BerkeleyDataProps & BerkeleyDataViewProps) {
  const selectedHeight: "standing" | "seated" = comfortZone.selectedHeight ?? ELEVATION_OPTIONS[0]
  const { formatTemperature, formatTemperatureDelta } = useFormatTemperature()
  const comfortZoneEdges = useMemo(() => getComfortZoneEdges(comfortZone.positions), [comfortZone.positions])
  const coverageThreshold = getCoolingThreshold({ primaryType, primaryUse })
  const { loading, data, error } = useCoolingVtkWorker({
    velocityUrls: selectedVtkFiles.vtkUrlGroups.velocity[selectedHeight],
    temperatureUrls: selectedVtkFiles.vtkUrlGroups.temperature[selectedHeight],
    coverageThreshold,
    comfortZoneEdges,
  })
  const velocityUnits = useAppSelector(store => getVelocityUnits(store))
  const dispatch = useDispatch()

  if (loading) {
    return <Loader/>
  } else if (error || !data) {
    return <>Failed to load CFD data.</>
  }

  const { averageTemperature, averageAirVelocity } = data

  const isEvap = averageTemperature !== null
  const averageTemp = isEvap ? new ImperialTemperature(averageTemperature) : new Temperature(airTemp)
  const airVelocity = new ImperialVelocity(averageAirVelocity)

  const { pmv, ppd, set, coolingEffect } = getThermalComfort({
    airVelocity,
    humidity,
    meanAirTemp: averageTemp,
    clothingType,
    metabolicRate,
  })

  return (
    <div key={`${comfortZone.id}-berkeley-data`}>
      <PanelSection>
        <Cooling>
          <Space bottom="base">
            <h3>Perceived Cooling Effect</h3>
          </Space>
          <CoolingValue>
            <Icon name="snowflake" color="fg" />
            <Label type="success">{formatTemperatureDelta(new MetricTemperature(coolingEffect))}</Label>
          </CoolingValue>
        </Cooling>
      </PanelSection>
      <PanelSection title="Advanced Information">
        <Space bottom="base">
          <Select
            name="select"
            label="CFD Level"
            value={selectedHeight}
            options={ELEVATION_OPTIONS.map(label => ({
              label,
              value: label,
            }))}
            onChange={(event: any) => {
              dispatch(updateComfortZone({ comfortZone: { ...comfortZone, selectedHeight: event.target.value }}))
            }}
          />
        </Space>
        <List>
          <Item>
            <StyledLabel>Air Velocity:</StyledLabel>
            {airVelocity.formattedValue(velocityUnits, { round: 2 })}
          </Item>
          <Item>
            <Space bottom="s">
              <StyledLabel>
                <InfoTooltip title="Predicted Mean Vote" iconSize="16px" />
                PMV:
              </StyledLabel>
              {pmv.toFixed(2)}
            </Space>
            <PMVScale value={pmv} />
          </Item>
          <Item>
            <StyledLabel>
              <InfoTooltip
                title="Percentage of Persons Dissatisfied"
                iconSize="16px"
              />
              PPD:
            </StyledLabel>
            {ppd.toFixed(2)}%
          </Item>
          <Item>
            <StyledLabel>
              <InfoTooltip title="Perceived Temperature" iconSize="16px" />
              SET:
            </StyledLabel>
            {formatTemperature(new MetricTemperature(set))}
          </Item>
        </List>
      </PanelSection>
    </div>
  )
}
