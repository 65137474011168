import { graphql } from '~/gql'
import get from 'lodash-es/get'
import isUndefined from 'lodash-es/isUndefined'
import uniqBy from 'lodash-es/uniqBy'

import client from 'client'
import {
  GET_FACILITY_QUERY,
  ALL_FACILITIES_QUERY,
  ALL_FACILITY_TEMPLATES_QUERY,
  ALL_PRIMARY_USES_QUERY,
} from 'client/queries'
import { getAllProducts } from 'client/queries/productsQuery'
import detailedObstructionsData from 'config/detailedObstructions'
import cloudinary from 'config/cloudinary'
import persistor from 'config/persistor'

import { getSnapshotUrl } from 'lib/utils'
import { convertCFDDataToArray } from 'lib/cfd'

import {
  SQUARE_THUMBNAIL_SIZE,
  THUMBNAIL_SIZE,
} from 'components/MetadataSection/MetadataSection'

import { parseCloudinaryUrlTransforms } from 'lib/utils'

export const FACILITY_PAGE_LIMIT = 10
export const FACILITY_LOCAL_STORAGE_KEY = 'BAF:UI:facilities'

function fetchItems(urls) {
  const promises = []
  for (let url of urls) {
    promises.push(
      fetch(url, { cache: 'force-cache' })
        .then(function (response) {
          if (response.ok) return response.blob()
          throw new Error('Network response was not ok.')
        })
        .then(function (blob) {
          if (blob && blob.type.includes('image')) {
            const image = new Image()
            image.src = url
          }
        })
        .catch(function (error) {
          console.log(
            'There has been a problem with your fetch operation: ',
            error.message
          )
        })
    )
  }

  return promises
}

export function createObjectURL(blob) {
  const URL = window.URL || window.webkitURL
  return URL.createObjectURL(blob)
}
export function revokeObjectURL(url) {
  const URL = window.URL || window.webkitURL
  URL.revokeObjectURL(url)
}

export const facilityQueryOptions = ({ facility, floorId, versionId }) => ({
  query: GET_FACILITY_QUERY,
  variables: {
    id: facility.id,
    floorId: floorId || get(facility, 'floor.id', null),
    versionId: versionId || get(facility, 'floor.version.id', null),
  },
})

function cacheSnapshots({ facility }) {
  const snapshots = get(facility, 'data.facility.floor.version.snapshots') || []
  const promises = []

  for (let snapshot of snapshots) {
    const snapshotURLs = [getSnapshotUrl(snapshot)] // snapshots panel

    // default modal snapshot
    snapshotURLs.push(
      cloudinary.url(snapshot.cloudinaryId, {
        x: 0,
        y: 0,
        width: snapshot.data.width,
        height: snapshot.data.height,
        crop: 'crop',
        secure: true,
      })
    )

    // cropped modal snapshot
    if (snapshot.cropData) {
      snapshotURLs.push(
        cloudinary.url(snapshot.cloudinaryId, {
          x: snapshot.cropData.x,
          y: snapshot.cropData.y,
          width: snapshot.cropData.width,
          height: snapshot.cropData.height,
          crop: 'crop',
          secure: true,
        })
      )
    }

    promises.push(...fetchItems(snapshotURLs))
  }

  return promises
}

function cacheBackgroundImage({ facility }) {
  const objects = get(facility, 'data.facility.floor.version.data') || {}
  const url = get(objects, 'backgroundImage.BACKGROUND_IMAGE.src')

  if (!isUndefined(url)) {
    return fetch(url)
  }
}

function cacheMetadataImages({ facility }) {
  const objects = get(facility, 'data.facility.floor.version.data') || {}
  const promises = []
  for (let objectGroupKey in objects) {
    const objectGroup = objects[objectGroupKey]
    if (typeof objectGroup !== 'object') continue
    for (let objectId in objectGroup) {
      const object = objectGroup[objectId]
      const images = get(object, 'metadata.images')
      if (images) {
        for (let image of images) {
          const imageURLs = [
            getSnapshotUrl({
              cloudinaryId: image.cloudinaryId,
              defaultSizes: {
                width: THUMBNAIL_SIZE,
                height: THUMBNAIL_SIZE,
              },
            }),
            getSnapshotUrl({
              cloudinaryId: image.cloudinaryId,
              defaultSizes: {
                width: SQUARE_THUMBNAIL_SIZE,
                height: SQUARE_THUMBNAIL_SIZE,
              },
            }),
            cloudinary.url(image.cloudinaryId, {
              crop: 'fill',
              secure: true,
            }),
          ]

          promises.push(...fetchItems(imageURLs))
        }
      }
    }
  }

  return promises
}

function cacheCFDImages({ facility }) {
  let images = []
  const cfds = convertCFDDataToArray(
    get(facility, 'data.facility.floor.version.cfd')
  )
  for (let i = 0; i < cfds.length; ++i) {
    const cfdData = cfds[i]
    if (cfdData) {
      images = images.concat(
        get(cfdData, 'URLs', []).filter(url => url.fileExtension === 'png')
      )
    }
  }
  return fetchItems(images.map(({ url }) => url))
}

function cacheCFDData({ facility }) {
  let vtks = []
  const cfds = convertCFDDataToArray(
    get(facility, 'data.facility.floor.version.cfd')
  )
  for (let i = 0; i < cfds.length; ++i) {
    const cfdData = cfds[i]
    if (cfdData) {
      vtks = vtks.concat(
        get(cfdData, 'URLs', []).filter(url => url.fileExtension === 'vtk')
      )
    }
  }
  return fetchItems(vtks.map(({ url }) => url))
}

export async function downloadFacility({
  facility,
  versionId,
  floorId,
  refetch = false,
}) {
  const options = facilityQueryOptions({ facility, versionId, floorId })
  if (refetch) {
    options.fetchPolicy = 'network-only'
  }

  const cachedFacility = await client.query(options)

  await Promise.all([
    cacheBackgroundImage({ facility: cachedFacility }),
    ...cacheSnapshots({ facility: cachedFacility }),
    ...cacheCFDImages({ facility: cachedFacility }),
    ...cacheCFDData({ facility: cachedFacility }),
    ...cacheMetadataImages({ facility: cachedFacility }),
  ]).catch(error => {
    console.error(error.message)
  })

  await persistor.persist()

  // When they download the facility, we need to store the facility ID in
  // localStorage so we can determine if it was actually downloaded vs. just
  // being in the Apollo cache.
  const downloadedFacilityIds =
    JSON.parse(localStorage.getItem(FACILITY_LOCAL_STORAGE_KEY)) || []
  downloadedFacilityIds.push(facility.id)
  const _downloadedFacilityIds = uniqBy(downloadedFacilityIds, id => id)
  localStorage.setItem(
    FACILITY_LOCAL_STORAGE_KEY,
    JSON.stringify(_downloadedFacilityIds)
  )
}

export function facilityIsDownloaded(facilityId) {
  const downloadedFaciltyIds =
    JSON.parse(localStorage.getItem(FACILITY_LOCAL_STORAGE_KEY)) || []
  if (downloadedFaciltyIds.length === 0) return false
  if (downloadedFaciltyIds.includes(facilityId)) return true
  return false
}

export function queryIsCached({ query, variables }) {
  try {
    // readQuery checks ONLY cache,
    // so if it errors we know we haven't cached the data
    client.readQuery({
      query,
      variables,
    })
    return true
  } catch (e) {
    return false
  }
}

function permissions() {
  const query = graphql(`
    query UserHasPermissionInitialData($permissionName: String!) {
      isAllowed: currentUserHasPermission(permissionName: $permissionName)
    }
  `)
  const uncachedPermissions = [
    'Comfort Zones',
    'Create Facility',
    'Create Template',
    'Delete Facility',
    'Edit Facility',
    'Edit Template',
    'FPM Grid',
    'Get Quote',
    'View Facility',
    'View Performance Metrics',
    'View Template',
  ]

  const promises = uncachedPermissions.map(name =>
    client.query({
      query,
      variables: { permissionName: name },
    })
  )

  return promises
}

function allFacilities(data) {
  const facilities = get(data, 'data.allFacilities.facilities') || []

  // If a facility is cached
  // (user manually selected download or navigated to it while online),
  // Update the data if a connection exists
  const promises = []
  for (let facility of facilities) {
    for (let i = 0; i < facility.floors.length; ++i) {
      const floor = facility.floors[i]
      for (let j = 0; j < floor.versions.length; ++j) {
        const version = floor.versions[j]
        const downloaded =
          queryIsCached(
            facilityQueryOptions({
              facility,
              versionId: version.id,
              floorId: floor.id,
            })
          ) && facilityIsDownloaded(facility.id)
        if (downloaded) {
          promises.push(
            downloadFacility({
              facility,
              versionId: version.id,
              floorId: floor.id,
              refetch: true,
            })
          )
        }
      }
    }

    // This is for short-url facilities
    const downloaded =
      queryIsCached(facilityQueryOptions({ facility: { id: facility.id } })) &&
      facilityIsDownloaded(facility.id)
    if (downloaded) {
      promises.push(
        downloadFacility({ facility: { id: facility.id }, refetch: true })
      )
    }
  }

  return promises
}

function allTemplates(data) {
  const templates = get(data, 'data.allFacilityTemplates.templates') || []

  // If a facility is cached
  // (user manually selected download or navigated to it while online),
  // Update the data if a connection exists
  const promises = []
  for (let template of templates) {
    const facility = get(template, 'facility')
    const downloaded =
      queryIsCached(facilityQueryOptions({ facility })) &&
      facilityIsDownloaded(facility.id)
    if (downloaded) {
      promises.push(downloadFacility({ facility, refetch: true }))
    }
    if (template.imageUrl) {
      promises.push(fetch(template.imageUrl))
      promises.push(fetch(parseCloudinaryUrlTransforms(template.imageUrl)))
    }
  }

  return promises
}

function detailedObstruction(obstructionType) {
  const meshPath = `/gltf/${obstructionType}.gltf`
  const binPath = `/gltf/${obstructionType}.bin`
  const imagePath = `/obstructions/${obstructionType}.png`

  return [fetch(imagePath), fetch(meshPath), fetch(binPath)]
}

function detailedObstructions() {
  const promises = []
  for (let obstructionData of detailedObstructionsData) {
    const obstructionType = get(obstructionData, 'obstructionType')
    promises.push(...detailedObstruction(obstructionType))
  }

  return promises
}

/**
 * Set up initial state required for application to run
 * @param {Function} setLoadingLabel set local state of loading label
 */
export default async (setLoadingLabel = () => {}, setProgress = () => {}) => {
  try {
    setLoadingLabel('Retrieving products data...')
    const productsData = await getAllProducts(setProgress)
    await client.query({ query: ALL_PRIMARY_USES_QUERY })

    setLoadingLabel('Retrieving facilities list...')
    const facilitiesData = await client.query({
      query: ALL_FACILITIES_QUERY,
    })

    setLoadingLabel('Checking downloaded facilities...')
    await allFacilities(facilitiesData)

    setLoadingLabel('Retrieving templates list...')
    const templatesData = await client.query({
      query: ALL_FACILITY_TEMPLATES_QUERY,
    })

    setLoadingLabel('Checking downloaded templates...')
    await allTemplates(templatesData)

    setLoadingLabel('Retrieving permissions...')
    await permissions()

    setLoadingLabel('Caching detailed obstructions images and models...')
    await detailedObstructions()

    setLoadingLabel('Finishing up...')
    setProgress(100)

    await persistor.persist()
  } catch (e) {
    console.log(e)
  }
}
