import get from 'lodash-es/get'
import Tool from './tool'
import Units from './units'
import Facility from './facility'
import Util from './util'
import Product from './product'
import WallSegment from './wallSegment'
import SnapQueries from './snapQueries'

import { isTouchDevice } from 'lib/utils'

import store from 'store'
import { addProduct } from 'store/objects'
import { setActiveTool } from 'store/tools'
import { showAlert } from 'store/alert'
import { isContinuousModeEnabled } from 'store/tools/selectors'
import { setStatus } from 'store/status'

import * as THREE from 'three'
import Roof from './roof'
import { vectorUIToModel } from '../util/units'

const LOWER_VOLTAGE_DIRECTIONAL_FANS = ['AirGo 2.0', 'Black Jack', 'Pivot 2.0']

class ProductTool extends Tool {
  constructor(props = {}) {
    super()

    // NOTE: this.obj3d is a contextual property provided by Tool
    this.name = 'PRODUCT_TOOL'
    const modelName = props.product.model
    const mountToCeiling = modelName === 'Haiku'
    const nativePosition = props.position || Util.getCameraPosition()

    const position = {
      x: Units.nativeToInches(nativePosition.x),
      y: Units.nativeToInches(nativePosition.y),
      z: Units.nativeToInches(nativePosition.z),
    }

    this.props = {
      size: 54,
      height: 96,
      position,
      mountToCeiling,
      modelName,
      id: Util.guid(),
      ...props,
    }

    this.height = Units.inchesToNative(this.props.height)

    this.init()
  }

  // NOTE: Determined by product but
  // passed down to product via redux
  // on rebuild
  static getProductModel(position, props) {
    if (position) {
      position = {
        x: Units.nativeToInches(position.x),
        y: Units.nativeToInches(position.y),
        z: Units.nativeToInches(position.z),
      }
    }
    const modelName = props.modelName || props.product.model

    const height = modelName.includes('IRH')
      ? get(props, 'heaterData[0].spotHeatHeight')
      : props.minFloorClearance

    return {
      airVelocities: props.airVelocities,
      cageHeight: props.cageHeight,
      canMountOnColumn: props.canMountOnColumn,
      canMountOnWall: props.canMountOnWall,
      canMountOverhead: props.canMountOverhead,
      canStandAlone: props.canStandAlone,
      category: props.product.category,
      degreesOfFreedom: props.degreesOfFreedom,
      height,
      isMounted: props.isMounted,
      minObstructionClearance: props.minObstructionClearance,
      minProductClearance: props.minProductClearance,
      minRoofClearance: props.minRoofClearance,
      minWallClearance: props.minWallClearance,
      mountingOptionAdderId: props.mountingOptionAdderId,
      mountingOptionAdders: props.mountingOptionAdders,
      mountPosition: props.mountPosition,
      recommendedFloorClearance: props.recommendedFloorClearance,
      recommendedObstructionClearance: props.recommendedObstructionClearance,
      recommendedProductClearance: props.recommendedProductClearance,
      recommendedRoofClearance: props.recommendedRoofClearance,
      size: props.size,
      id: props.id || Util.guid(),
      minFloorClearance: props.minFloorClearance,
      pedestals: props.pedestals,
      position,
      product: props.product,
      productId: props.product.id,
      rotation: props.rotation,
      tiltStart: get(props, 'degreesOfFreedom.start'),
      tiltEnd: get(props, 'degreesOfFreedom.end'),
      tiltStep: get(props, 'degreesOfFreedom.step'),
      tubeLength: Product.getDefaultTubeSize(modelName),
      variationId: props.variationId,
      voltage: props.voltage,
      voltageId: props.voltageId,
      voltages: props.voltages,
      wallSegmentId: props.wallSegmentId,

      // Install Information
      adderOther: props.adderOther,
      adders: props.adders,
      level: props.level,
      liftNeeded: props.liftNeeded,
      liftType: props.liftType,
      deckHeight: props.deckHeight,
      heightToAttachPoint: props.heightToAttachPoint,
      installAdders: props.installAdders,
      fireRelay: props.fireRelay,
      fireRelayType: props.fireRelayType,

      // Heater Information
      heaterData: props.heaterData,
      heaterPerformance: props.heaterPerformance,
    }
  }

  didEnd = false

  setPosition = ({ x, y }) => {
    this.product.obj3d.position.copy(new THREE.Vector3(x, y, this.height))
  }

  init() {
    this.createVisual()
    this.createEventListeners()
  }

  createVisual() {
    if (this.product) {
      this.obj3d.remove(this.product.obj3d)
      this.product.destroy()
    }

    this.rotation = {
      x: get(this, 'rotation.x', this.props.degreesOfFreedom ? 90 : 0),
      // x: get(this, 'rotation.x', 90),
      y: get(this, 'rotation.y', 0),
      z: get(this, 'rotation.z', 0),
    }

    const pos = get(this, 'product.obj3d.position')
    const props = {
      ...this.props,
      modelName: get(this, 'product.modelName'),
      isMounted: get(this, 'product.isMounted'),
      mountPosition: this.mountPosition,
      rotation: this.rotation,
      wallSegmentId: this.wallSegmentId,
    }
    const model = {
      ...ProductTool.getProductModel(pos, props),
      tiltStart: get(this.props, 'degreesOfFreedom.start'),
      tiltEnd: get(this.props, 'degreesOfFreedom.end'),
      tiltStep: get(this.props, 'degreesOfFreedom.step'),
      rotation: this.rotation,
      height: this.props.minFloorClearance,
      position: this.props.position,
    }

    this.product = new Product(model, 'INCHES')
    this.product.showArrowsOverride = true

    this.obj3d.add(this.product.obj3d)
    this.setPosition(this.props.position)
  }

  updateMountedDirection(startPoint, endPoint, interiorWall) {
    if (!startPoint || !endPoint) return

    const direction = new THREE.Vector3()
      .subVectors(startPoint, endPoint)
      .normalize()

    if (isNaN(direction.z)) direction.z = 0

    this.product.obj3d.quaternion.setFromUnitVectors(
      new THREE.Vector3(1, 0, 0),
      direction
    )

    let isClosed = true
    if (interiorWall) {
      interiorWall.wrapper.segments.forEach(seg => {
        if (!seg.nextSegment || !seg.previousSegment) {
          isClosed = false
        }
      })
    }

    // Keep product facing inside
    if (isClosed) {
      this.product.obj3d.rotation.z =
        this.product.obj3d.rotation.z + (180 * Math.PI) / 180
    }

    this.product.obj3d.rotation.x = 0

    this.rotation = {
      x: this.rotation.x,
      y: (this.product.obj3d.rotation.y * 180) / Math.PI,
      z: (this.product.obj3d.rotation.z * 180) / Math.PI,
    }
  }

  updateHeight() {
    const { distance } = Product.getDistanceFromFloor(
      Facility.current,
      this.product.obj3d.position,
      this.product.totalMeshHeight,
      this.props.mountToCeiling
    )
    const { heightChanged, height } = Product.getUpdatedHeight(
      this.height,
      distance
    )

    if (heightChanged) {
      this.height = height
      this.product.obj3d.position.z = height
    }
  }

  createEventListeners = () => {
    document.addEventListener('keyup', this.handleKeyUp)
  }

  removeEventListeners = () => {
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  handleKeyUp = e => {
    // Esc key up
    if (e.which === 27) {
      this.deactivate(true)
    }
  }

  insert = multiSelect => {
    if (!isContinuousModeEnabled()) this.deactivate()

    const pos = get(this, 'product.obj3d.position')
    const props = {
      ...this.props,
      modelName: get(this, 'product.modelName'),
      isMounted: get(this, 'product.isMounted'),
      mountPosition: this.mountPosition,
      rotation: this.rotation,
      wallSegmentId: this.wallSegmentId,
    }

    const model = ProductTool.getProductModel(pos, props)

    // Set default voltage of lower voltage directional fans
    if (LOWER_VOLTAGE_DIRECTIONAL_FANS.includes(model.product.model)) {
      const voltages = get(model, 'voltages', [])
      const defaultVoltage = voltages.find(
        voltage => voltage.inputPower === '100-125/1'
      )

      if (defaultVoltage) {
        model.voltage = defaultVoltage.inputPower
        model.voltageId = defaultVoltage.id
      }
    }

    model.id = Util.guid()
    store.dispatch(addProduct({ product: model, multiSelect }))

    if (isContinuousModeEnabled()) {
      this.init()
      const model = Object.assign({}, this.props.product)
      store.dispatch(setActiveTool({ tool: 'PRODUCT_TOOL', props: model }))
    }
  }

  // Context functions
  toolMoved(
    mousePos,
    snappedMousePos,
    lastSceneIntersectionPoint,
    objectWithCursor,
    objectWithSnappedCursor,
    activeSnapRegion
  ) {
    if (this.didEnd) return

    const isDirectional = this.product.isDirectional

    this.product.clearanceMaterial.opacity = 0.95
    this.wallSegmentId = null

    this.setPosition({
      x: isDirectional ? mousePos.x : snappedMousePos.x,
      y: isDirectional ? mousePos.y : snappedMousePos.y,
    })

    const point = vectorUIToModel(this.product.obj3d.position)
    this.product.doErrorCheck(point)

    this.updateHeight()

    if (isDirectional) {
      this.product.directionArrow.visible = true

      const measurements = Facility.current.measureObjectsInRangeOfPoint(
        snappedMousePos,
        Units.inchesToNative(this.product.size) / 2, // product radius
        { sort: true }
      )
      const interiorWall = measurements.find(
        obj => obj.wrapper.layerKey === 'INTERIOR_WALLS'
      )
      const column = measurements.find(
        obj => obj.obj3d.userData.objectType === 'COLUMN'
      )
      const wall = this.product.findWallSegment(snappedMousePos)
      if (wall) this.wallSegmentId = wall.id

      if (objectWithCursor instanceof WallSegment) {
        this.wallSegmentId = objectWithCursor.id
      }

      const mountableSurfaceInRange = !!(wall || interiorWall || column)

      if (!this.wallSegmentId && activeSnapRegion) {
        // Mouse might not be over a wall, but the box could be.
        // Try to see if box point lies on wall segment
        const segments = Facility.current.getWallSegments()
        for (let i = 0; i < segments.length; i++) {
          const seg = segments[i]
          const outsetPoints = seg.getOutsetPoints()
          const insetPoints = seg.getInsetPoints()
          const prodPosition = get(this, 'product.obj3d.position')

          if (prodPosition && insetPoints.length && outsetPoints.length) {
            const isOnInsetLine =
              insetPoints.length &&
              Util.isPointOnSegment(
                Util.arrayPointToObjectPoint(insetPoints[0]),
                prodPosition,
                Util.arrayPointToObjectPoint(insetPoints[1])
              )
            const isOnOutsetLine =
              outsetPoints.length &&
              Util.isPointOnSegment(
                Util.arrayPointToObjectPoint(outsetPoints[0]),
                prodPosition,
                Util.arrayPointToObjectPoint(outsetPoints[1])
              )

            if (isOnInsetLine || isOnOutsetLine) {
              this.wallSegmentId = seg.id
              break
            }
          }
        }
      }

      if (activeSnapRegion && mountableSurfaceInRange) {
        this.setPosition({
          x: snappedMousePos.x,
          y: snappedMousePos.y,
        })
        this.updateHeight()
        this.updateMountedDirection(
          activeSnapRegion.point1,
          activeSnapRegion.point2,
          interiorWall
        )
        // Give mounted products a little buffer room
        const radius = Units.inchesToNative(this.product.size) / 2 + 1.75
        this.mountPosition = this.product.obj3d.position.clone()
        this.product.obj3d.translateY(radius)
        this.height = this.product.minFloorClearance || 60
        this.product.isMounted = true

        if (this.mountedIndicator) {
          this.product.obj3d.remove(this.mountedIndicator)
        }

        this.mountedIndicator = this.product.getMountedIndicator()
        this.product.obj3d.add(this.mountedIndicator)
      } else {
        this.product.isMounted = false
        this.product.obj3d.remove(this.mountedIndicator)
      }
    }
  }

  toolUp({ snappedMousePos, multiSelect }) {
    if (this.didEnd) return

    const pos = this.product.obj3d.position.clone()
    const isInsideFacility = Util.isPositionsOverFacility([pos])

    const isDirectional = get(this, 'product.canMountOnWall')
    const isIRH = get(this, 'product.model').includes('IRH')
    let isTouchingWall = undefined

    if (!isDirectional && !isIRH) {
      isTouchingWall = Util.checkFoilWallCollisions(
        pos,
        get(this, 'props.size')
      )
    }

    if (isInsideFacility && isTouchingWall === undefined) {
      if (isTouchDevice()) {
        this.setPosition({
          x: snappedMousePos.x,
          y: snappedMousePos.y,
        })
      }

      this.product.hideError()
      this.insert(multiSelect)
    } else if (isTouchingWall !== undefined) {
      const error = isTouchingWall instanceof Roof ? 'Product must not intersect the roof!' : 'Product must not intersect a wall!'
      store.dispatch(
        setStatus({
          text: error,
          type: 'error',
        })
      )
    } else if (!isInsideFacility) {
      const error = 'Products must be placed inside the facility!'
      store.dispatch(
        setStatus({
          text: error,
          type: 'error',
        })
      )
    }
  }

  activate(mousePos) {
    this.didEnd = false

    if (isTouchDevice()) {
      store.dispatch(
        showAlert({
          text: 'Tap the canvas to place the product',
        })
      )
    } else {
      this.setPosition({
        x: mousePos.x,
        y: mousePos.y,
      })
    }
  }

  deactivate(forceDeactivate = false) {
    if (isContinuousModeEnabled() && !forceDeactivate) return

    this.didEnd = true
    this.obj3d.remove(this.product.obj3d)
    this.removeEventListeners()
    this.product.hideError()

    this.product.destroy()
  }

  onPropsDidChange(nextProps) {
    this.didEnd = false
    this.props = {
      ...this.props,
      ...nextProps,
    }
    this.init()
  }

  getSnapRegions(facility, draggedObject) {
    // Overhead products only snap to other products snap regions
    if (!this.product.isDirectional) {
      return SnapQueries.getAllProductCenterOrthoLines([this.product.id])
    }

    let wallSnapRegions = []
    let wallOutsetSnapRegions = []
    if (this.product.canMountOnWall) {
      wallSnapRegions = SnapQueries.getAllWallInsetLines(
        [this.product.id],
        true,
        Units.inchesToNative(24),
        0.75
      )
      wallOutsetSnapRegions = SnapQueries.getOpenWallOutsetLines(
        [this.product.id],
        Units.inchesToNative(24),
        0.75
      )
    }
    let columnSnapRegions = []
    if (this.product.canMountOnColumn) {
      columnSnapRegions = SnapQueries.getAllColumnOutsetLines(
        [this.product.id],
        true
      )
    }
    return wallSnapRegions.concat(columnSnapRegions, wallOutsetSnapRegions)
  }

  getArrowDescriptions() {
    this.product.isDragging = true
    return this.product.getArrowDescriptions(true)
  }
}

export default ProductTool
