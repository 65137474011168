import get from 'lodash-es/get'

import { showAlert } from '../alert'

const ROOT = 'CFD'

export const UPLOAD_OBJ = `${ROOT}/UPLOAD_OBJ`
export const UPLOAD_COMPLETE = `${ROOT}/UPLOAD_COMPLETE`
export const UPLOAD_OBJ_FAILED = `${ROOT}/UPLOAD_OBJ_FAILED`
export const ABORT_UPLOAD_OBJ = `${ROOT}/ABORT_UPLOAD_OBJ`
export const UPLOAD_OBJ_ABORTED = `${ROOT}/UPLOAD_OBJ_ABORTED`
export const NOTIFY_UPLOAD_OBJ_PROGRESS = `${ROOT}/NOTIFY_UPLOAD_OBJ_PROGRESS`
export const CANCEL_JOB = `${ROOT}/CANCEL_JOB`
export const JOB_CANCELLED = `${ROOT}/JOB_CANCELLED`
export const OBJ_FILE_FILENAME = 'model.obj'
export const UPDATE_CFD = 'UPDATE_CFD'
export const SET_CFD_LAYER = 'SET_CFD_LAYER'

const notifyUploadObjAborted = type => ({
  type: UPLOAD_OBJ_ABORTED,
  payload: { type },
})

export const setCFDSelectedLayer = ({ level, type, url, goal }) => ({
  type: SET_CFD_LAYER,
  payload: {
    level,
    goal,
    type,
    url,
  },
})

export const setCFDLayer = ({ level, type, url, goal }) => dispatch => {
  dispatch(
    setCFDSelectedLayer({
      level,
      type,
      url,
      goal,
    })
  )
}

export const updateCFD = payload => ({
  type: UPDATE_CFD,
  payload,
})

export const abortAnalyzeObj = () => dispatch => {
  dispatch({
    type: ABORT_UPLOAD_OBJ,
  })
  activeCanceller.cancel()
  dispatch(notifyUploadObjAborted())
}

