import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
// @ts-expect-error recompact has no types
import { withProps } from 'recompact'

import { updateObjects } from 'store/objects'
import store, { AppDispatch } from '~/store'

import { getLayerIcon } from 'config/layers'
import theme from 'config/theme'

import {
  setCurrentLayer,
  toggleLayerVisibility,
  toggleLayerLocked,
  toggleLayerExpanded,
} from 'store/layers'
import { LAYERS_PANEL } from 'store/panel/types'

import { hasPermission } from '../../RequiredPermission'

import Panel from 'components/UIKit/Panel'
import Icon from 'components/UIKit/Icon'
import Space from 'components/UIKit/Space'

import ListItem from './styled/ListItem'
import Title from './styled/Title'
import Actions from './styled/Actions'
import ActionIcon from './styled/ActionIcon'
import layerKeys, { LayerKeys } from 'config/layerKeys'
import { RootState } from '~/store';
import { layerData, ParentProps } from '~/store/layers/reducer';

export const PANEL_TITLE = 'Layers'

type LayersPanelProps = {
  layers: RootState['layers']['layers']
  currentLayer: LayerKeys
  hideComfortZone: boolean
  isTouchUI: boolean
  onSetCurrentLayer: (payload: { layerKey: LayerKeys }) => void
  onToggleVisibility: (payload: { layerKey: LayerKeys }) => void
  onToggleExpanded: (payload: { layerKey: LayerKeys }) => void
  onToggleLocked: (payload: { layerKey: LayerKeys }) => void
}

class LayersPanel extends Component<LayersPanelProps> {
  static propTypes = {
    layers: PropTypes.object.isRequired,
    currentLayer: PropTypes.string,
    hideComfortZone: PropTypes.bool,
    isTouchUI: PropTypes.bool,
    onSetCurrentLayer: PropTypes.func,
    onToggleVisibility: PropTypes.func,
    onToggleLocked: PropTypes.func,
    onToggleExpanded: PropTypes.func,
  }
  handleToggleExpanded = (event: MouseEvent, layerKey: LayerKeys) => {
    event.preventDefault()
    event.stopPropagation()

    this.props.onToggleExpanded({ layerKey })
  }

  renderChildLayer = (childLayerKey: LayerKeys) => {
    const { layers, onToggleVisibility, isTouchUI } = this.props
    const childLayer = layers[childLayerKey]

    return (
      // @ts-expect-error uikit has no types
      <ListItem key={childLayerKey} isChild>
        {/* @ts-expect-error uikit has no types */}
        <Title marginLeft="48px">{layerData[childLayerKey].name}</Title>
        <Actions>
          <ActionIcon
            size={isTouchUI ? '18' : '16'}
            name={childLayer.visible ? 'eye' : 'eyeOff'}
            color={childLayer.visible ? 'fg' : 'subdued'}
            // @ts-expect-error uikit has no types
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              onToggleVisibility({ layerKey: childLayerKey })
            }}
          />
        </Actions>
      </ListItem>
    )
  }

  renderSubIcon = (layerKey: LayerKeys) => {
    const layer = this.props.layers[layerKey]
    const iconSize = this.props.isTouchUI ? '18' : '16'

    if (layerData[layerKey].type === 'parent' && layerData[layerKey].children?.length) {
      if (layer.expanded) {
        return (
          <Icon
            name="arrowDown"
            color="light"
            size={iconSize}
            // @ts-expect-error uikit has no types
            onClick={event => this.handleToggleExpanded(event, layerKey)}
          />
        )
      }

      return (
        <Icon
          name="arrowRight"
          color="light"
          size={iconSize}
          // @ts-expect-error uikit has no types
          onClick={event => this.handleToggleExpanded(event, layerKey)}
        />
      )
    }

    return <Space left="base" />
  }

  render() {
    const {
      layers,
      currentLayer,
      onSetCurrentLayer,
      onToggleVisibility,
      onToggleLocked,
      hideComfortZone,
      isTouchUI,
    } = this.props

    const iconSize = isTouchUI ? '18' : '16'

    const layersArray = Object.entries(layerData)
    const validLayers = layersArray.filter<[LayerKeys, ParentProps]>(
      (data): data is [LayerKeys, ParentProps] => data[1].type === 'parent'
    )

    const sortedLayers = validLayers
      .sort(([_ka, a], [_kb, b]) => {
        if (a.position > b.position) {
          return 1
        }
        if (a.position < b.position) {
          return -1
        }

        return 0
      })
      .filter(([_key, layer]) => {
        if (hideComfortZone) {
          return layer.name !== 'Comfort Zones'
        }

        return true
      })
      .map(([key, layer]) => ({ ...layer, key }))

    return (
      <Panel
        scrollable
        title={PANEL_TITLE}
        panelKey={LAYERS_PANEL}
        closeOnDocumentClick={false}
        docked={isTouchUI}
        collapsible={!isTouchUI}
        zIndex={isTouchUI ? 'modal' : null}
        stretchContainer
      >
        {sortedLayers.map(layer => {
          return (
            <Fragment key={layer.key}>
              <ListItem
                // @ts-expect-error uikit has no types
                selected={currentLayer === layer.key}
                onClick={() => {
                  onSetCurrentLayer({ layerKey: layer.key })
                }}
                isTouchUI={isTouchUI}
              >
                <Space right="xs">{this.renderSubIcon(layer.key)}</Space>
                <Icon
                  // @ts-expect-error uikit has no types
                  name={getLayerIcon(layer.name)}
                  color="light"
                  size={iconSize}
                />
                {/* @ts-expect-error uikit has no types */}
                <Title marginLeft={theme.spacing.s}>{layer.name}</Title>
                <Actions>
                  <ActionIcon
                    size={iconSize}
                    name={layers[layer.key].locked ? 'locked' : 'unlocked'}
                    color={layers[layer.key].locked ? 'fg' : 'subdued'}
                    // @ts-expect-error uikit has no types
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()
                      onToggleLocked({ layerKey: layer.key })
                    }}
                  />
                  <ActionIcon
                    size={iconSize}
                    name={layers[layer.key].visible ? 'eye' : 'eyeOff'}
                    color={layers[layer.key].visible ? 'fg' : 'subdued'}
                    // @ts-expect-error uikit has no types
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()
                      onToggleVisibility({ layerKey: layer.key })
                    }}
                  />
                </Actions>
              </ListItem>
              {layer.children &&
                layers[layer.key].expanded &&
                layer.children.map(this.renderChildLayer)}
            </Fragment>
          )
        })}
      </Panel>
    )
  }
}

const mapStateToProps = ({ layers, userInterface }: RootState) => ({
  currentLayer: layers.currentLayer,
  layers: layers.layers,
  isTouchUI: userInterface.isTouchUI,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSetCurrentLayer({ layerKey }: Parameters<typeof setCurrentLayer>[0]) {
    dispatch(setCurrentLayer({ layerKey }))
  },

  onToggleVisibility({ layerKey }: Parameters<typeof toggleLayerVisibility>[0]) {
    dispatch(toggleLayerVisibility({ layerKey }))
    if (
      layerKey === layerKeys.EXTERIOR_WALLS ||
      layerKey === layerKeys.INTERIOR_WALLS
    ) {
      if (store.getState().layers.layers[layerKey].visible) {
        let segments = Object.values(store.getState().objects.present.segments)
          // @ts-expect-error segments aren't typed
          .filter(seg => seg.materialIndex !== 0 && seg.layerKey === layerKey)
          // @ts-ignore
          .map(seg => ({ ...seg, materialIndex: 0 }))

        dispatch(updateObjects(segments))
      }
    }
  },

  onToggleLocked({ layerKey }: Parameters<typeof toggleLayerLocked>[0]) {
    dispatch(toggleLayerLocked({ layerKey }))
  },

  onToggleExpanded({ layerKey }: Parameters<typeof toggleLayerExpanded>[0]) {
    dispatch(toggleLayerExpanded({ layerKey }))
  },
})

export default compose(
  hasPermission({
    name: 'Comfort Zones',
    withPermissions: true,
  }),
  withProps((props: { hideComfortZone: boolean; permissions: { isAllowed: boolean; isLoading: boolean; }; loading: boolean; }) => ({
    hideComfortZone: props.hideComfortZone || !props.permissions.isAllowed,
    loading: props.loading || props.permissions.isLoading,
  })),
  appConnect(mapStateToProps, mapDispatchToProps)
)(LayersPanel)
