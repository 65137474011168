export const ObjectClassName = {
  AIRFLOW: 'Airflow',
  AIRFLOW_DATA: 'AirflowData',
  CEILING: 'Ceiling',
  CFD: 'CFD',
  CFD_GRID: 'CFD_GRID',
  COMFORT_ZONE: 'ComfortZone',
  DIMENSION: 'Dimension',
  DOOR: 'Door',
  ELEVATION_LINE: 'ElevationLine',
  ELEVATION_POINT: 'ElevationPoint',
  GRID_BOX: 'GridBox',
  HEAT_MAP: 'HeatMap',
  OBSTRUCTION: 'Obstruction',
  PRODUCT: 'Product',
  ROOF: 'Roof',
  ROOF_SECTION: 'RoofSection',
  UTILITY_BOX: 'UtilityBox',
  WALL: 'Wall',
  WALL_SEGMENT: 'WallSegment',
} as const
export type ObjectClassName = (typeof ObjectClassName)[keyof typeof ObjectClassName]
export default ObjectClassName