import { useLoader } from "@react-three/fiber"
import { BufferAttribute, BufferGeometry, DoubleSide, LineBasicMaterial, LineSegments, Mesh, MeshBasicMaterial, MeshStandardMaterial, Object3D } from "three"
import { VTKLoader } from "./VTKLoader"
import { useAppSelector } from "~/store/hooks"
import Units from "../lib/units"
import { Suspense } from "react"
import ColorWorker from './cfdColor.worker?worker'
import { PrimaryTypes, PrimaryUses } from "~/config/facility"
import theme from '~/config/theme'
import { suspend } from 'suspend-react'
import { ErrorBoundary } from "@sentry/react"
import LayerKeys from "~/config/layerKeys"
import { isLowVelocityFacility } from "~/config/cfd"

const CFD_OPACITY = 1

export function CFD() {
  const selectedLayer = useAppSelector(state => state.cfd.selectedLayer)
  if (selectedLayer.url === undefined || selectedLayer.url === "") {
    return <></>
  }
  return (
    <Suspense>
      <ErrorBoundary>
        <Visualization url={selectedLayer.url}/>
      </ErrorBoundary>
    </Suspense>
  )
}

async function getColorData(velocities: BufferAttribute, isHeat: boolean, primaryUse: PrimaryUses, primaryType: PrimaryTypes, goal: string, type: string, worker: Worker): Promise<Float32Array> {
  let colors: { color: string; velocity: number }[] | { color: string; intensity: number }[] = 
    isLowVelocityFacility({ primaryUse, primaryType }) ?
      theme.colors.lowVelocityAirflow :
      theme.colors.highVelocityAirflow

  if (goal === 'destrat') {
    colors =
      type === 'destrat'
        ? theme.colors.destrat
        : theme.colors.draftRisk
  }

  if (isHeat) colors = theme.colors.intensity

  return new Promise((resolve, reject) => {
    worker.onmessage = (event: MessageEvent<{ data: ArrayBuffer }>) => {
      resolve(new Float32Array(event.data.data))
    }
    worker.onerror = (event) => {
      reject(event)
    }
    worker.postMessage({
      array: velocities.array,
      count: velocities.count,
      colors,
      isHeat,
    })
  })
}

const LINE_CFD = new Set(['track', 'isometric', 'streamlines'])
const BASIC_CFD = new Set(['overhead', 'destrat', 'overhead-fpm', 'sideY', 'sideX'])

async function createVisualMesh(vtk: BufferGeometry, goal: string, type: string): Promise<Object3D> {
  const worker = new ColorWorker()
  const geometry = vtk.clone()

  // CFD is taking the data we send them and converting it
  // from feet to meters, so we need to undo that conversion
  const scale = Units.metersToNative(1)
  const positions = geometry.attributes.position.array
  const zFightOffset = 0.4
  for (let i = 0; i < positions.length; i += 3) {
    positions[i] = positions[i] * scale
    positions[i + 1] = positions[i + 1] * scale
    positions[i + 2] = positions[i + 2] * scale + zFightOffset
  }

  const velocities = geometry.attributes.velocity as BufferAttribute
  const intensity = geometry.attributes.intensity as BufferAttribute

  const colorData = await getColorData(velocities || intensity, intensity !== undefined, 'OTHER', 'COMMERCIAL', goal, type, worker)

  const geo = geometry.clone()

  geo.setAttribute('color', new BufferAttribute(colorData, 4))

  if (LINE_CFD.has(type)) {
    return new LineSegments(geo, new LineBasicMaterial({ vertexColors: true }))
  } else if (BASIC_CFD.has(type)) {
    return new Mesh(geo, new MeshBasicMaterial({
      side: DoubleSide,
      vertexColors: true,
      opacity: CFD_OPACITY,
      transparent: true,
    }))
  } else {
    return new Mesh(geo, new MeshStandardMaterial())
  }
}

function Visualization(props: { url: string }) {
  const vtk = useLoader(VTKLoader, props.url)
  const selectedLayer = useAppSelector(state => state.cfd.selectedLayer)
  const isVisible = useAppSelector(state => state.layers.layers[LayerKeys.CFD].visible)

  const mesh = suspend(createVisualMesh, [vtk, selectedLayer.goal, selectedLayer.type])

  return (
    <group visible={isVisible}>
      <primitive object={mesh}/>
    </group>
  )
}
