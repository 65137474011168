import find from 'lodash-es/find'

import {
  SHOW_PANEL,
  HIDE_PANEL,
  TOGGLE_PANEL,
  RESET_PANELS,
  SET_DEFAULT_PANEL,
} from './actions'

import {
  LAYERS_PANEL,
  SNAPSHOTS_PANEL,
  AIRFLOW_PANEL,
  CFD_PANEL,
  DEFAULT_SELECTED_PANEL,
  DETAILED_OBSTRUCTION_PANEL,
  SELECTED_WALL_PANEL,
  SELECTED_WALL_SEGMENT_PANEL,
  SELECTED_PRODUCT_PANEL,
  SELECTED_OBSTRUCTION_PANEL,
  SELECTED_DOOR_PANEL,
  SELECTED_UTILITY_BOX_PANEL,
  SELECTED_ROOF_PANEL,
  SELECTED_ROOF_SECTION_PANEL,
  SELECTED_CEILING_PANEL,
  SELECTED_ELEVATION_POINT_PANEL,
  SELECTED_ELEVATION_LINE_PANEL,
  GET_QUOTE_PANEL,
  SELECTED_COMFORT_ZONE_PANEL,
  SELECTED_BACKGROUND_IMAGE_PANEL,
  PERFORMANCE_METRICS_PANEL,
  DOCK_ITEM_PANEL,
  CONTROLS_PANEL,
  HEAT_MAP_PANEL,
} from './types'

import {
  ADD_OBJECT,
  ADD_PRODUCT,
  ADD_DOOR,
  ADD_UTILITY_BOX,
  ADD_OBSTRUCTION,
  ADD_COMFORT_ZONE,
  ADD_ELEVATION_LINE,
  ADD_ELEVATION_POINT,
  UPDATE_ELEVATION_LINE,
  DELETE_OBJECTS,
} from '../objects'

import {
  SELECT_OBJECTS,
  DESELECT_OBJECTS,
  DELETE_SELECTED_OBJECTS,
} from '../selectedObjects'

import {
  mostRecentSelectedObjectState,
  getSelectedObjects,
} from '../selectedObjects/selectors'
import CLASS_NAMES from 'config/objectClassNames'

const getPanelSide = ({ state, type }) => {
  let currentSide = ''
  Object.keys(state).forEach(side => {
    const panel = find(Object.keys(state[side]), key => key === type)
    if (panel) {
      currentSide = side
    }
  })
  return currentSide
}

const getPanelFromObjectClassName = ({ className }) => {
  const panelTypes = {
    Product: SELECTED_PRODUCT_PANEL,
    Wall: SELECTED_WALL_PANEL,
    WallSegment: SELECTED_WALL_SEGMENT_PANEL,
    Door: SELECTED_DOOR_PANEL,
    UtilityBox: SELECTED_UTILITY_BOX_PANEL,
    Roof: SELECTED_ROOF_PANEL,
    RoofSection: SELECTED_ROOF_SECTION_PANEL,
    Ceiling: SELECTED_CEILING_PANEL,
    Obstruction: SELECTED_OBSTRUCTION_PANEL,
    ElevationPoint: SELECTED_ELEVATION_POINT_PANEL,
    ElevationLine: SELECTED_ELEVATION_LINE_PANEL,
    ComfortZone: SELECTED_COMFORT_ZONE_PANEL,
    SnapshotsPanel: SNAPSHOTS_PANEL,
    AirflowPanel: AIRFLOW_PANEL,
    CFDPanel: CFD_PANEL,
    GetQuotePanel: GET_QUOTE_PANEL,
    BackgroundImage: SELECTED_BACKGROUND_IMAGE_PANEL,
    HeatMapPanel: HEAT_MAP_PANEL,
  }

  return panelTypes[className]
}

const updateVisiblePanel = ({ state, className }) => {
  const panelType = getPanelFromObjectClassName({ className })
  const side = getPanelSide({ state, type: panelType })

  return {
    ...state,
    [side]: {
      ...state[side],
      visiblePanel: panelType,
    },
  }
}

const initialState = {
  other: {
    visiblePanel: null,
    defaultPanel: CONTROLS_PANEL,
    collapsed: false,
    [CONTROLS_PANEL]: {
      component: 'Camera3DControlsPanel',
    },
  },
  bottom: {
    visiblePanel: null,
    defaultPanel: null,
    collapsed: false,
    [LAYERS_PANEL]: {
      component: 'LayersPanel',
    },
    [SNAPSHOTS_PANEL]: {
      component: 'SnapshotsPanel',
    },
    [AIRFLOW_PANEL]: {
      component: 'AirflowPanel',
    },
    [CFD_PANEL]: {
      component: 'CFDPanel',
    },
    [PERFORMANCE_METRICS_PANEL]: {
      component: 'PerformanceMetricsPanel',
    },
    [HEAT_MAP_PANEL]: {
      component: 'HeatMapPanel',
    },
  },
  right: {
    visiblePanel: DEFAULT_SELECTED_PANEL,
    defaultPanel: DEFAULT_SELECTED_PANEL,
    collapsed: false,
    [DOCK_ITEM_PANEL]: {
      component: 'DockItemPanel',
    },
    [DEFAULT_SELECTED_PANEL]: {
      component: 'DefaultSelectedPanel',
    },
    [DETAILED_OBSTRUCTION_PANEL]: {
      component: 'DetailedObstructionPanel',
    },
    [SELECTED_WALL_SEGMENT_PANEL]: {
      component: 'SelectedWallSegmentPanel',
    },
    [SELECTED_WALL_PANEL]: {
      component: 'SelectedWallPanel',
    },
    [SELECTED_PRODUCT_PANEL]: {
      component: 'SelectedProductPanel',
    },
    [SELECTED_ROOF_PANEL]: {
      component: 'SelectedRoofPanel',
    },
    [SELECTED_ROOF_SECTION_PANEL]: {
      component: 'SelectedRoofSectionPanel',
    },
    [SELECTED_CEILING_PANEL]: {
      component: 'SelectedCeilingPanel',
    },
    [SELECTED_ELEVATION_POINT_PANEL]: {
      component: 'SelectedElevationPointPanel',
    },
    [SELECTED_OBSTRUCTION_PANEL]: {
      component: 'SelectedObstructionPanel',
    },
    [SELECTED_ELEVATION_LINE_PANEL]: {
      component: 'SelectedElevationLinePanel',
    },
    [SELECTED_ELEVATION_LINE_PANEL]: {
      component: 'SelectedElevationLinePanel',
    },
    [GET_QUOTE_PANEL]: {
      component: 'GetQuotePanel',
    },
    [SELECTED_COMFORT_ZONE_PANEL]: {
      component: 'SelectedComfortZonePanel',
    },
    [SELECTED_DOOR_PANEL]: {
      component: 'SelectedDoorPanel',
    },
    [SELECTED_UTILITY_BOX_PANEL]: {
      component: 'SelectedUtilityBoxPanel',
    },
    [SELECTED_BACKGROUND_IMAGE_PANEL]: {
      component: 'SelectedBackgroundImagePanel',
    },
  },
}

function updateSelectedObjectsPanel(selectedObjects, state) {
  let stateCopy = { ...state }

  let layer
  selectedObjects.every(object => {
    const type = getPanelFromObjectClassName({ className: object.className })

    if (layer !== undefined && layer !== type) {
      layer = false
    } else {
      layer = type
    }

    return layer
  })

  stateCopy = {
    ...stateCopy,
    right: {
      ...stateCopy.right,
      visiblePanel: stateCopy.right.collapsed
        ? null
        : layer || DEFAULT_SELECTED_PANEL,
    },
  }

  return stateCopy
}

export default function panelReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_PANEL: {
      const { type } = action.payload
      const side = getPanelSide({ state, type })
      const selectedObject = mostRecentSelectedObjectState(state)

      if (!side) return state

      if (type === state[side].defaultPanel) {
        const visiblePanel =
          side !== 'other' && selectedObject && selectedObject.className
            ? getPanelFromObjectClassName({
                className: selectedObject.className,
              })
            : type

        return {
          ...state,
          [side]: {
            ...state[side],
            visiblePanel,
            collapsed: false,
          },
        }
      }

      return {
        ...state,
        [side]: {
          ...state[side],
          visiblePanel: state[side].collapsed ? null : type,
        },
      }
    }
    case HIDE_PANEL: {
      const { type } = action.payload
      const side = getPanelSide({ state, type })

      if (!side) {
        return state
      }

      if (type === state[side].defaultPanel) {
        return {
          ...state,
          [side]: {
            ...state[side],
            visiblePanel: null,
            collapsed: true,
          },
        }
      }

      return {
        ...state,
        [side]: {
          ...state[side],
          visiblePanel: null,
        },
      }
    }
    case TOGGLE_PANEL: {
      const { type } = action.payload
      const side = getPanelSide({ state, type })
      if (!side) {
        return state
      }

      const isVisible = state[side].visiblePanel === type

      if (type === state[side].defaultPanel) {
        return {
          ...state,
          [side]: {
            ...state[side],
            visiblePanel: isVisible ? null : type,
            collapsed: !state[side].collapsed,
          },
        }
      }

      return {
        ...state,
        [side]: {
          ...state[side],
          visiblePanel: isVisible ? null : type,
          collapsed: !state[side].collapsed,
        },
      }
    }
    case SET_DEFAULT_PANEL:
      const { type } = action.payload
      const side = getPanelSide({ state, type })
      return {
        ...state,
        [side]: {
          ...state[side],
          defaultPanel: type,
        },
      }
    case DELETE_OBJECTS:
    case RESET_PANELS: {
      return {
        ...initialState,
        right: {
          ...initialState.right,
          visiblePanel: state.right.defaultPanel,
          defaultPanel: state.right.defaultPanel,
        },
      }
    }
    case DELETE_SELECTED_OBJECTS: {
      const selectedObjectClassNames = getSelectedObjects(action.globalState).map(
        o => o.className
      )

      let stateCopy = { ...state }

      selectedObjectClassNames.forEach(selectedObjectClassName => {
        const type = getPanelFromObjectClassName({
          className: selectedObjectClassName,
        })
        const side = getPanelSide({ state: stateCopy, type })

        if (!side) {
          return
        }

        stateCopy = {
          ...stateCopy,
          [side]: {
            ...stateCopy[side],
            visiblePanel: stateCopy[side].collapsed
              ? null
              : stateCopy[side].defaultPanel,
          },
        }
      })

      return stateCopy
    }
    case SELECT_OBJECTS: {
      const { payload = {} } = action
      const { ignorePanel, objects = [] } = payload

      if (ignorePanel) {
        return { ...state }
      }

      return updateSelectedObjectsPanel([...objects].reverse(), state)
    }
    case DESELECT_OBJECTS: {
      let stateCopy = { ...state }
      const { payload = {} } = action
      const { selectedObjects = [], ignorePanel } = payload

      if (ignorePanel) {
        return stateCopy
      }

      if (selectedObjects.length) {
        stateCopy = updateSelectedObjectsPanel(
          [...selectedObjects].reverse(),
          stateCopy
        )
      } else {
        stateCopy = {
          ...stateCopy,
          right: {
            ...stateCopy.right,
            visiblePanel: stateCopy.right.collapsed
              ? null
              : stateCopy.right.defaultPanel,
          },
        }
      }

      return stateCopy
    }
    case ADD_OBJECT: {
      const _className = action.payload.object.isEnclosed
        ? CLASS_NAMES.WALL
        : CLASS_NAMES.WALL_SEGMENT

      return updateVisiblePanel({ state, className: _className })
    }
    case ADD_PRODUCT: {
      return updateVisiblePanel({ state, className: CLASS_NAMES.PRODUCT })
    }
    case ADD_OBSTRUCTION: {
      return updateVisiblePanel({ state, className: CLASS_NAMES.OBSTRUCTION })
    }
    case ADD_COMFORT_ZONE: {
      return updateVisiblePanel({
        state,
        className: CLASS_NAMES.COMFORT_ZONE,
      })
    }
    case ADD_DOOR: {
      return updateVisiblePanel({ state, className: CLASS_NAMES.DOOR })
    }
    case ADD_UTILITY_BOX: {
      return updateVisiblePanel({ state, className: CLASS_NAMES.UTILITY_BOX })
    }
    case ADD_ELEVATION_LINE: {
      return updateVisiblePanel({
        state,
        className: CLASS_NAMES.ELEVATION_LINE,
      })
    }
    case UPDATE_ELEVATION_LINE: {
      return updateVisiblePanel({
        state,
        className: CLASS_NAMES.ELEVATION_LINE,
      })
    }
    case ADD_ELEVATION_POINT: {
      return updateVisiblePanel({
        state,
        className: CLASS_NAMES.ELEVATION_POINT,
      })
    }
    default: {
      return state
    }
  }
}
