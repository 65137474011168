import omit from 'lodash-es/omit'

import LAYER_KEYS, { AllLayerKeys, LayerKeys } from 'config/layerKeys'

import { isContinuousModeActive } from '../tools/selectors'
import { getSelectedObjects } from '../selectedObjects/selectors'

import {
  shouldIgnoreUpdateToInvalidateCFD,
  shouldIgnoreDeleteToInvalidateCFD,
} from '../cfd/reducer'

import {
  setCurrentLayer,
  setFocusedLayer,
  toggleLayerVisibility,
  toggleLayerLocked,
  toggleLayerExpanded,
  setVisibleLayers,
  lockAllLayers,
  restoreLayers,
} from './actions'

import {
  DELETE_BACKGROUND_IMAGE,
  ADD_OBJECT,
  ADD_ROOF,
  ADD_ROOF_SECTION,
  UPDATE_ELEVATION_POINT,
  UPDATE_ELEVATION_LINE,
  UPDATE_ROOF,
  UPDATE_ROOF_SECTION,
  UPDATE_OBJECT,
  UPDATE_OBJECTS,
  UPDATE_WALL,
  UPDATE_SEGMENT,
  ADD_PRODUCT,
  DISTRIBUTE_PRODUCTS,
  UPDATE_PRODUCT,
  ADD_OBSTRUCTION,
  DISTRIBUTE_OBSTRUCTIONS,
  UPDATE_OBSTRUCTION,
  ADD_CEILING,
  UPDATE_CEILING,
  DELETE_CEILING,
  DELETE_OBJECTS,
  DUPLICATE_OBJECTS,
  DELETE_ROOF_SECTION,
  UPDATE_SEGMENTS,
  LOAD_FACILITY,
} from '../objects'
import { SELECT_OBJECTS, DESELECT_OBJECTS } from '../selectedObjects'
import { UnknownAction } from '@reduxjs/toolkit'

function recursiveFreeze<T extends {}>(object: T): T {
  Object.freeze(object)

  for (const child of Object.values(object)) {
    if (typeof child === 'object' && !Object.isFrozen(child) && child !== null) {
      recursiveFreeze(child)
    }
  }

  return object
}

export type ChildProps = {
  type: 'child'
  name: string
  defaultVisible: boolean
  defaultLocked?: boolean
}

export type ParentProps = {
  type: 'parent'
  name: string
  defaultVisible: boolean
  defaultLocked: boolean
  position: number
  children?: LayerKeys[]
}

export type OffscreenProps = {
  type: 'offscreen'
  name: string
  defaultVisible: boolean
  defaultLocked: boolean
}

export const layerData: Readonly<Record<LayerKeys, ChildProps | ParentProps | OffscreenProps>> = {
  [LAYER_KEYS.ROOFS]: {
    type: 'parent',
    name: 'Roofs',
    defaultVisible: false,
    defaultLocked: false,
    position: 0,
    children: [LAYER_KEYS.ELEVATION_POINT, LAYER_KEYS.ELEVATION_LINE],
  },
  [LAYER_KEYS.ROOF_SECTIONS]: {
    type: 'parent',
    name: 'Mounting Structures',
    defaultVisible: false,
    defaultLocked: false,
    position: 1,
    children: [
      LAYER_KEYS.PRIMARY_MOUNTING_STRUCTURE,
      LAYER_KEYS.SECONDARY_MOUNTING_STRUCTURE,
      LAYER_KEYS.COLUMNS,
      LAYER_KEYS.MOUNTING_STRUCTURE_GUIDELINES,
    ],
  },
  [LAYER_KEYS.CEILINGS]: {
    type: 'parent',
    name: 'Ceilings',
    defaultVisible: false,
    defaultLocked: false,
    position: 2,
  },
  [LAYER_KEYS.PRODUCTS]: {
    type: 'parent',
    name: 'Products',
    defaultVisible: true,
    defaultLocked: false,
    position: 3,
    children: [
      LAYER_KEYS.PRODUCTS_OVERHEAD,
      LAYER_KEYS.PRODUCTS_DIRECTIONAL,
      LAYER_KEYS.PRODUCTS_HEATERS,
      LAYER_KEYS.PRODUCTS_EVAP,
    ]
  },
  [LAYER_KEYS.DIMENSIONS]: {
    type: 'parent',
    name: 'Annotations',
    defaultVisible: true,
    defaultLocked: false,
    position: 4,
    children: [
      LAYER_KEYS.DIMENSION_LINES,
      LAYER_KEYS.NOTES,
      LAYER_KEYS.PRODUCT_HEIGHTS,
    ],
  },
  [LAYER_KEYS.OBSTRUCTIONS]: {
    type: 'parent',
    name: 'Obstructions',
    defaultVisible: true,
    defaultLocked: false,
    position: 5,
  },
  [LAYER_KEYS.COMFORT_ZONES]: {
    type: 'parent',
    name: 'Comfort Zones',
    defaultVisible: true,
    defaultLocked: false,
    position: 6,
  },
  [LAYER_KEYS.INTERIOR_WALLS]: {
    type: 'parent',
    name: 'Interior Walls',
    defaultVisible: true,
    defaultLocked: false,
    position: 8,
  },
  [LAYER_KEYS.EXTERIOR_WALLS]: {
    type: 'parent',
    name: 'Exterior Walls',
    defaultVisible: true,
    defaultLocked: false,
    position: 9,
  },
  [LAYER_KEYS.BACKGROUND_IMAGE]: {
    type: 'parent',
    name: 'Background Image',
    defaultVisible: true,
    defaultLocked: true,
    position: 10,
  },
  // Children layers
  [LAYER_KEYS.ELEVATION_LINE]: {
    type: 'child',
    name: 'Elevation Line',
    defaultVisible: false,
  },
  [LAYER_KEYS.ELEVATION_POINT]: {
    type: 'child',
    name: 'Elevation Point',
    defaultVisible: false,
  },
  [LAYER_KEYS.PRIMARY_MOUNTING_STRUCTURE]: {
    type: 'child',
    name: 'Primary Structure',
    defaultVisible: false,
  },
  [LAYER_KEYS.MOUNTING_STRUCTURE_GUIDELINES]: {
    type: 'child',
    name: 'Guidelines',
    defaultVisible: false,
  },
  [LAYER_KEYS.PRODUCTS_OVERHEAD]: {
    type: 'child',
    name: 'Overhead Fans',
    defaultVisible: true,
  },
  [LAYER_KEYS.PRODUCTS_DIRECTIONAL]: {
    type: 'child',
    name: 'Directional Fans',
    defaultVisible: true,
  },
  [LAYER_KEYS.PRODUCTS_HEATERS]: {
    type: 'child',
    name: 'Heaters',
    defaultVisible: true,
  },
  [LAYER_KEYS.PRODUCTS_EVAP]: {
    type: 'child',
    name: 'Evaporative Coolers',
    defaultVisible: true,
  },
  [LAYER_KEYS.SECONDARY_MOUNTING_STRUCTURE]: {
    type: 'child',
    name: 'Secondary Structure',
    defaultVisible: false,
  },
  [LAYER_KEYS.COLUMNS]: {
    type: 'child',
    name: 'Columns',
    defaultVisible: false,
  },
  [LAYER_KEYS.DIMENSION_LINES]: {
    type: 'child',
    name: 'Dimension Lines',
    defaultVisible: true,
  },
  [LAYER_KEYS.NOTES]: {
    type: 'child',
    name: 'Notes',
    defaultVisible: true,
  },
  [LAYER_KEYS.PRODUCT_HEIGHTS]: {
    type: 'child',
    name: 'Product Heights',
    defaultVisible: true,
  },
  // Excluded from Panel:
  [LAYER_KEYS.DOORS]: {
    type: 'offscreen',
    name: 'Doors',
    defaultVisible: true,
    defaultLocked: false,
  },
  [LAYER_KEYS.UTILITY_BOXES]: {
    type: 'offscreen',
    name: 'UtilityBoxes',
    defaultVisible: true,
    defaultLocked: false,
  },
  [LAYER_KEYS.AIRFLOW]: {
    type: 'offscreen',
    name: 'Airflow',
    defaultVisible: false,
    defaultLocked: false,
  },
  [LAYER_KEYS.CFD]: {
    type: 'offscreen',
    name: 'CFD',
    defaultVisible: false,
    defaultLocked: false,
  },
  [LAYER_KEYS.GRID_BOX]: {
    type: 'offscreen',
    name: 'GRID_BOX',
    defaultVisible: true,
    defaultLocked: false,
  },
  [LAYER_KEYS.FACILITY]: {
    type: 'offscreen',
    name: 'FACILITY',
    defaultVisible: false,
    defaultLocked: true,
  },
  [LAYER_KEYS.HEAT_MAP]: {
    type: 'offscreen',
    name: 'Heat Map',
    defaultVisible: false,
    defaultLocked: false,
  },
}
recursiveFreeze(layerData)

function chilrenOfLayer(key: LayerKeys) {
  return layerData[key].type === 'parent' ? layerData[key].children ?? [] : []
}

const initialState: State = {
  currentLayer: LayerKeys.EXTERIOR_WALLS as LayerKeys,
  layers: Object.fromEntries(Object.entries(layerData).map( ([key, data]) => ([key, { visible: data.defaultVisible ?? false, locked: data.defaultLocked ?? false, expanded: true }]) )) as Record<LayerKeys, { visible: boolean; locked: boolean; expanded: boolean }>,
  temporaryLayers: {} as Record<LayerKeys, { visible: boolean; locked: boolean; expanded: boolean }> | {},
}

type State = {
  currentLayer: LayerKeys
  layers: Record<LayerKeys, { visible: boolean; locked: boolean; expanded: boolean }>
  temporaryLayers: any
}

export default function layersReducer(state = initialState, action: UnknownAction): State {
  switch (true) {
    case setCurrentLayer.match(action): {
      const childrenKeys = chilrenOfLayer(action.payload.layerKey)
      const expanded = state.layers[action.payload.layerKey].expanded

      const newState = {
        ...state,
        currentLayer: action.payload.layerKey,
        layers: {
          ...state.layers,
          [action.payload.layerKey]: {
            ...state.layers[action.payload.layerKey],
            visible: true,
            expanded: !expanded ? true : expanded,
          },
        },
      }

      childrenKeys.forEach(childKey => {
        newState.layers[childKey] = {
          ...state.layers[childKey],
          visible: true,
        }
      })

      return newState
    }
    case setFocusedLayer.match(action): {
      const newState = {
        ...state,
        currentLayer: action.payload.layerKey,
        layers: {
          ...state.layers,
          [action.payload.layerKey]: {
            ...state.layers[action.payload.layerKey],
            visible: true,
            locked: false,
          },
        },
      }
      
      AllLayerKeys.filter(key => key !== action.payload.layerKey).forEach(
        (layerKey) => {
          newState.layers[layerKey] = {
            ...newState.layers[layerKey],
            locked: true,
          }
        }
      )

      return newState
    }
    case action.type === SELECT_OBJECTS: {
      const { payload = {} } = action as any
      const { objects = [] } = payload
      const currentLayer = objects.length
        ? objects[objects.length - 1].layerKey
        : initialState.currentLayer

      return {
        ...state,
        currentLayer,
      }
    }
    case action.type === DESELECT_OBJECTS: {
      const { payload = {}, globalState } = action as any
      const { objects = [] } = payload
      // @ts-expect-error objects actions aren't typed
      const deselectedIds = objects.map(obj => obj.id)
      const selectedObjects = getSelectedObjects(globalState).filter(
        // @ts-expect-error objects actions aren't typed
        obj => !deselectedIds.includes(obj.id)
      )

      // If no objects remain selected stay on current layer
      if (!selectedObjects.length || !objects.length) return { ...state }

      const currentLayer = selectedObjects[selectedObjects.length - 1].layerKey
      if (currentLayer) {
        return {
          ...state,
          currentLayer,
        }
      }

      return {
        ...state,
      }
    }
    case toggleLayerVisibility.match(action): {
      const childrenKeys = chilrenOfLayer(action.payload.layerKey)
      const visible = !state.layers[action.payload.layerKey].visible

      const newState = {
        ...state,
        layers: {
          ...state.layers,
          [action.payload.layerKey]: {
            ...state.layers[action.payload.layerKey],
            visible,
          },
        },
      }

      childrenKeys.forEach(childKey => {
        newState.layers[childKey] = {
          ...state.layers[childKey],
          visible,
        }
      })

      return newState
    }
    case toggleLayerLocked.match(action): {
      if (layerData[action.payload.layerKey].type === 'child') {
        return state
      }
      const layer = layerData[action.payload.layerKey]
      const otherLayers: Record<string, { visible: boolean; locked: boolean; expanded: boolean }> = {}
      ;(layer.type === 'parent' ? layer.children ?? [] : []).forEach(childKey => {
        otherLayers[childKey] = {
          ...state.layers[childKey],
          locked: !state.layers[action.payload.layerKey].locked,
        }
      })
      return {
        ...state,
        layers: {
          ...state.layers,
          ...otherLayers,
          [action.payload.layerKey]: {
            ...state.layers[action.payload.layerKey],
            locked: !state.layers[action.payload.layerKey].locked,
          },
        },
      }
    }
    case toggleLayerExpanded.match(action): {
      return {
        ...state,
        layers: {
          ...state.layers,
          [action.payload.layerKey]: {
            ...state.layers[action.payload.layerKey],
            expanded: !state.layers[action.payload.layerKey].expanded,
          },
        },
      }
    }
    case action.type === ADD_ROOF:
    case action.type === ADD_ROOF_SECTION:
    case action.type === UPDATE_ROOF:
    case action.type === UPDATE_ROOF_SECTION:
    case action.type === UPDATE_ELEVATION_POINT:
    case action.type === UPDATE_ELEVATION_LINE:
    case action.type === UPDATE_OBJECT:
    case action.type === UPDATE_OBJECTS:
    case action.type === UPDATE_WALL:
    case action.type === UPDATE_SEGMENT:
    case action.type === UPDATE_SEGMENTS:
    case action.type === DISTRIBUTE_PRODUCTS:
    case action.type === UPDATE_PRODUCT:
    case action.type === ADD_OBSTRUCTION:
    case action.type === DISTRIBUTE_OBSTRUCTIONS:
    case action.type === UPDATE_OBSTRUCTION:
    case action.type === ADD_CEILING:
    case action.type === UPDATE_CEILING:
    case action.type === DELETE_CEILING:
    case action.type === DELETE_OBJECTS:
    case action.type === DUPLICATE_OBJECTS:
    case action.type === DELETE_ROOF_SECTION: {
      const payload = action.payload
      let visible = false

      if (shouldIgnoreUpdateToInvalidateCFD(payload)) {
        visible = state.layers.CFD.visible
      }

      if (
        action.type === 'DELETE_OBJECTS' &&
        shouldIgnoreDeleteToInvalidateCFD(action.globalState)
      ) {
        visible = state.layers.CFD.visible
      }

      return {
        ...state,
        layers: {
          ...state.layers,
          CFD: {
            ...state.layers.CFD,
            visible,
            locked: false,
          },
        },
      }
    }
    case action.type === ADD_OBJECT: {
      const payload = action.payload as any
      const visibleCFD = shouldIgnoreUpdateToInvalidateCFD(payload)
        ? true
        : false

      return {
        ...state,
        currentLayer: payload.object.layerKey,
        layers: {
          ...state.layers,
          CFD: {
            ...state.layers.CFD,
            visible: visibleCFD,
            locked: false,
          },
          [payload.object.layerKey]: {
            // @ts-expect-error ADD_OBJECT is not typed yet
            ...state.layers[payload.object.layerKey],
            visible: true,
            locked: false,
          },
        },
      }
    }
    case action.type === ADD_PRODUCT: {
      if (isContinuousModeActive(action.globalState)) return { ...state }

      return {
        ...state,
        currentLayer: LAYER_KEYS.PRODUCTS,
        layers: {
          ...state.layers,
          PRODUCTS: {
            ...state.layers.PRODUCTS,
            visible: true,
            locked: false,
          },
          CFD: {
            ...state.layers.CFD,
            visible: false,
            locked: false,
          },
        },
      }
    }
    case setVisibleLayers.match(action): {
      const layers: Partial<(typeof state)['layers']> = {}
      AllLayerKeys.forEach(layerKey => {
        const layer = layerData[layerKey]
        if (action.payload.visibleLayerIds.includes(layerKey)) {
          layers[layerKey] = {
            ...state.layers[layerKey],
            visible: true,
            locked: false,
          }
          ;(layer.type === 'parent' ? layer.children ?? [] : []).forEach(childKey => {
            layers[childKey] = {
              ...state.layers[childKey],
              visible: true,
              locked: false,
            }
          })
        } else if (layer.type !== 'child') {
          layers[layerKey] = {
            ...state.layers[layerKey],
            visible: false,
            locked: false,
          }
          ;(layer.type === 'parent' ? layer.children ?? [] : []).forEach(childKey => {
            layers[childKey] = {
              ...state.layers[childKey],
              visible: false,
              locked: false,
            }
          })
        }
      })
      return {
        ...state,
        layers: {
          ...state.layers,
          ...layers,
        },
      }
    }
    case action.type === DELETE_BACKGROUND_IMAGE: {
      return initialState
    }
    case action.type === LOAD_FACILITY: {
      const { layers } = action.payload as any

      if (layers) {
        return {
          ...state,
          ...layers,
          layers: Object.fromEntries(AllLayerKeys.map(key => {
            // children get their state reset on load
            if (layerData[key].type === 'child') {
              return [key, initialState.layers[key]]
            }
            // we want these layers to always be invisible on facility load
            if ([LAYER_KEYS.AIRFLOW as LayerKeys, LAYER_KEYS.CFD, LAYER_KEYS.HEAT_MAP].includes(key)) {
              return [key, {
                visible: false,
                locked: layers.layers[key]?.locked ?? layerData[key].defaultLocked,
                expanded: layers.layers[key]?.expanded ?? true,
              }]
            }
            // everything else gets pulled from the save
            return [key, {
              visible: layers.layers[key]?.visible ?? layerData[key].defaultVisible,
              locked: layers.layers[key]?.locked ?? layerData[key].defaultLocked,
              expanded: layers.layers[key]?.expanded ?? true,
            }]
          }))
        }
      }

      return initialState
    }
    case lockAllLayers.match(action): {
      const currentLayers = state.layers
      // @ts-expect-error we're building out the object in the loop below
      const newLayers: (typeof state)['layers'] = {}

      AllLayerKeys.forEach(key => {
        newLayers[key] = {
          ...state.layers[key],
          locked: true,
        }
      })

      return {
        ...state,
        temporaryLayers: currentLayers,
        layers: newLayers,
      }
    }
    case restoreLayers.match(action): {
      return {
        ...state,
        layers: state.temporaryLayers,
        temporaryLayers: {},
      }
    }
    default: {
      return state
    }
  }
}
