import { setStatus } from '../status'
import { UPDATE_VERSION_MUTATION } from 'client/mutations'
import cleanProducts from '../utils/cleanProducts'

export const SAVE_OBJECTS_STARTED = 'SAVE_OBJECTS_STARTED'
export const SAVE_OBJECTS_SUCCEEDED = 'SAVE_OBJECTS_SUCCEEDED'
export const SAVE_OBJECTS_FAILED = 'SAVE_OBJECTS_FAILED'

export const saveObjectsStarted = () => ({
  type: SAVE_OBJECTS_STARTED,
})

export const saveObjectsSucceeded = () => ({
  type: SAVE_OBJECTS_SUCCEEDED,
})

export const saveObjectsFailed = payload => ({
  type: SAVE_OBJECTS_FAILED,
  payload,
})

export const saveObjects = ({ versionId, saveLayers }) => (
  dispatch,
  getState,
  client
) => {
  dispatch(saveObjectsStarted())
  const {
    objects,
    cfd,
    layers,
    userInterface,
    loadingDock,
    install,
  } = getState()
  const data = {
    units: objects.present.units,
    objects: objects.present.objects,
    products: cleanProducts(objects.present.products),
    doors: objects.present.doors,
    utilityBoxes: objects.present.utilityBoxes,
    obstructions: objects.present.obstructions,
    comfortZones: objects.present.comfortZones,
    dimensions: objects.present.dimensions,
    segments: objects.present.segments,
    roofs: objects.present.roofs,
    roofSections: objects.present.roofSections,
    elevationPoints: objects.present.elevationPoints,
    elevationLines: objects.present.elevationLines,
    backgroundImage: objects.present.backgroundImage,
    ceilings: objects.present.ceilings,
    loadingDock,
    install,
    cfd: {
      isValid: cfd.isValid,
      validTypes: cfd.validTypes,
      metrics: cfd.metrics,
    },
    userInterface: {
      isTouchUI: userInterface.isTouchUI,
    },
  }

  if (saveLayers) {
    data.layers = layers
  }

  client
    .mutate({
      mutation: UPDATE_VERSION_MUTATION,
      variables: {
        id: versionId,
        data,
      },
    })
    .then(data => {
      dispatch(saveObjectsSucceeded())
    })
    .catch(error => {
      // NOTE: You can indeed have bread, though 🍞
      const readOnly = error.message.match(/\bread only/i)
      const isTemplate = error.message.match(/\btemplate/i)
      if (readOnly) {
        dispatch(
          setStatus(
            isTemplate
              ? { type: 'error', text: 'You cannot save this template.' }
              : {
                  type: 'error',
                  text:
                    'Facility owned by user outside your organization, make a copy to edit.',
                }
          )
        )
      }
      dispatch(saveObjectsFailed(error))
    })
}
