import { getIndexOf } from 'lib/utils'

const opaqueObjectOrder = ['products']

const transparentObjectOrder = [
  'snapRegions',
  'roofs',
  'mountingStructures',
  'ceilings',
  'arrowRenderer',
  'comfortZone',
  'airflow',
  'cfd',
  'heatMap',
  'floor',
  'backgroundImage',
  'gridBox',
]

// Transparent and opaque objects are sorted separately by the three.js renderer, so
// we use two separate lists here. Additionally, three.js sorts transparent objects
// in reverse order, so we reverse our transparent list here to compensate.
const objectOrder = opaqueObjectOrder.concat(transparentObjectOrder.reverse())

const getRenderOrder = (key: string): number => getIndexOf({ key, list: objectOrder })

export default getRenderOrder
