import get from 'lodash-es/get'
import findIndex from 'lodash-es/findIndex'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import omit from 'lodash-es/omit'
import omitBy from 'lodash-es/omitBy'
import uniqBy from 'lodash-es/uniqBy'
import pick from 'lodash-es/pick'
import keyBy from 'lodash-es/keyBy'
import some from 'lodash-es/some'

import Util from 'components/DrawingCanvas/lib/util'
import Units from 'components/DrawingCanvas/lib/units'
import Wall from 'components/DrawingCanvas/lib/wall'
import Roof from 'components/DrawingCanvas/lib/roof'
import RoofSection from 'components/DrawingCanvas/lib/roofSection'
import { findObject, objectClassNameFromStateKey, objectStateKeyFromClassName } from './selectors'
import shiftGridLines from './shiftGridLines'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'

import {
  LOAD_FACILITY,
  RESET_FACILITY,
  ADD_OBJECT,
  ADD_ROOF,
  ADD_ROOF_SECTION,
  ADD_ELEVATION_POINT,
  UPDATE_ELEVATION_POINT,
  ADD_ELEVATION_LINE,
  UPDATE_ELEVATION_LINE,
  REMOVE_ELEVATION_LINE_HEIGHT,
  UPDATE_ROOF,
  UPDATE_ROOF_STRUCTURE,
  UPDATE_ROOF_SECTION,
  ADD_MOUNTING_BEAM,
  REMOVE_MOUNTING_BEAM,
  UPDATE_MOUNTING_BEAM,
  ADD_COLUMN_LINE,
  REMOVE_COLUMN_LINE,
  UPDATE_COLUMN_LINE,
  REVERT_ROOF_SECTIONS,
  UPDATE_OBJECT,
  UPDATE_OBJECTS,
  UPDATE_OBJECTS_PASSIVELY,
  FORCE_UPDATE_OBJECT,
  UPDATE_WALL,
  UPDATE_SEGMENT,
  ADD_DOOR,
  UPDATE_DOOR,
  DISTRIBUTE_DOORS,
  ADD_UTILITY_BOX,
  UPDATE_UTILITY_BOX,
  ADD_PRODUCT,
  DISTRIBUTE_PRODUCTS,
  UPDATE_PRODUCT,
  ADD_OBSTRUCTION,
  DISTRIBUTE_OBSTRUCTIONS,
  UPDATE_OBSTRUCTION,
  ADD_COMFORT_ZONE,
  UPDATE_COMFORT_ZONE,
  ADD_DIMENSION,
  UPDATE_DIMENSION,
  DELETE_DIMENSION,
  ADD_CEILING,
  UPDATE_CEILING,
  DELETE_CEILING,
  DELETE_OBJECTS,
  DUPLICATE_OBJECTS,
  ADD_GRID,
  DUPLICATE_GRID,
  REQUEST_AIRFLOW,
  RECEIVE_AIRFLOW,
  UPDATE_AIRFLOW_STATUS,
  SET_AIRFLOW_LAYER,
  DELETE_ROOF_SECTION,
  ADD_BACKGROUND_IMAGE,
  DELETE_BACKGROUND_IMAGE,
  UPDATE_BACKGROUND_IMAGE,
  UPDATE_PRODUCT_HEIGHT,
  UPDATE_SEGMENTS,
  ADD_METADATA_IMAGE,
  REQUEST_HEAT_MAP,
  RECEIVE_HEAT_MAP,
  UPDATE_HEAT_MAP_STATUS,
  SET_HEAT_MAP_LAYER,
} from './actions'
import * as CFD from '../cfd/actions'
import { TOGGLE_LAYER_VISIBILITY } from '../layers'
import { SELECT_OBJECTS, DESELECT_OBJECTS } from '../selectedObjects/action_types'

import * as THREE from 'three'
const DEFAULT_ROOF_COLOR = '#4488aa'

function updateWall(wall, state) {
  let segmentObjects = wall.segments
  if (
    segmentObjects &&
    segmentObjects.length &&
    typeof segmentObjects[0] === 'string'
  ) {
    segmentObjects = segmentObjects.map(id => state.segments[id])
  }
  const perimeterPoints = Wall.getCenterLinePointsFromSegments(segmentObjects)

  const roofSections = {}
  wall.roofSectionIds.forEach(
    roofSectionId =>
      (roofSections[roofSectionId] = {
        ...state.roofSections[roofSectionId],
        perimeterPoints,
        position: wall.position,
      })
  )

  const segments = {}
  segmentObjects.forEach(segment => {
    segments[segment.id] = {
      ...segments[segment.id],
      ...segment,
    }
  })
  return {
    ...state,
    objects: {
      ...state.objects,
      [wall.id]: {
        ...state.objects[wall.id],
        ...wall,
        segments: segmentObjects.map(segment => segment.id),
        metadata: {
          ...state.objects[wall.id].metadata,
          ...wall.metadata,
        },
      },
    },
    segments: {
      ...state.segments,
      ...segments,
    },
    roofs: {
      ...state.roofs,
      [wall.roofId]: {
        ...state.roofs[wall.roofId],
        perimeterPoints,
        position: wall.position,
      },
    },
    roofSections: {
      ...state.roofSections,
      ...roofSections,
    },
  }
}

function updateElevationLine(elevationLine, state) {
  const newElevationPoints = {
    ...state.elevationPoints,
  }

  if (elevationLine.height) {
    const linePointIds = elevationLine.elevationPointIds

    linePointIds.forEach(id => {
      const ep = {
        ...state.elevationPoints[id],
        position: {
          ...state.elevationPoints[id].position,
          z: elevationLine.height,
        },
      }
      newElevationPoints[id] = ep
    })
  }

  return {
    ...state,
    needsSaved: true,
    elevationLines: {
      ...state.elevationLines,
      [elevationLine.id]: {
        ...elevationLine,
      },
    },
    elevationPoints: newElevationPoints,
  }
}

function updateSegment(segment, state) {
  // Update height of associated roof and roof sections.
  const prevSegment = state.segments[segment.id]

  const updatedSegment = {
    ...prevSegment,
    ...segment,
  }
  let newState = {
    ...state,
    segments: {
      ...state.segments,
      [updatedSegment.id]: updatedSegment,
    },
  }

  const parent = newState.objects[updatedSegment.parentId]
  const height = updatedSegment.height
  const heightDidChange = updatedSegment.height !== prevSegment.height
  const roofs = parent.roofId
    ? {
        ...newState.roofs,
        [parent.roofId]: { ...newState.roofs[parent.roofId], height },
      }
    : newState.roofs
  const roofSections = {}
  parent.roofSectionIds.forEach(roofSectionId => {
    roofSections[roofSectionId] = {
      ...newState.roofSections[roofSectionId],
      height,
    }
  })

  if (heightDidChange) {
    const relatedCeilings = getRelatedCeilings(segment.id, newState)
    if (relatedCeilings.length === 1) {
      const ceilingId = relatedCeilings[0]
      const ceiling = {
        ...newState.ceilings[ceilingId],
        height,
      }
      newState = updateCeiling(ceiling, newState)
    }
  }

  return {
    ...newState,
    roofs: roofs,
    roofSections: {
      ...newState.roofSections,
      ...roofSections,
    },
  }
}

function getSegmentsForCeilings(state) {
  const ceilings = {}
  Object.values(state.ceilings).forEach(ceiling => {
    const { id, perimeterPoints } = ceiling
    const segments = []
    Object.values(state.segments).forEach(segment => {
      let matchedIndex
      const hasSegment = some(perimeterPoints, (point, i) => {
        // TODO: Shouldn't all units be stored/used in the same unit of measure?
        const x = +Units.nativeToInches(point.x).toPrecision(6)
        const y = +Units.nativeToInches(point.y).toPrecision(6)
        const startX = +segment.startPoint.x.toPrecision(6)
        const startY = +segment.startPoint.y.toPrecision(6)
        const endX = +segment.endPoint.x.toPrecision(6)
        const endY = +segment.endPoint.y.toPrecision(6)
        if ((x === startX && y === startY) || (x === endX && y === endY)) {
          // If an adjacent point was matched, we have a matching segment
          if (
            matchedIndex === (i - 1) % perimeterPoints.length ||
            matchedIndex === (i + 1) % perimeterPoints.length
          ) {
            return true
          } else {
            matchedIndex = i
          }
        }
      })
      if (hasSegment) {
        segments.push(segment.id)
      }
    })
    ceilings[id] = {
      segments,
    }
  })

  return ceilings
}

function getRelatedCeilings(segmentId, state) {
  const segment = state.segments[segmentId]
  const ceilingSegments = getSegmentsForCeilings(state)
  if (segment.layerKey === LAYER_KEYS.INTERIOR_WALLS) {
    const enabledCeilings = Object.values(state.ceilings)
      .filter(ceiling => ceiling.enabled)
      .map(ceiling => ceiling.id)
    return Object.keys(ceilingSegments)
      .filter(id => enabledCeilings.includes(id))
      .filter(id => ceilingSegments[id].segments.includes(segmentId))
  }

  return []
}

function updateGridBox(object, state) {
  return {
    ...state,
    gridBox: {
      ...state.gridBox,
      [object.id]: {
        ...state.gridBox[object.id],
        ...object,
      },
    },
  }
}

function updateCeiling(object, state) {
  const curCeiling = get(state, `ceilings.${object.id}.height`)
  const newState = {
    ...state,
    ceilings: {
      ...state.ceilings,
      [object.id]: {
        ...state.ceilings[object.id],
        ...object,
        metadata:
          object.metadata || state.ceilings[object.id].metadata
            ? {
                ...state.ceilings[object.id].metadata,
                ...object.metadata,
              }
            : undefined,
      },
    },
  }

  // Udpdate wall segment heights iff:
  // * Ceiling is switched to enabled OR
  // * Ceiling is enabled AND ceiling height changed
  const updateWallHeights =
    (object.enabled && !curCeiling.enabled) ||
    (object.enabled && object.height !== curCeiling.height)

  if (updateWallHeights) {
    // Update wall segment heights to match ceiling
    const updatedSegments = {}
    const ceilingSegments = getSegmentsForCeilings(newState)
    ceilingSegments[object.id].segments
      .filter(segId => !newState.segments[segId].isFullHeight) // Don't update fullHeight segments
      .forEach(segId => {
        // Check if wall is shared
        const relatedCeilings = getRelatedCeilings(segId, newState)

        // If wall is not shared, update height
        if (relatedCeilings.length === 1) {
          updatedSegments[segId] = {
            ...newState.segments[segId],
            height: object.height,
          }
        }
      })
    newState.segments = {
      ...newState.segments,
      ...updatedSegments,
    }
  }

  return newState
}

function updateRoofStructure(object, state) {
  // Build new elevation points
  const elevationPoints = {}
  object.elevationPoints.forEach(point => {
    const id = Util.guid()
    elevationPoints[id] = {
      id,
      position: point,
      roofId: object.roofId,
      wallId: object.wallId,
    }
  })

  // Get new elevation point ids
  const elevationPointIds = []
  Object.keys(elevationPoints).forEach(function(key, index) {
    elevationPointIds.push(elevationPoints[key].id)
  })

  // Build new elevation line
  const elevationLineId = Util.guid()
  const elevationLine = {
    id: elevationLineId,
    elevationPointIds,
    elevationPoints,
    height: object.height,
    roofId: object.roofId,
  }

  return {
    ...state,
    elevationPoints: {
      ...elevationPoints,
    },
    elevationLines: {
      [elevationLineId]: elevationLine,
    },
    roofs: {
      [object.roofId]: {
        ...state.roofs[object.roofId],
        elevationLineIds: [elevationLineId],
        elevationPointIds: [],
      },
    },
  }
}

// this seems like a terrible idea to update so many things
// for ONLY a camera change
function onCameraUpdate(state) {
  const obstructions = {}
  const doors = {}
  const utilityBoxes = {}
  const roofSections = {}
  const comfortZones = {}
  if (!isEmpty(state.doors)) {
    Object.keys(state.doors).forEach(key => {
      doors[key] = {
        ...state.doors[key],
        // updateId: Util.guid(),
      }
    })
  }
  if (!isEmpty(state.utilityBoxes)) {
    Object.keys(state.utilityBoxes).forEach(key => {
      utilityBoxes[key] = {
        ...state.utilityBoxes[key],
        // updateId: Util.guid(),
      }
    })
  }
  if (!isEmpty(state.roofSections)) {
    Object.keys(state.roofSections).forEach(key => {
      roofSections[key] = {
        ...state.roofSections[key],
        // updateId: Util.guid(),
      }
    })
  }
  if (!isEmpty(state.obstructions)) {
    Object.keys(state.obstructions).forEach(key => {
      obstructions[key] = {
        ...state.obstructions[key],
        // updateId: Util.guid(),
      }
    })
  }
  if (!isEmpty(state.comfortZones)) {
    Object.keys(state.comfortZones).forEach(key => {
      comfortZones[key] = {
        ...state.comfortZones[key],
        // updateId: Util.guid(),
      }
    })
  }

  return {
    ...state,
    doors: {
      ...doors,
    },
    utilityBoxes: {
      ...utilityBoxes,
    },
    roofSections: {
      ...roofSections,
    },
    obstructions: {
      ...obstructions,
    },
    comfortZones: {
      ...comfortZones,
    },
  }
}

// Update any objects during a multi select
function onMultiSelect(state) {
  const obstructions = {}
  if (!isEmpty(state.obstructions)) {
    Object.keys(state.obstructions).forEach(key => {
      obstructions[key] = {
        ...state.obstructions[key],
        // updateId: Util.guid(),
      }
    })
  }

  return {
    ...state,
    obstructions: {
      ...obstructions,
    },
  }
}

function findObjectById(state, id) {
  for (const key in state) {
    if (state[key] && typeof state[key] === 'object' && id in state[key]) {
      return [state[key][id], key]
    }
  }
  return [undefined, undefined]
}

function updateObject(object, state) {
  const [_, stateKey] = findObjectById(state, object.id)

  if (!stateKey) {
    return {
      ...state,
    }
  }

  switch (stateKey) {
    case 'objects': {
      return updateWall(object, state)
    }
    case 'elevationLines': {
      return updateElevationLine(object, state)
    }
    case 'segments': {
      return updateSegment(object, state)
    }
    case 'ceilings': {
      return updateCeiling(object, state)
    }
    case 'gridBox': {
      return updateGridBox(object, state)
    }
    default: {
      return {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          [object.id]: {
            ...state[stateKey][object.id],
            ...object,
            // In case updated metadata is pushed
            // without other existing metadata,
            // we should retain the existing metadata
            metadata:
              object.metadata || state[stateKey][object.id].metadata
                ? {
                    ...state[stateKey][object.id].metadata,
                    ...object.metadata,
                  }
                : undefined,
          },
        },
      }
    }
  }
}

const initialState = {
  objects: /** @type {Record<string, import('./types.ts').Wall} */({}),
  segments: /** @type {Record<string, import('./types.ts').WallSegment} */({}),
  obstructions: /** @type {Record<string, import('./types.ts').Obstruction} */({}),
  comfortZones: {},
  dimensions: /** @type {Record<string, import('./types.ts').Dimension} */({}),
  roofs: /** @type {Record<string, import('./types.ts').Roof} */({}),
  doors: /** @type {Record<string, import('./types.ts').Door} */ ({}),
  utilityBoxes: /** @type {Record<string, import('./types.ts').UtilityBox} */({}),
  elevationPoints: /** @type {Record<string, import('./types.ts').ElevationPoint} */({}),
  elevationLines: /** @type {Record<string, import('./types.ts').ElevationLine} */({}),
  roofSections: /** @type {Record<string, import('./types.ts').RoofSection} */ ({}),
  products: /** @type {Record<string, import('./types.ts').Product} */({}),
  units: 'INCHES',
  airflow: {},
  backgroundImage: /** @type {Record<"BACKGROUND_IMAGE", import('./types').BackgroundImage | undefined>}*/ ({
    [LAYER_KEYS.BACKGROUND_IMAGE]: undefined 
  }),
  ceilings: {},
  selectedProductHeight: 0,
  heatMap: {},
}

export default function objectsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_FACILITY: {
      return {
        ...state,
        ...omit(omitBy(action.payload.data, isNil), 'cfd'),
        airflow: {
          data: action.payload.data.airflow,
        },
        heatMap: {
          data: action.payload.data.heatMap,
        },
      }
    }
    case RESET_FACILITY: {
      return initialState
    }
    case ADD_OBJECT: {
      const wall = action.payload.object

      // Create segment models
      const segments = {}
      wall.segments.forEach(segment => {
        segments[segment.id] = segment
      })

      const perimeterPoints = Wall.getCenterLinePointsFromSegments(
        wall.segments
      )

      let roofs = state.roofs
      let roofSections = state.roofSections
      let roofId
      const roofSectionIds = []

      if (wall.isEnclosed && wall.layerKey === LAYER_KEYS.EXTERIOR_WALLS) {
        // Create roof model
        const roof = Roof.createModel(
          perimeterPoints,
          wall.position,
          Wall.getGreatestSegmentHeight(wall.segments)
        )

        // Create roof section model
        const roofSection = RoofSection.createModel(
          wall.id,
          roof.id,
          perimeterPoints,
          wall.position,
          roof.height
        )

        roofId = roof.id
        roofSectionIds.push(roofSection.id)
        roofs = {
          ...state.roofs,
          [roof.id]: roof,
        }

        roofSections = {
          ...state.roofSections,
          [roofSection.id]: roofSection,
        }
      }

      return {
        ...state,
        objects: {
          ...state.objects,
          [wall.id]: {
            ...wall,
            segments: wall.segments.map(segment => segment.id),
            roofId,
            roofSectionIds,
          },
        },
        segments: {
          ...state.segments,
          ...segments,
        },
        roofs,
        roofSections,
      }
    }

    case UPDATE_OBJECT: {
      return updateObject(action.payload.object, state)
    }
    case UPDATE_OBJECTS_PASSIVELY:
    case UPDATE_OBJECTS: {
      let newState = { ...state }
      action.payload.forEach(object => {
        newState = updateObject(object, newState)
      })
      return newState
    }
    case FORCE_UPDATE_OBJECT: {
      return updateObject(action.payload.object, state)
    }
    case DELETE_OBJECTS: {
      let stateCopy = { ...state }

      action.payload.forEach(objectId => {
        const [object, key] = findObjectById(state, objectId)
        const className = objectClassNameFromStateKey(key)
        let isLocked = false

        if (!object) {
          return
        }

        if (className !== undefined && className === 'GridBox') {
          isLocked = action.globalState.layers.layers[
            LAYER_KEYS[object.models[0].layerKey]
          ].locked
        } else {
          isLocked = get(
            action.globalState,
            `layers.layers[${LAYER_KEYS[object.layerKey]}].locked`,
            false
          )
        }

        if (!object || isLocked) {
          return
        }

        // If this is a wall, delete its segments too
        if (className === CLASS_NAMES.WALL) {
          stateCopy = {
            ...stateCopy,
            objects: omit(stateCopy.objects, [objectId]),
            segments: omit(stateCopy.segments, object.segments),
            roofs: omit(stateCopy.roofs, object.roofId),
            roofSections: omit(stateCopy.roofSections, object.roofSectionIds),
            doors: omitBy(stateCopy.doors, door =>
              object.segments.includes(door.wallSegmentId)
            ),
          }
        } else if (className === CLASS_NAMES.WALL_SEGMENT) {
          // If this is a segment update or remove its parent wall
          let newObjects = stateCopy.objects

          const parentWalls = Object.values(newObjects).filter(
            o => o.segments && o.segments.includes(objectId)
          )
          const parentWall = parentWalls[0]
          if (parentWall) {
            // If the parent wall doesn't have any more segments, delete it
            if (!parentWall.segments || parentWall.segments.length === 1) {
              newObjects = omit(newObjects, [parentWall.id])
            } else {
              newObjects = {
                ...newObjects,
                [parentWall.id]: {
                  ...parentWall,
                  segments: parentWall.segments.filter(id => id !== objectId),
                },
              }
            }
          }

          function slidingWindow(array, windowSize) {
            return Array.from(
              { length: array.length - (windowSize - 1) },
              (_, idx) => array.slice(idx, idx+windowSize)
            )
          }
          function negate({ x, y, z }) {
            return { x: -x, y: -y, z: -z }
          }
          function add({ x: x1, y: y1, z: z1 }, { x: x2, y: y2, z: z2 }) {
            return { x: x1+x2, y: y1+y2, z: z1+z2 }
          }
          function sub(a, b) {
            return add(a, negate(b))
          }
          function magnitude({ x, y, z }) {
            return Math.sqrt(x*x + y*y + z*z)
          }

          const windows = parentWall.id in newObjects ? slidingWindow(newObjects[parentWall.id].segments, 2) : []
          let discontinuity = undefined
          for (const [prevID, nextID] of windows) {
            const nextSegment = stateCopy.segments[nextID]
            const prevSegment = stateCopy.segments[prevID]

            if (magnitude(sub(prevSegment.endPoint, nextSegment.startPoint)) >= 0.01) {
              discontinuity = [prevID, nextID]
              break
            }
          }

          // if deleting this segment would make a discontinuity, we need to handle that specially
          if (discontinuity !== undefined) {
            const [prevID, nextID] = discontinuity

            const neighbors = (segmentID) => {
              const segment = stateCopy.segments[segmentID]
              const a = newObjects[parentWall.id].segments
                .find((sID) => magnitude(sub(segment.endPoint, stateCopy.segments[sID].startPoint)) <= 0.001)
              const b = newObjects[parentWall.id].segments
                .find((sID) => magnitude(sub(segment.startPoint, stateCopy.segments[sID].endPoint)) <= 0.001)
              return [...a ? [a] : [], ...b ? [b] : []]
            }
            const parents = new Map()
            const reachable = (() => {
              const queue = []
              const explored = new Set()
              explored.add(prevID)
              queue.push(prevID)
              while (queue.length > 0) {
                const segmentID = queue.pop()
                if (segmentID === nextID) {
                  return true
                }
                for (const neighbor of neighbors(segmentID)) {
                  if (!explored.has(neighbor)) {
                    explored.add(neighbor)
                    queue.push(neighbor)
                    parents.set(neighbor, segmentID)
                  }
                }
              }
              return false
            })()

            if (reachable) {
              // if the discontinuous segments are reachable via other segments, reorder the segments such that there is no gap
              const order = []
              const pop = (map, key) => {
                const value = map.get(key)
                map.delete(key)
                return value
              }
              for (let child = nextID; child !== undefined; child = pop(parents, child)) {
                order.push(child)
              }
              newObjects = {
                ...newObjects,
                [parentWall.id]: {
                  ...parentWall,
                  segments: order,
                },
              }
            } else {
              // otherwise we need to split into two wall objects
              const oldSegments = newObjects[parentWall.id].segments.slice(0, newObjects[parentWall.id].segments.indexOf(prevID)+1)
              const newSegments = newObjects[parentWall.id].segments.slice(newObjects[parentWall.id].segments.indexOf(nextID))

              const newWallID = Util.guid()

              newObjects = {
                ...newObjects,
                [parentWall.id]: {
                  ...parentWall,
                  segments: oldSegments,
                },
                [newWallID]: {
                  ...parentWall,
                  id: newWallID,
                  segments: newSegments,
                },
              }
              stateCopy = {
                ...stateCopy,
                segments: Object.fromEntries(Object.entries(stateCopy.segments).map(([id, segment]) => [id, { ...segment, ...newSegments.includes(id) ? {parentId: newWallID} : {} }])),
              }
            }
          }

          // Filter out any roof sections without parent walls
          const roofSections = {}
          Object.values(stateCopy.roofSections).forEach(section => {
            if (newObjects[section.wallId]) {
              roofSections[section.id] = section
            }
          })

          // Filter out any roofs without sections
          const roofs = {}
          Object.values(roofSections).forEach(section => {
            if (stateCopy.roofs[section.roofId]) {
              roofs[section.roofId] = stateCopy.roofs[section.roofId]
            }
          })

          stateCopy = {
            ...stateCopy,
            objects: newObjects,
            roofSections,
            roofs,
            segments: omit(stateCopy.segments, [objectId]),
            doors: omitBy(
              stateCopy.doors,
              door => objectId === door.wallSegmentId
            ),
          }
        } else if (className === CLASS_NAMES.ELEVATION_POINT) {
          // First see if it belongs to a roof
          const parentRoof = Object.values(stateCopy.roofs).find(
            roof =>
              roof.elevationPointIds &&
              roof.elevationPointIds.includes(objectId)
          )

          if (parentRoof) {
            const newRoofs = {
              ...stateCopy.roofs,
              [parentRoof.id]: {
                ...parentRoof,
                elevationPointIds: parentRoof.elevationPointIds.filter(
                  id => id !== objectId
                ),
              },
            }

            stateCopy = {
              ...stateCopy,
              roofs: newRoofs,
              elevationPoints: omit(stateCopy.elevationPoints, [objectId]),
            }
          }

          // Didn't belong to a roof so it must belong to an elevation line
          const parentEL = Object.values(stateCopy.elevationLines).find(
            el =>
              el.elevationPointIds && el.elevationPointIds.includes(objectId)
          )

          if (parentEL) {
            const newElevationLines = {
              ...stateCopy.elevationLines,
              [parentEL.id]: {
                ...parentEL,
                elevationPointIds: parentEL.elevationPointIds.filter(
                  id => id !== objectId
                ),
              },
            }

            stateCopy = {
              ...stateCopy,
              elevationLines: newElevationLines,
              elevationPoints: omit(stateCopy.elevationPoints, [objectId]),
            }
          }
        } else if (className === CLASS_NAMES.ELEVATION_LINE) {
          const parentRoof = Object.values(stateCopy.roofs).find(roof =>
            roof.elevationLineIds.includes(objectId)
          )

          if (parentRoof) {
            const newRoofs = {
              ...stateCopy.roofs,
              [parentRoof.id]: {
                ...parentRoof,
                elevationLineIds: parentRoof.elevationLineIds.filter(
                  id => id !== objectId
                ),
              },
            }

            stateCopy = {
              ...stateCopy,
              roofs: newRoofs,
              elevationLines: omit(stateCopy.elevationLines, [objectId]),
              elevationPoints: omit(
                stateCopy.elevationPoints,
                object.elevationPointIds
              ),
            }
          }
        } else if (className === CLASS_NAMES.ROOF) {
          stateCopy = {
            ...stateCopy,
          }
        } else if (className === CLASS_NAMES.ROOF_SECTION) {
          const parentWall = state.objects[object.wallId]
          if (!parentWall) {
            stateCopy = {
              ...stateCopy,
              roofSections: omit(stateCopy.roofSections, [object.id]),
            }
          } else {
            stateCopy = {
              ...stateCopy,
            }
          }
        } else {
          if (
            className === CLASS_NAMES.GRID_BOX &&
            !action.clearGridBox
          ) {
            // if its a gridbox, also delete the models inside the grid
            object.models.forEach(model => {
              stateCopy = {
                ...stateCopy,
                [objectStateKeyFromClassName(model.className)]: omit(
                  stateCopy[objectStateKeyFromClassName(model.className)],
                  [model.id]
                ),
              }
            })
          }

          // All other object types delete themselves normally:
          stateCopy = {
            ...stateCopy,
            [objectStateKeyFromClassName(className)]: omit(
              stateCopy[objectStateKeyFromClassName(className)],
              [objectId]
            ),
          }
        }

        // Remove grid box when one of its items was deleted
        let shouldRemoveGridBox = false
        const gridBoxes = get(stateCopy, 'gridBox', {})
        Object.keys(gridBoxes).forEach(id => {
          const gridbox = stateCopy.gridBox[id]
          const gridIds = get(gridbox, 'models', []).map(o => o.id)
          if (gridIds.includes(objectId)) shouldRemoveGridBox = true
        })
        if (shouldRemoveGridBox) stateCopy.gridBox = {}
      })

      return stateCopy
    }
    case DUPLICATE_OBJECTS: {
      let stateCopy = { ...state }
      action.payload.forEach(newObject => {
        const stateKey = objectStateKeyFromClassName(newObject.className)
        stateCopy = {
          ...stateCopy,
          [stateKey]: {
            ...stateCopy[stateKey],
            [newObject.id]: {
              ...newObject.obj,
            },
          },
        }
      })

      return stateCopy
    }
    case DUPLICATE_GRID: {
      let stateCopy = { ...state }
      action.payload.objects.forEach(newObject => {
        const stateKey = objectStateKeyFromClassName(newObject.className)
        if (stateKey === 'products') {
          stateCopy = {
            ...stateCopy,
            [stateKey]: {
              ...stateCopy[stateKey],
              [newObject.id]: {
                cageHeight: newObject.cageHeight,
                className: newObject.className,
                canMountOnColumn: newObject.canMountOnColumn,
                canMountOnWall: newObject.canMountOnWall,
                canMountOverhead: newObject.canMountOverhead,
                canStandAlone: newObject.canStandAlone,
                degreesOfFreedom: newObject.degreesOfFreedom,
                height: newObject.height,
                id: newObject.id,
                isDirectionalOverhead: newObject.isDirectionalOverhead,
                isMounted: newObject.isMounted,
                layerKey: newObject.layerKey,
                metadata: newObject.metadata,
                minFloorClearance: newObject.minFloorClearance,
                minObstructionClearance: newObject.minObstructionClearance,
                minProductClearance: newObject.minProductClearance,
                minRoofClearance: newObject.minRoofClearance,
                minWallClearance: newObject.minWallClearance,
                model: newObject.model,
                mountPosition: newObject.mountPosition,
                mountToCeiling: newObject.mountToCeiling,
                mountingOptionAdderId: newObject.mountingOptionAdderId,
                mountingOptionId: newObject.mountingOptionId,
                pedestals: newObject.pedestals,
                position: newObject.position,
                product: newObject.product,
                recommendedFloorClearance: newObject.recommendedFloorClearance,
                recommendedObstructionClearance:
                  newObject.recommendedObstructionClearance,
                recommendedProductClearance:
                  newObject.recommendedProductClearance,
                recommendedRoofClearance: newObject.recommendedRoofClearance,
                recommendedWallClearance: newObject.recommendedWallClearance,
                rotation: newObject.rotation,
                size: newObject.size,
                tiltEnd: newObject.tiltEnd,
                tiltStart: newObject.tiltStart,
                tiltStep: newObject.tiltStep,
                tubeLength: newObject.tubeLength,
                variationId: newObject.variationId,
                voltage: newObject.voltage,
                voltageId: newObject.voltageId,
              },
            },
          }
        } else if (stateKey === 'obstructions') {
          stateCopy = {
            ...stateCopy,
            [stateKey]: {
              ...stateCopy[stateKey],
              [newObject.id]: {
                className: newObject.className,
                height: newObject.height,
                id: newObject.id,
                length: newObject.length,
                layerKey: newObject.layerKey,
                obstructionType: newObject.obstructionType,
                offset: newObject.offset,
                position: newObject.position,
                positions: newObject.positions,
                resizable: newObject.resizable,
                rotation: newObject.rotation,
                scale: newObject.scale,
                startLocation: newObject.startLocation,
                startPosition: newObject.startPosition,
              },
            },
          }
        } else {
          stateCopy = {
            ...stateCopy,
            [stateKey]: {
              ...stateCopy[stateKey],
              [newObject.id]: {
                ...newObject,
              },
            },
          }
        }
      })

      stateCopy = {
        ...stateCopy,
        gridBox: {
          [action.payload.gridBox.id]: { ...action.payload.gridBox },
        },
      }

      return stateCopy
    }
    case ADD_GRID: {
      return {
        ...state,
        gridBox: {
          [action.payload.gridBox.id]: {
            ...action.payload.gridBox,
          },
        },
      }
    }
    case ADD_ROOF: {
      let roofName = 'Roof 1'
      if (state.roofs && Object.keys(state.roofs).length) {
        roofName = `Roof ${Object.keys(state.roofs).length + 1}`
      }
      return {
        ...state,
        roofs: {
          ...state.roofs,
          [action.payload.roof.id]: {
            ...action.payload.roof,
            color: DEFAULT_ROOF_COLOR,
            title: roofName,
          },
        },
      }
    }
    case UPDATE_ROOF: {
      return updateObject(action.payload.roof, state)
    }
    case UPDATE_ROOF_STRUCTURE: {
      return updateRoofStructure(action.payload, state)
    }
    case ADD_ROOF_SECTION: {
      const roofSection = action.payload.roofSection
      const wall = state.objects[action.payload.roofSection.wallId]

      return {
        ...state,
        roofSections: {
          ...state.roofSections,
          [roofSection.id]: {
            ...roofSection,
          },
        },
        objects: {
          ...state.objects,
          [wall.id]: {
            ...wall,
            roofSectionIds: wall.roofSectionIds.concat([roofSection.id]),
          },
        },
      }
    }
    case UPDATE_ROOF_SECTION: {
      return updateObject(action.payload.roofSection, state)
    }
    case ADD_MOUNTING_BEAM: {
      const roofSection = state.roofSections[action.payload.roofSectionId]
      const updatedBeamModels = roofSection.beamModels.slice()
      if (action.payload.previousBeamId) {
        const prevBeamIndex = roofSection.beamModels.findIndex(
          beam => beam.id === action.payload.previousBeamId
        )
        updatedBeamModels.splice(prevBeamIndex + 1, 0, {
          id: action.payload.beamId,
          position: action.payload.beamPosition,
          edited: action.payload.edited,
        })
      } else {
        updatedBeamModels.push({
          id: action.payload.beamId,
          position: action.payload.beamPosition,
          edited: action.payload.edited,
        })
      }
      return {
        ...state,
        roofSections: {
          ...state.roofSections,
          [roofSection.id]: {
            ...roofSection,
            beamModels: updatedBeamModels,
          },
        },
      }
    }
    case REMOVE_MOUNTING_BEAM: {
      const roofSection = state.roofSections[action.payload.roofSectionId]
      const updatedBeamModels = roofSection.beamModels.slice()
      const beamIndex = roofSection.beamModels.findIndex(
        beam => beam.id === action.payload.beamId
      )
      updatedBeamModels.splice(beamIndex, 1)
      // Since we can't mark the deleted beam as edited,
      // We need to make sure at least one of the beams is
      // So that the SelectedRoofSectionPanel knows the beams have been edited
      if (updatedBeamModels.length) {
        updatedBeamModels[0] = { ...updatedBeamModels[0], edited: true }
      }
      return {
        ...state,
        roofSections: {
          ...state.roofSections,
          [roofSection.id]: {
            ...roofSection,
            beamModels: updatedBeamModels,
          },
        },
      }
    }
    case UPDATE_MOUNTING_BEAM: {
      const { roofSectionId, beamId, beamPosition, direction } = action.payload

      const roofSection = state.roofSections[roofSectionId]
      const beam = roofSection.beamModels.find(beam => beam.id === beamId)

      let updatedBeamModels = roofSection.beamModels.slice()
      const beamIndex = findIndex(
        roofSection.beamModels,
        beamModel => beamModel.id === beamId
      )

      // How much to shift each line
      const beamPositionDiff = {
        x: beamPosition.x - beam.position.x,
        y: beamPosition.y - beam.position.y,
      }

      updatedBeamModels = shiftGridLines({
        models: updatedBeamModels,
        rotation: Math.sin(roofSection.beamRotation),
        spacing: roofSection.beamSpacing,
        startIndex: beamIndex,
        positionDiff: beamPositionDiff,
        direction,
      })

      updatedBeamModels[beamIndex].edited = true

      return {
        ...state,
        roofSections: {
          ...state.roofSections,
          [roofSection.id]: {
            ...roofSection,
            beamModels: updatedBeamModels,
          },
        },
      }
    }
    case ADD_COLUMN_LINE: {
      const roofSection = state.roofSections[action.payload.roofSectionId]
      const updatedColumnLineModels = roofSection.columnLineModels.slice()
      if (action.payload.previousColumnLineId) {
        const prevColumnLineIndex = roofSection.columnLineModels.findIndex(
          columnLine => columnLine.id === action.payload.previousColumnLineId
        )
        updatedColumnLineModels.splice(prevColumnLineIndex + 1, 0, {
          id: action.payload.columnLineId,
          position: action.payload.columnLinePosition,
          edited: action.payload.edited,
        })
      } else {
        updatedColumnLineModels.push({
          id: action.payload.columnLineId,
          position: action.payload.columnLinePosition,
          edited: action.payload.edited,
        })
      }
      return {
        ...state,
        roofSections: {
          ...state.roofSections,
          [roofSection.id]: {
            ...roofSection,
            columnLineModels: updatedColumnLineModels,
          },
        },
      }
    }
    case REMOVE_COLUMN_LINE: {
      const roofSection = state.roofSections[action.payload.roofSectionId]
      const updatedColumnLineModels = roofSection.columnLineModels.slice()
      const columnLineIndex = roofSection.columnLineModels.findIndex(
        columnLine => columnLine.id === action.payload.columnLineId
      )
      updatedColumnLineModels.splice(columnLineIndex, 1)
      // Since we can't mark the deleted column line as edited,
      // We need to make sure at least one of the column lines is
      // So that the SelectedRoofSectionPanel knows the column lines have been edited
      if (updatedColumnLineModels.length) {
        updatedColumnLineModels[0] = { ...updatedColumnLineModels[0], edited: true }
      }
      return {
        ...state,
        roofSections: {
          ...state.roofSections,
          [roofSection.id]: {
            ...roofSection,
            columnLineModels: updatedColumnLineModels,
          },
        },
      }
    }
    case UPDATE_COLUMN_LINE: {
      const {
        roofSectionId,
        columnLineId,
        columnLinePosition,
        direction,
      } = action.payload

      const roofSection = state.roofSections[roofSectionId]
      const columnLine = roofSection.columnLineModels.find(
        columnLine => columnLine.id === columnLineId
      )

      let updatedColumnLineModels = roofSection.columnLineModels.slice()
      const columnLineIndex = findIndex(
        roofSection.columnLineModels,
        columnLine => columnLine.id === columnLineId
      )

      // How much to shift each line
      const columnLinePositionDiff = {
        x: columnLinePosition.x - columnLine.position.x,
        y: columnLinePosition.y - columnLine.position.y,
      }

      updatedColumnLineModels = shiftGridLines({
        models: updatedColumnLineModels,
        rotation: Math.cos(roofSection.beamRotation),
        spacing: roofSection.columnSpacing,
        startIndex: columnLineIndex,
        positionDiff: columnLinePositionDiff,
        direction,
      })
      updatedColumnLineModels[columnLineIndex].edited = true

      return {
        ...state,
        roofSections: {
          ...state.roofSections,
          [roofSection.id]: {
            ...roofSection,
            columnLineModels: updatedColumnLineModels,
          },
        },
      }
    }
    case DELETE_ROOF_SECTION: {
      const parentWall = state.objects[action.payload.roofSection.wallId]

      return {
        ...state,
        objects: {
          ...state.objects,
          [parentWall.id]: {
            ...parentWall,
            roofSectionIds: parentWall.roofSectionIds.filter(
              id => id !== action.payload.roofSection.id
            ),
          },
        },
        roofSections: omit(state.roofSections, [action.payload.roofSection.id]),
      }
    }
    case REVERT_ROOF_SECTIONS: {
      if (!action.payload.id) {
        return {
          ...state,
        }
      }
      const parentWallId = state.roofSections[action.payload.id].wallId
      const parentWall = state.objects[parentWallId]
      const roofSectionIds = state.objects[parentWallId].roofSectionIds
      const firstSectionId = roofSectionIds[0]

      let segmentObjects = parentWall.segments
      const hasSegments = segmentObjects && segmentObjects.length
      if (hasSegments && typeof segmentObjects[0] === 'string') {
        segmentObjects = segmentObjects.map(id => state.segments[id])
      }
      const perimeterPoints = Wall.getCenterLinePointsFromSegments(
        segmentObjects
      )

      return {
        ...state,
        objects: {
          ...state.objects,
          [parentWallId]: {
            ...state.objects[parentWallId],
            roofSectionIds: [firstSectionId],
          },
        },
        roofSections: {
          ...omit(state.roofSections, roofSectionIds),
          [firstSectionId]: {
            ...state.roofSections[firstSectionId],
            perimeterPoints,
            position: parentWall.position,
          },
        },
      }
    }
    case ADD_ELEVATION_POINT: {
      return {
        ...state,
        needsSaved: true,
        elevationPoints: {
          ...state.elevationPoints,
          [action.payload.elevationPoint.id]: {
            ...action.payload.elevationPoint,
          },
        },
      }
    }
    case UPDATE_ELEVATION_POINT: {
      return updateObject(action.payload.elevationPoint, state)
    }
    case ADD_ELEVATION_LINE: {
      const roof = state.roofs[action.payload.elevationLine.roofId]
      const elevationPointsArray = action.payload.elevationPoints
      const elevationPointsObj = {}
      const roofLineIds = get(roof, 'elevationLineIds', {})

      elevationPointsArray.forEach(ep => (elevationPointsObj[ep.id] = ep))

      return {
        ...state,
        needsSaved: true,
        elevationLines: {
          ...state.elevationLines,
          [action.payload.elevationLine.id]: {
            ...action.payload.elevationLine,
          },
        },
        elevationPoints: {
          ...state.elevationPoints,
          ...elevationPointsObj,
        },
        roofs: {
          ...state.roofs,
          [roof.id]: {
            ...roof,
            elevationLineIds: [...roofLineIds, action.payload.elevationLine.id],
          },
        },
      }
    }
    case UPDATE_ELEVATION_LINE: {
      return updateObject(action.payload.elevationLine, state)
    }
    case REMOVE_ELEVATION_LINE_HEIGHT: {
      return updateObject(action.payload.elevationLine, state)
    }
    case UPDATE_WALL: {
      return updateObject(action.payload.wall, state)
    }
    case UPDATE_SEGMENT: {
      return updateObject(action.payload.segment, state)
    }
    case UPDATE_SEGMENTS: {
      const newSegments = action.payload.ids.map(id => ({
        ...state.segments[id],
        ...action.payload.updates,
      }))
      const walls = uniqBy(newSegments, 'parentId').map(
        s => state.objects[s.parentId]
      )

      const roofSections = {}
      const roofs = {}
      walls.forEach(wall => {
        wall.roofSectionIds.forEach(roofSectionId => {
          roofSections[roofSectionId] = {
            ...state.roofSections[roofSectionId],
            ...pick(action.payload.updates, ['height']),
          }
        })
        // Interior walls don't have roofs,
        // So only update the roof if there is a roofId
        if (wall.roofId) {
          roofs[wall.roofId] = {
            ...state.roofs[wall.roofId],
            ...pick(action.payload.updates, ['height']),
          }
        }
      })

      return {
        ...state,
        roofSections: {
          ...state.roofSections,
          ...roofSections,
        },
        roofs: {
          ...state.roofs,
          ...roofs,
        },
        segments: {
          ...state.segments,
          ...keyBy(newSegments, 'id'),
        },
      }
    }
    case ADD_DOOR: {
      return {
        ...state,
        doors: {
          ...state.doors,
          [action.payload.door.id]: {
            ...action.payload.door,
          },
        },
      }
    }
    case UPDATE_DOOR: {
      return updateObject(action.payload.door, state)
    }
    case ADD_UTILITY_BOX: {
      return {
        ...state,
        utilityBoxes: {
          ...state.utilityBoxes,
          [action.payload.utilityBox.id]: {
            ...action.payload.utilityBox,
          },
        },
      }
    }
    case UPDATE_UTILITY_BOX: {
      return updateObject(action.payload.utilityBox, state)
    }
    case ADD_PRODUCT: {
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.product.id]: {
            ...action.payload.product,
            layerKey: LAYER_KEYS.PRODUCTS,
            includedInQuote: true,
          },
        },
      }
    }
    case DISTRIBUTE_PRODUCTS: {
      const products = {}
      action.payload.models.forEach(model => {
        products[model.id] = {
          ...model,
        }
      })
      return {
        ...state,
        products: {
          ...state.products,
          ...products,
        },
        gridBox: {
          [action.payload.gridBox.id]: action.payload.gridBox,
        },
      }
    }
    case UPDATE_PRODUCT: {
      return updateObject(action.payload.product, state)
    }
    case ADD_OBSTRUCTION: {
      return {
        ...state,
        obstructions: {
          ...state.obstructions,
          [action.payload.obstruction.id]: {
            ...action.payload.obstruction,
            id: action.payload.obstruction.id,
            layerKey: LAYER_KEYS.OBSTRUCTIONS,
          },
        },
      }
    }
    case DISTRIBUTE_OBSTRUCTIONS: {
      const obstructions = {}
      action.payload.models.forEach(model => {
        obstructions[model.id] = {
          ...model,
        }
      })
      return {
        ...state,
        obstructions: {
          ...state.obstructions,
          ...obstructions,
        },
        gridBox: {
          [action.payload.gridBox.id]: action.payload.gridBox,
        },
      }
    }
    case DISTRIBUTE_DOORS: {
      const doors = {}
      action.payload.models.forEach(model => {
        doors[model.id] = {
          ...model,
        }
      })
      return {
        ...state,
        doors: {
          ...state.doors,
          ...doors,
        },
      }
    }
    case UPDATE_OBSTRUCTION: {
      return updateObject(action.payload.obstruction, state)
    }
    case ADD_COMFORT_ZONE: {
      return {
        ...state,
        comfortZones: {
          ...state.comfortZones,
          [action.payload.comfortZone.id]: {
            ...action.payload.comfortZone,
            name: `Zone ${Object.keys(state.comfortZones).length + 1}`,
            id: action.payload.comfortZone.id,
            layerKey: LAYER_KEYS.COMFORT_ZONES,
          },
        },
      }
    }
    case UPDATE_COMFORT_ZONE: {
      return updateObject(action.payload.comfortZone, state)
    }
    case CFD.UPLOAD_COMPLETE: {
      const comfortZonesArray = Object.entries(state.comfortZones)
      const comfortZones = {}
      comfortZonesArray.forEach(([id, comfortZone]) => {
        comfortZones[id] = {
          ...comfortZone,
          metrics: {},
        }
      })
      return {
        ...state,
        comfortZones,
      }
    }
    case ADD_DIMENSION: {
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          [action.payload.dimension.id]: {
            ...action.payload.dimension,
            id: action.payload.dimension.id,
            layerKey: LAYER_KEYS.DIMENSIONS,
            dimensionFinished: action.payload.dimension.dimensionFinished,
            startPos: action.payload.dimension.startPos,
            endPos: action.payload.dimension.endPos,
          },
        },
      }
    }
    case UPDATE_DIMENSION: {
      return updateObject(action.payload.dimension, state)
    }
    case DELETE_DIMENSION: {
      return {
        ...state,
        dimensions: omit(state.dimensions, [action.payload.id]),
      }
    }
    case ADD_CEILING: {
      return {
        ...state,
        needsSaved: true,
        ceilings: {
          ...state.ceilings,
          [action.payload.ceiling.id]: {
            ...action.payload.ceiling,
          },
        },
      }
    }
    case UPDATE_CEILING: {
      return updateObject(action.payload.ceiling, state)
    }
    case DELETE_CEILING: {
      return {
        ...state,
        ceilings: omit(state.ceilings, [action.payload.ceiling.id]),
      }
    }
    case REQUEST_AIRFLOW: {
      return {
        ...state,
        airflow: {
          isFetching: true,
          isValid: false,
          ...state.airflow,
        },
      }
    }
    case RECEIVE_AIRFLOW: {
      return {
        ...state,
        airflow: {
          isFetching: false,
          isValid: true,
          data: action.payload,
          selectedLayer:
            state.airflow.selectedLayer ||
            (action.payload && action.payload[3]),
        },
      }
    }
    case UPDATE_AIRFLOW_STATUS: {
      return {
        ...state,
        airflow: {
          ...state.airflow,
          isValid: action.payload,
        },
      }
    }
    case SET_AIRFLOW_LAYER: {
      if (!state.airflow.data)
        return {
          ...state,
        }

      const selected = state.airflow.data.filter(d => {
        return d.evaluationHeight === action.payload.layerKey
      })
      return {
        ...state,
        airflow: {
          ...state.airflow,
          selectedLayer: selected[0],
        },
      }
    }
    case ADD_BACKGROUND_IMAGE: {
      return {
        ...state,
        backgroundImage: action.payload.backgroundImage,
      }
    }
    case DELETE_BACKGROUND_IMAGE: {
      return {
        ...state,
        backgroundImage: initialState.backgroundImage,
      }
    }
    case UPDATE_BACKGROUND_IMAGE: {
      return {
        ...state,
        backgroundImage: {
          [LAYER_KEYS.BACKGROUND_IMAGE]: {
            ...state.backgroundImage[LAYER_KEYS.BACKGROUND_IMAGE],
            ...action.payload.backgroundImage
          }
        }
      }
    }
    case DESELECT_OBJECTS:
    case SELECT_OBJECTS: {
      // If objects are selected and we update the selection we need
      // to make sure we update the objects so they re-render
      const selectedCount = action.globalState.selectedObjects.length
      const newSelectedCount = get(action.payload, 'objects', []).length
      if (selectedCount && newSelectedCount) {
        return onMultiSelect(state)
      }

      return { ...state }
    }
    case TOGGLE_LAYER_VISIBILITY: {
      const wallLayers = ['INTERIOR_WALLS', 'EXTERIOR_WALLS']
      const isWallLayer = wallLayers.includes(action.payload.layerKey)
      if (!isWallLayer) return { ...state }

      if (isEmpty(state.doors) && isEmpty(state.utilityBoxes))
        return { ...state }

      const doors = {}
      const utilityBoxes = {}
      if (!isEmpty(state.doors)) {
        Object.keys(state.doors).forEach(key => {
          doors[key] = {
            ...state.doors[key],
            // updateId: Util.guid(),
          }
        })
      }
      if (!isEmpty(state.utilityBoxes)) {
        Object.keys(state.utilityBoxes).forEach(key => {
          utilityBoxes[key] = {
            ...state.utilityBoxes[key],
            // updateId: Util.guid(),
          }
        })
      }

      return {
        ...state,
        doors: {
          ...doors,
        },
        utilityBoxes: {
          ...utilityBoxes,
        },
      }
    }
    case UPDATE_PRODUCT_HEIGHT: {
      return {
        ...state,
        selectedProductHeight: action.payload,
      }
    }
    case ADD_METADATA_IMAGE: {
      const { objectId, cloudinaryId, width, height } = action.payload

      const [object, _key] = findObjectById(state, objectId)

      if (!object) {
        return {
          ...state,
        }
      }

      const newObject = {
        ...object,
        metadata: {
          ...object.metadata,
          images: [
            ...get(object, 'metadata.images', []),
            {
              cloudinaryId,
              width,
              height,
            },
          ],
        },
      }

      return updateObject(newObject, state)
    }
    case REQUEST_HEAT_MAP: {
      return {
        ...state,
        heatMap: {
          isFetching: true,
          isValid: false,
          ...state.heatMap,
        },
      }
    }
    case RECEIVE_HEAT_MAP: {
      return {
        ...state,
        heatMap: {
          isFetching: false,
          isValid: true,
          data: action.payload,
          selectedLayer: action.payload && action.payload[0],
        },
      }
    }
    case UPDATE_HEAT_MAP_STATUS: {
      return {
        ...state,
        heatMap: {
          ...state.heatMap,
          isValid: action.payload,
        },
      }
    }
    case SET_HEAT_MAP_LAYER: {
      if (!state.heatMap.data)
        return {
          ...state,
        }

      const selected = state.heatMap.data.filter(d => {
        return d.evaluationHeight === action.payload.layerKey
      })
      return {
        ...state,
        heatMap: {
          ...state.heatMap,
          selectedLayer: selected[0],
        },
      }
    }
    default: {
      return state
    }
  }
}
