import { produce } from 'immer'
import LAYER_KEYS from '~/config/layerKeys'

export const patchupBackgroundImage = (data: any) => {
  return produce(data, (facility: any) => {
    const backgroundImage = facility.backgroundImage?.[LAYER_KEYS.BACKGROUND_IMAGE]
    if (!backgroundImage) return
    if (!backgroundImage.position) backgroundImage.position = { x: 0, y: 0, z: 0 }
    if (!backgroundImage.rotation) backgroundImage.rotation = { x: 0, y: 0, z: 0 }
  })
}
