import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import { graphql } from '@apollo/client/react/hoc'
import { ActionCreators } from 'redux-undo'
import { Redirect, Route, Prompt, withRouter } from 'react-router-dom'
import get from 'lodash-es/get'
import isEqual from 'lodash-es/isEqual'
import { Helmet } from 'react-helmet'
import { withProps } from 'recompact'

import { getTitle } from 'config/titles'
import routes from 'config/routes'
import { formatNumberDigits, getDockedPanelWidth } from 'lib/utils'
import { currentUserCanEdit } from 'lib/currentUserIs'

import { GET_FACILITY_QUERY } from 'client/queries'
import withUser from 'client/decorators/withUser'
import { UPLOAD_SNAPSHOT_MUTATION } from 'client/mutations'
import { showAlert } from 'store/alert'
import { start2DFit } from 'store/camera'
import { loadFacility, resetFacility } from 'store/objects'
import { saveObjects } from 'store/objectsPersistence'
import { showPanel, resetPanels, setDefaultPanel } from 'store/panel'
import {
  DEFAULT_SELECTED_PANEL,
  DOCK_ITEM_PANEL,
  GET_QUOTE_PANEL,
} from 'store/panel/types'
import { setActiveTool } from 'store/tools'
import {
  anyObjectSelected,
  deselectObjects,
} from 'store/selectedObjects/selectors'
import { TYPES } from 'store/units/constants'

import Loader from 'components/UIKit/Loader'
import Overlay from 'components/UIKit/Overlay'
import Space from 'components/UIKit/Space'
import Toolbar from 'components/UIKit/Toolbar'

import Chrome from 'components/Chrome/'
import DockedPanelTab from 'components/DockedPanelTab'
import DrawingCanvas from 'components/DrawingCanvas'
import DrawingCanvasContainer from 'components/DrawingCanvasContainer'
import DrawingTools from 'components/DrawingTools'
import DrawingSettings from 'components/DrawingSettings'
import PanelManager from 'components/PanelManager'
import LoadingDock from 'components/LoadingDock'
import Lock from 'components/Lock'
import HistoryToolbarGroup from 'components/HistoryToolbarGroup'
import ShapeTemplatesModal from 'components/Modals/ShapeTemplatesModal'
import RoofTemplatesModal from 'components/Modals/RoofTemplatesModal'
import ZoomToolbarGroup from 'components/ZoomToolbarGroup'
import DistributeDoorModal from 'components/Modals/DistributeDoorModal'
import DistributeObstructionModal from 'components/Modals/DistributeObstructionModal'
import DistributeProductModal from 'components/Modals/DistributeProductModal'
import UploadImageModal from 'components/Modals/UploadImageModal'
import ConvertImageModal from 'components/Modals/ConvertImageModal'
import ScaleImageModal from 'components/Modals/ScaleImageModal'
import AnnotateImageModal from 'components/Modals/AnnotateImageModal'
import AddProductOverlayImageModal from 'components/Modals/AddProductOverlayImageModal'
import ViewImageModal from 'components/Modals/ViewImageModal'
import EditImageModal from 'components/Modals/EditImageModal'
import WebcamCaptureModal from 'components/Modals/WebcamCaptureModal'
import AddControlPanelInstallInfoModal from 'components/Modals/AddControlPanelInstallInfoModal'
import AddElectricPanelInstallInfoModal from 'components/Modals/AddElectricPanelInstallInfoModal'
import AddProductInstallInfoModal from 'components/Modals/AddProductInstallInfoModal'
import SubmitFeedbackButton from 'components/SubmitFeedbackButton'
import AppContainer from 'components/App/styled/AppContainer'
import { hasPermission } from 'components/RequiredPermission'
import PermissionDeniedRoute from 'components/PermissionDeniedRoute'
import DoesNotExistRoute from 'components/DoesNotExistRoute'
import CloseFullscreenButton from 'components/CloseFullscreenButton'
import PerspectiveSwitch from 'components/PerspectiveSwitch'

import { TopLeft, TopLeftTouch } from './TopLeft'
import TopCenter from './TopCenter'
import TopRight from './TopRight'
import MiddleTouch from './MiddleTouch'
import MiddleCenter from './MiddleCenter'
import BottomLeft from './BottomLeft'
import BottomCenter from './BottomCenter'
import BottomRight from './BottomRight'

import DrawingPanel from './styled/DrawingPanel'
import PerspectiveSwitchContainer from './styled/PerspectiveSwitchContainer'
import FlashBanner from 'components/UIKit/FlashBanner'
import { CFDUploadsProvider } from '~/hooks/contexts/CFDUploadsContext'

import { withSentryRouting } from '@sentry/react'
import { DimensionsTool } from '~/components/DrawingTools/DimensionsTool';
const SentryRoute = withSentryRouting(Route)

class FacilityScreen extends Component {
  static propTypes = {
    allFacilities: PropTypes.object,
    canEditTemplate: PropTypes.bool,
    canViewFacility: PropTypes.bool,
    canViewTemplate: PropTypes.bool,
    collapsed: PropTypes.bool,
    defaultPanel: PropTypes.string,
    facilityData: PropTypes.object,
    fullFacilityData: PropTypes.object,
    history: PropTypes.object,
    isSaving: PropTypes.bool,
    isTouchUI: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    needsSaved: PropTypes.bool,
    onGetQuote: PropTypes.func,
    onLoadFacility: PropTypes.func,
    onMount: PropTypes.func,
    onResetFacility: PropTypes.func,
    onSaveObjects: PropTypes.func,
    onShowAlert: PropTypes.func,
    onSnapshot: PropTypes.func,
    productData: PropTypes.object,
    setDefaultPanel: PropTypes.func,
    showPanel: PropTypes.func,
    snapshots: PropTypes.object,
    status: PropTypes.object,
    onSaveCameraPosition: PropTypes.func,
    online: PropTypes.bool,
    user: PropTypes.object,
    shared: PropTypes.bool,
    globalState: PropTypes.object,
  }

  state = {
    loadedCanvas: false,
    uploadingSnapshot: false,
  }

  componentDidMount() {
    const { onMount } = this.props

    if (!this.props.facilityData.loading) {
      this.handleLoadData(this.props)
    }

    this.setState({
      loadedCanvas: true,
    })
    if (this.props.isTouchUI) {
      this.props.setDefaultPanel({ type: DOCK_ITEM_PANEL })
    } else {
      this.props.setDefaultPanel({ type: DEFAULT_SELECTED_PANEL })
    }
    this.autoSaveInterval = setInterval(this.handleAutoSave, 5000)
    window.addEventListener('beforeunload', this.handleWarnUnsaved)

    onMount()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const units = get(this.props, 'facilityData.facility.units')
    const nextUnits = get(nextProps, 'facilityData.facility.units')
    const newFacility =
      get(this.props, 'facilityData.facility.floor.version.id') !==
      get(nextProps, 'facilityData.facility.floor.version.id')

    // Force rerender of drawing canvas
    if (newFacility) {
      this.setState({ loadedCanvas: false })
    }

    if (
      (this.props.facilityData.loading && !nextProps.facilityData.loading) ||
      newFacility ||
      !isEqual(units, nextUnits)
    ) {
      this.handleLoadData(nextProps)

      if (get(this.props.history, 'location.state.getQuote')) {
        this.props.history.replace({
          pathname: window.location.pathname,
          state: {},
        })
        this.props.onGetQuote()
      }

      // Store this ID so we can redirect to it after Salesforce auth
      window.localStorage.setItem(
        'lastViewedFacilityId',
        get(nextProps, 'facilityData.facility.id')
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.loadedCanvas && prevState.loadedCanvas) {
      this.setState({ loadedCanvas: true })
    }
    if (this.props.isTouchUI && !prevProps.isTouchUI) {
      this.props.setDefaultPanel({ type: DOCK_ITEM_PANEL })
    }
    if (!this.props.isTouchUI && prevProps.isTouchUI) {
      this.props.setDefaultPanel({ type: DEFAULT_SELECTED_PANEL })
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoSaveInterval)
    window.removeEventListener('beforeunload', this.handleWarnUnsaved)
  }

  handleAutoSave = () => {
    const {
      needsSaved,
      online,
      onSaveObjects,
      facilityData,
      user,
      shared,
    } = this.props
    const versionId = get(facilityData, 'facility.floor.version.id')
    const facility = get(facilityData, 'facility')

    if (
      needsSaved &&
      versionId &&
      online &&
      currentUserCanEdit(facility, user, shared)
    ) {
      onSaveObjects({
        versionId,
      })
    }
  }

  handleWarnUnsaved = event => {
    if (this.props.needsSaved || this.props.isSaving) {
      const warning = 'Your facility has unsaved changes! Leave anyway?'
      event.returnValue = warning

      return event
    }

    return undefined
  }

  handleLoadData = props => {
    const data = get(props, 'facilityData.facility.floor.version.data')
    const units = get(props, 'facilityData.facility.units')

    if (data) {
      this.props.onLoadFacility({
        data: {
          objects: data.objects,
          segments: data.segments,
          products: data.products,
          doors: data.doors,
          utilityBoxes: data.utilityBoxes,
          comfortZones: data.comfortZones,
          obstructions: data.obstructions,
          dimensions: data.dimensions,
          roofs: data.roofs,
          roofSections: data.roofSections,
          elevationPoints: data.elevationPoints,
          elevationLines: data.elevationLines,
          backgroundImage: data.backgroundImage,
          ceilings: data.ceilings,
          units: data.units,
          cfd: {
            isValid: get(data, 'cfd.isValid'),
            validTypes: get(data, 'cfd.validTypes', []),
            metrics: get(data, 'cfd.metrics'),
          },
        },
        install: data.install,
        layers: data.layers,
        userInterface: data.userInterface,
        loadingDock: data.loadingDock,
        units: {
          [TYPES.DISTANCE]: units,
          [TYPES.VELOCITY]: units,
          [TYPES.TEMPERATURE]: units,
        },
      })
    } else {
      this.props.onResetFacility()
    }
  }

  handleUnimplementedFeatureClick = event => {
    event.preventDefault()

    this.props.onShowAlert({
      text: 'This feature is not implemented yet.',
      type: 'warning',
    })
  }

  handleSnapshot = data => {
    const { onSnapshot, facilityData } = this.props
    const totalSnapshots = get(
      facilityData,
      'facility.floor.version.snapshots',
      []
    ).length

    this.setState({ uploadingSnapshot: true })
    const done = () => this.setState({ uploadingSnapshot: false })
    onSnapshot({
      title: `Snapshot ${formatNumberDigits(totalSnapshots + 1)}`,
      versionId: facilityData.facility.floor.version.id,
      dataUrl: data,
    })
      .then(done, done)
      .catch(done)
  }

  filterAllFacilities = () => {
    const { allFacilities, facilityData } = this.props

    return get(allFacilities, 'allFacilities.facilities', []).map(facility => ({
      id: facility.id,
      title: facility.name,
      onClick: event => this.handleFacilityMenuClick(facility, event),
      active: facility.id === facilityData.facility.id,
      icon: {
        name: 'building',
      },
    }))
  }

  getOverlayOffsetProps = () =>
    this.props.collapsed
      ? {
          offsetSide: null,
          offsetValue: null,
        }
      : {
          offsetSide: this.props.isTouchUI ? 'left' : 'right',
          offsetValue: `${getDockedPanelWidth()}px`,
        }

  render() {
    const {
      facilityData,
      fullFacilityData,
      showPanel,
      collapsed,
      defaultPanel,
      match,
      location,
      isSaving,
      needsSaved,
      status,
      canViewFacility,
      canViewTemplate,
      online,
      user,
      shared,
    } = this.props
    const { loadedCanvas } = this.state

    const error = get(facilityData, 'error.message')
    if (error && error.match(/\bnot allowed/i)) {
      return (
        <PermissionDeniedRoute message="You do not have access to this facility because it is owned by a user outside your organization and BAF." />
      )
    }
    
    if (facilityData?.loading || !fullFacilityData || fullFacilityData.loading) {
      return <Loader centered />
    }

    const facilityId = match?.params?.id
    const floorId = fullFacilityData?.facility?.floor?.id
    const versionId = fullFacilityData?.facility?.floor?.version?.id

    // Redirecting to full url to provide app access to floor and version ids
    if (match.path === routes.facility.short && facilityId && floorId && versionId) {
      return <Redirect to={`/facility/${facilityId}/area/${floorId}/version/${versionId}`}/>
    }

    const matchedFloorId = match?.params?.floorId
    const matchedVersionId = match?.params?.versionId
    const isValidFloorId = !!fullFacilityData?.facility?.floors?.find(({id}) => id === matchedFloorId)
    const isValidVersionId = !!fullFacilityData?.facility?.floor?.versions?.find(({id}) => matchedVersionId === id)
    const isValidFacility = isValidFloorId && isValidVersionId
    
    if ((!facilityData?.loading && !fullFacilityData) || !isValidFacility) {
      return <DoesNotExistRoute />
    }
    
    const isTemplate = get(facilityData, 'facility.isTemplate')
    if (!canViewFacility || (isTemplate && !canViewTemplate)) {
      return <PermissionDeniedRoute />
    }

    const templateStatus =
      isTemplate && this.props.canEditTemplate ? 'unlocked' : 'locked'

    const shouldShowLoadingDock =
      this.props.isTouchUI && online && !this.props.isFullscreen

    return (
      <AppContainer>
        <Helmet>
          <title>{getTitle(facilityData.facility.name)}</title>
        </Helmet>
        {!currentUserCanEdit(get(facilityData, 'facility'), user, shared) && (
          <FlashBanner
            type="warning"
            text="Your changes will not be saved. Contact the author of this facility to make changes."
          />
        )}
        <Prompt
          when={
            (isSaving || needsSaved) &&
            online &&
            currentUserCanEdit(get(facilityData, 'facility'), user, shared)
          }
          message={nextLocation => {
            if (
              nextLocation.pathname.split('/')[1] === 'facility' &&
              location.pathname.split('/')[1] === 'facility' &&
              nextLocation.pathname.split('/')[2] ===
                location.pathname.split('/')[2]
            ) {
              return true
            }

            return 'Are you sure you want to leave? You have unsaved changes!'
          }}
        />
        <Chrome>
          {this.props.isTouchUI && (
            <TopLeftTouch
              // `withRouter` is being called in `TopLeftTouch` and *should*
              // work to re-render, but something isn't working right, so this
              // just passes `match={match}` to force the re-render.
              // TODO: Come up with a better solution here!
              match={match}
              key="topLeft"
              facility={facilityData.facility}
              versionId={get(
                this.props,
                'facilityData.facility.floor.version.id'
              )}
              online={online}
            />
          )}
          {!this.props.isTouchUI && (
            <TopLeft
              // `withRouter` is being called in `TopLeftTouch` and *should*
              // work to re-render, but something isn't working right, so this
              // just passes `match={match}` to force the re-render.
              // TODO: Come up with a better solution here!
              match={match}
              key="topLeft"
              facility={facilityData.facility}
              onUnimplementedFeatureClick={this.handleUnimplementedFeatureClick}
              versionId={get(
                this.props,
                'facilityData.facility.floor.version.id'
              )}
              online={online}
            />
          )}
          <TopCenter key="topCenter" />
          <TopRight
            key="topRight"
            facility={facilityData.facility}
            onUnimplementedFeatureClick={this.handleUnimplementedFeatureClick}
            online={online}
          />
          <MiddleTouch
            key="middle"
            match={match}
            facility={facilityData.facility}
            versionId={get(
              this.props,
              'facilityData.facility.floor.version.id'
            )}
          />
          <HistoryToolbarGroup
            key="middleLeft"
            onUnimplementedFeatureClick={this.handleUnimplementedFeatureClick}
          />
          <MiddleCenter
            key="middleCenter"
            onUnimplementedFeatureClick={this.handleUnimplementedFeatureClick}
          />
          <ZoomToolbarGroup key="middleRight" />
          <div key="canvas">
            <DrawingCanvasContainer
              hasDockedPanel={!collapsed}
              ref={el => {
                this.containerEl = el
              }}
            >
              {loadedCanvas && (
                <>
                  {status.disabledCanvas && (
                    <Overlay {...this.getOverlayOffsetProps()} />
                  )}
                  {!this.props.isFullscreen ? (
                    <>
                      <DrawingPanel isTouchUI={this.props.isTouchUI}>
                        <Space bottom="s">
                          <DrawingTools key="1" online={online} />
                        </Space>
                        <Space bottom="s">
                          <DrawingSettings online={online} />
                        </Space>
                        <DimensionsTool/>
                      </DrawingPanel>
                      <SubmitFeedbackButton />
                    </>
                  ) : (
                    <>
                      <CloseFullscreenButton />
                      <PerspectiveSwitchContainer>
                        <PerspectiveSwitch />
                      </PerspectiveSwitchContainer>
                    </>
                  )}
                  {isTemplate && (
                    <Lock type="template" status={templateStatus} />
                  )}
                  {!isTemplate &&
                    !currentUserCanEdit(
                      get(facilityData, 'facility'),
                      user,
                      shared
                    ) && <Lock type="facility" status="locked" />}
                  <DrawingCanvas
                    key="2"
                    facility={facilityData.facility}
                    online={online}
                  />
                </>
              )}
            </DrawingCanvasContainer>
            {!this.props.isFullscreen ? (
              <PanelManager
                snapshots={get(
                  facilityData,
                  'facility.floor.version.snapshots',
                  []
                )}
                onSnapshot={this.handleSnapshot}
                uploadingSnapshot={this.state.uploadingSnapshot}
                facility={facilityData.facility}
                versionId={get(facilityData, 'facility.floor.version.id')}
                forceAlignment={this.props.isTouchUI && 'left'}
                online={online}
              />
            ) : null}

            {this.props.isTouchUI && !this.props.isFullscreen && (
              <Toolbar
                size="xl"
                variant="dark"
                panelToolbarPosition="bottom"
                isTouchUI={this.props.isTouchUI}
              >
                <HistoryToolbarGroup
                  onUnimplementedFeatureClick={
                    this.handleUnimplementedFeatureClick
                  }
                  size="100%"
                  alignment="space-between"
                  actionSize="s"
                  online={online}
                />
              </Toolbar>
            )}
            {shouldShowLoadingDock && <LoadingDock />}
            {collapsed && (
              <DockedPanelTab
                onClick={event => {
                  event.preventDefault()
                  showPanel({ type: defaultPanel })
                }}
              />
            )}
            <SentryRoute
              path={`${match.url}${routes.modals.shapeTemplates}`}
              render={props => (
                <ShapeTemplatesModal parentRoute={match.url} {...props} />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.roofTemplates}`}
              render={props => (
                <RoofTemplatesModal parentRoute={match.url} {...props} />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.distributeDoor}`}
              render={props => (
                <DistributeDoorModal parentRoute={match.url} {...props} />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.distributeObstruction}`}
              render={props => (
                <DistributeObstructionModal
                  parentRoute={match.url}
                  {...props}
                />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.distributeProduct}`}
              render={props => (
                <DistributeProductModal parentRoute={match.url} {...props} />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.uploadImage}`}
              render={props =>
                props.match.params.type !== 'metadata' ||
                anyObjectSelected(this.props.globalState) ? (
                  <UploadImageModal parentRoute={match.url} {...props} />
                ) : (
                  <Redirect to={match.url} />
                )
              }
            />
            <SentryRoute
              path={`${match.url}${routes.modals.editImage}`}
              render={props => (
                <EditImageModal parentRoute={match.url} {...props} />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.convertImage}`}
              render={props => (
                <ConvertImageModal parentRoute={match.url} {...props} />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.scaleImage}`}
              render={props => (
                <ScaleImageModal parentRoute={match.url} {...props} />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.viewImage}`}
              render={props => (
                <ViewImageModal parentRoute={match.url} {...props} />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.annotateImage}`}
              render={props => (
                <AnnotateImageModal parentRoute={match.url} {...props} />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.addProductOverlayImage}`}
              render={props => (
                <AddProductOverlayImageModal
                  parentRoute={match.url}
                  {...props}
                />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.webcamCapture}`}
              render={props => (
                <WebcamCaptureModal parentRoute={match.url} {...props} />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.addControlPanelInstallInfo}`}
              render={props => (
                <AddControlPanelInstallInfoModal
                  parentRoute={match.url}
                  online={online}
                  {...props}
                />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.addElectricPanelInstallInfo}`}
              render={props => (
                <AddElectricPanelInstallInfoModal
                  parentRoute={match.url}
                  online={online}
                  {...props}
                />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.addProductInstallInfo}`}
              render={props => (
                <AddProductInstallInfoModal
                  parentRoute={match.url}
                  online={online}
                  {...props}
                />
              )}
            />
          </div>
          <BottomLeft
            key="bottomLeft"
            versionId={get(
              this.props,
              'facilityData.facility.floor.version.id'
            )}
            onUnimplementedFeatureClick={this.handleUnimplementedFeatureClick}
          />
          <BottomCenter key="bottomCenter" />
          <BottomRight
            key="bottomRight"
            versionId={get(
              this.props,
              'facilityData.facility.floor.version.id'
            )}
            onUnimplementedFeatureClick={this.handleUnimplementedFeatureClick}
          />
        </Chrome>
      </AppContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const { auth, objectsPersistence, panel, status, userInterface, loadingDock, layers } = state
  return {
    userId: auth.userId,
    needsSaved: objectsPersistence.needsSaved,
    isSaving: objectsPersistence.isSaving,
    collapsed: panel.right.collapsed,
    defaultPanel: panel.right.defaultPanel,
    isTouchUI: userInterface.isTouchUI,
    isFullscreen: userInterface.isFullscreen,
    loadingDock,
    status,
    shared: !layers.layers['FACILITY'].locked,
    globalState: state
  }
}

const mapDispatchToProps = dispatch => ({
  onShowAlert(options) {
    dispatch(showAlert(options))
  },

  onLoadFacility({ data, units, layers, userInterface, loadingDock, install }) {
    deselectObjects({})
    dispatch(
      loadFacility({ data, units, layers, userInterface, loadingDock, install })
    )
    dispatch(resetPanels())
    dispatch(ActionCreators.clearHistory())
  },

  onMount() {
    deselectObjects({})
    dispatch(setActiveTool({ tool: 'SELECT_TOOL' }))
  },

  onResetFacility() {
    dispatch(resetFacility())
    dispatch(resetPanels())
    dispatch(ActionCreators.clearHistory())
  },

  onSaveObjects({ versionId }) {
    dispatch(saveObjects({ versionId, saveLayers: true }))
  },

  showPanel(payload) {
    dispatch(showPanel(payload))
  },

  setDefaultPanel(payload) {
    dispatch(setDefaultPanel(payload))
    dispatch(showPanel(payload))
  },

  onGetQuote() {
    dispatch(start2DFit())
    dispatch(showPanel({ type: GET_QUOTE_PANEL }))
  },
})

const ComposedFacility = compose(
  hasPermission({
    name: 'View Facility',
    withPermissions: true,
  }),
  withProps(props => ({
    canViewFacility: props.canViewFacility || props.permissions.isAllowed,
    loading: props.loading || props.permissions.isLoading,
  })),
  hasPermission({
    name: 'View Template',
    withPermissions: true,
  }),
  withProps(props => ({
    canViewTemplate: props.canViewTemplate || props.permissions.isAllowed,
    loading: props.loading || props.permissions.isLoading,
  })),
  hasPermission({
    name: 'Edit Template',
    withPermissions: true,
  }),
  withProps(props => ({
    canEditTemplate: props.canEditTemplate || props.permissions.isAllowed,
    loading: props.loading || props.permissions.isLoading,
  })),
  appConnect(mapStateToProps, mapDispatchToProps),
  graphql(GET_FACILITY_QUERY, {
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
        floorId: match.params.floorId || null,
        versionId: match.params.versionId || null,
      },
    }),
    name: 'facilityData',
  }),
  // We use this query throughout the app assuming we have run it with
  // the versionId provided. So we need to cache the query with the versionId
  // Meaning we need to run this query a second time in case the first time
  // didn't have the versionId provided
  graphql(GET_FACILITY_QUERY, {
    // Wait to call this until we have the facility data
    skip: ({ facilityData }) =>
      get(facilityData, 'loading') || !get(facilityData, 'facility.id'),
    options: ({ match, facilityData }) => ({
      variables: {
        id: get(facilityData, 'facility.id'),
        floorId: get(facilityData, 'facility.floor.id'),
        versionId: get(facilityData, 'facility.floor.version.id'),
      },
    }),
    name: 'fullFacilityData',
  }),
  graphql(UPLOAD_SNAPSHOT_MUTATION, {
    props: ({ mutate }) => ({
      onSnapshot: variables => mutate({ variables }),
    }),
    options: ({ facilityData }) => ({
      refetchQueries: [
        {
          query: GET_FACILITY_QUERY,
          variables: {
            id: get(facilityData, 'facility.id'),
            floorId: get(facilityData, 'facility.floor.id'),
            versionId: get(facilityData, 'facility.floor.version.id'),
          },
          name: 'facilityData',
        },
      ],
    }),
  }),
  withRouter,
  withUser
)(FacilityScreen)

export default function FacilityScreenWithCFDUploadContext(props) {
  return <CFDUploadsProvider><ComposedFacility {...props}/></CFDUploadsProvider>
}
