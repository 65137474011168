import LAYER_KEYS from './layerKeys'

const CLICK_PRIORITY: Partial<Record<LAYER_KEYS, number>> = {
  [LAYER_KEYS.BACKGROUND_IMAGE]: 1,
  [LAYER_KEYS.EXTERIOR_WALLS]: 2,
  [LAYER_KEYS.INTERIOR_WALLS]: 3,
  [LAYER_KEYS.ROOFS]: 4,
  [LAYER_KEYS.ROOF_SECTIONS]: 5, // mounting structures
  [LAYER_KEYS.CEILINGS]: 6,
  [LAYER_KEYS.COMFORT_ZONES]: 7,
  [LAYER_KEYS.DOORS]: 9,
  [LAYER_KEYS.OBSTRUCTIONS]: 10,
  [LAYER_KEYS.UTILITY_BOXES]: 11,
  [LAYER_KEYS.DIMENSIONS]: 12, // annotations
  [LAYER_KEYS.PRODUCTS]: 13,
  [LAYER_KEYS.PRODUCTS_OVERHEAD]: 13,
  [LAYER_KEYS.PRODUCTS_DIRECTIONAL]: 13,
  [LAYER_KEYS.PRODUCTS_EVAP]: 13,
  [LAYER_KEYS.PRODUCTS_HEATERS]: 13,
  [LAYER_KEYS.GRID_BOX]: 14,
}

export default CLICK_PRIORITY
