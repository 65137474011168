import * as THREE from 'three'
import memoize from 'lodash-es/memoize'
import { type GLTF, GLTFLoader } from 'three/examples/jsm/Addons.js'
import DETAILED_OBSTRUCTIONS from 'config/detailedObstructions'
import Units from '~/components/DrawingCanvas/lib/units'

export const assetCache: Map<string, Promise<GLTF>> = new Map()

export function getMeshAsset(assetPath: string) {
  const asset = assetCache.get(assetPath)
  if (!asset) {
    const loadingAsset = new GLTFLoader().loadAsync(assetPath)
    assetCache.set(assetPath, loadingAsset)
    return loadingAsset
  } else {
    return asset
  }
}

const OVERHEAD_3D_MODELS = new Set([
  'Bravo',
  'Impulse',
  'Speakeasy',
  'Turbo6',
  'Powerfoil X3.0',
  'Powerfoil X4',
  'Powerfoil 8',
  'Powerfoil D',
  'Basic 6',
  'Essence',
  'i6',
  'ES6',
  'Haiku',
  'Existing HVLS',
  'Hornet',
])

const DIRECTIONAL_3D_MODELS = new Set([
  'Black Jack',
  'AirEye',
  'AirGo 2.0',
  'Sidekick',
  'Pivot 2.0',
])

const HEIGHT_VARIABLE_DIRECTIONAL_3D_MODELS = new Set(['Sweat Bee', 'Yellow Jacket'])

const PARTS = {
  blades: 'blades',
  blades_hub: 'blades_hub',
  tube: 'tube',
  mount: 'mount',
  hub: 'hub',
  motor: 'motor',
  cage: 'cage',
  bracket: 'bracket',
  pedestal: 'pedestal',
  tiltGroup: 'tilt_group',
  body: 'body',
  loop: 'loop',
}

const DEFAULT = 'default'

const OVERHEAD_PIVOT_2_0 = 'Pivot 2.0 overhead'

function animateOverhead(this: THREE.Object3D) {
  this.rotation.y = performance.now() * -0.001
}

function animateDirectional(this: THREE.Object3D) {
  this.rotation.z = performance.now() * -0.001
}

const isMeshWithGeometry = (
  object: THREE.Object3D<THREE.Object3DEventMap>
): object is THREE.Mesh<THREE.BufferGeometry> => {
  return object instanceof THREE.Mesh && object.geometry instanceof THREE.BufferGeometry
}

const setUpOverheadFan = (
  scene: THREE.Group<THREE.Object3DEventMap>,
  options: { tubeLength: number; diameter: number }
): void => {
  const { tubeLength, diameter } = options
  const tubeOffset = Units.inchesToNative(tubeLength)
  scene.traverse(object => {
    const [partName, partSize] = object.name.split('-')
    if (!partName || !partSize) return
    if (partName.includes(PARTS.blades)) {
      object.visible = partName === PARTS.blades_hub || partSize === diameter.toString()
      object.onBeforeRender = animateOverhead
    }
    if (isMeshWithGeometry(object)) {
      if (partName === PARTS.tube) {
        object.geometry.computeBoundingBox()
        const boundingBox = object.geometry.boundingBox!
        const assetTubeHeight = boundingBox.max.y - boundingBox.min.y
        const scaleFactor = 1 + tubeOffset / assetTubeHeight
        object.scale.setY(scaleFactor)
      } else if (partName === PARTS.mount) {
        object.position.setY(tubeOffset)
      }
      const isHaikuLowProfileElement = partSize.includes('lowprofile')
      const isHaikuUniversalElement = partSize.includes('universal')
      if (isHaikuLowProfileElement) {
        object.visible = tubeLength === 0
      } else if (isHaikuUniversalElement) {
        object.visible = tubeLength !== 0
        object.position.setY(tubeOffset)
      }
    }
  })
}

const setUpDirectionalFan = (
  scene: THREE.Group<THREE.Object3DEventMap>,
  options: {
    tiltGroup?: THREE.Object3D
    isMounted: boolean
    isDirectionalOverhead: boolean
    diameter?: number | typeof DEFAULT
    variation?: string
    tubeLength?: number
  }
): void => {
  const {
    tiltGroup,
    isMounted,
    isDirectionalOverhead,
    tubeLength = 0,
    diameter = DEFAULT,
    variation = DEFAULT,
  } = options
  scene.traverse(object => {
    const [partName, partVariation, partSize] = object.name.split('-')
    if (!partName || !partVariation || !partSize) return
    if (partName.includes(PARTS.blades)) object.onBeforeRender = animateDirectional
    const isMatchingVariation = variation === partVariation
    const isMatchingSize = partSize === diameter.toString()
    const isTiltGroupPart = partName === PARTS.tiltGroup
    if (isMounted) {
      const isMatchingPart = partName === PARTS.hub || partName === PARTS.motor
      object.visible = (isMatchingSize || isMatchingPart || isTiltGroupPart) && partVariation === DEFAULT
    } else if (isDirectionalOverhead) {
      const isMatchingPart = [PARTS.hub, PARTS.motor, PARTS.tube].includes(partName)
      object.visible = (isMatchingSize || isMatchingPart || isTiltGroupPart) && partVariation === DEFAULT
      if (partName === PARTS.tube && isMeshWithGeometry(object)) {
        object.geometry.computeBoundingBox()
        const boundingBox = object.geometry.boundingBox!
        const assetTubeHeight = boundingBox.max.y - boundingBox.min.y
        const tubeOffset = Units.inchesToNative(tubeLength)
        const scaleFactor = 1 + tubeOffset / assetTubeHeight
        object.scale.setY(scaleFactor)
      }
    } else if (partName.includes(PARTS.blades) || partName === PARTS.cage) {
      const isMatchingSize = partSize === DEFAULT || diameter.toString() === partSize
      object.visible = isMatchingVariation && isMatchingSize
    } else {
      object.visible = isMatchingVariation
    }
    if (tiltGroup && partName === PARTS.tiltGroup) object.rotateX(tiltGroup.rotation.x)
  })
}

const setUpVariableHeightDirectionalFan = (
  scene: THREE.Group<THREE.Object3DEventMap>,
  options: {
    diameter: number
    isMounted: boolean
    isDirectionalOverhead: boolean
    tubeLength: number
    tiltGroup?: THREE.Object3D
  }
): void => {
  const { diameter, tubeLength, isMounted, isDirectionalOverhead, tiltGroup } = options
  scene.traverse(object => {
    const [partName, partVariation, partSize] = object.name.split('-')
    if (!partName || !partVariation || !partSize) return
    if (partName.includes(PARTS.blades)) object.onBeforeRender = animateDirectional
    if (partName === PARTS.pedestal) {
      const floorLevel = -Units.inchesToNative(tubeLength)
      object.position.setY(floorLevel)
    }
    if (partName === PARTS.tube && isMeshWithGeometry(object)) {
      object.geometry.computeBoundingBox()
      const boundingBox = object.geometry.boundingBox!
      const assetTubeHeight = boundingBox.max.y - boundingBox.min.y
      const scaleFactor = 1 + Units.inchesToNative(tubeLength) / assetTubeHeight
      object.scale.setY(scaleFactor)
    }
    if (tiltGroup && partName === PARTS.tiltGroup) object.rotateX(tiltGroup.rotation.x)
    const isFloorPart = [PARTS.tube, PARTS.pedestal, PARTS.bracket].includes(partName)
    const isMatchingSize = partSize === DEFAULT || partSize === diameter.toString()
    const isVisible =
      isMounted || isDirectionalOverhead ? !isFloorPart && isMatchingSize : isMatchingSize
    object.visible = isVisible
  })
}

const setUpUnitHeater = (
  scene: THREE.Group<THREE.Object3DEventMap>,
  { heaterData }: { heaterData: { boxWidthB: number; boxDepthF: number; boxHeightA: number } }
): void => {
  const { boxDepthF, boxHeightA, boxWidthB } = heaterData
  scene.scale.set(
    Units.inchesToNative(boxWidthB),
    Units.inchesToNative(boxHeightA),
    Units.inchesToNative(boxDepthF)
  )
}

const setUpIRH = (
  scene: THREE.Group<THREE.Object3DEventMap>,
  { size, rotation }: { size: number; rotation: THREE.Vector3 }
): THREE.Group<THREE.Object3DEventMap> => {
  const targetWidth = Units.inchesToNative(size)
  scene.traverse(object => {
    if (object.name === PARTS.body) {
      const boundingBox = new THREE.Box3().setFromObject(object)
      const width = Math.abs(boundingBox.max.x - boundingBox.min.x)
      const scaleFactor = targetWidth / width
      object.scale.setX(scaleFactor)
    }
    if (object.name === PARTS.loop) {
      object.translateX(targetWidth)
    }
  })
  const boundingBox = new THREE.Box3().setFromObject(scene)
  const width = Math.abs(boundingBox.max.x - boundingBox.min.x)
  scene.translateX(-width / 2)
  const parent = new THREE.Group()
  parent.add(scene)
  scene.rotateX(THREE.MathUtils.degToRad(rotation.x))
  parent.rotateY(THREE.MathUtils.degToRad(rotation.y))
  return parent
}

export async function _buildProductMesh({
  model,
  mountStyle,
  size,
  tubeLength,
  root,
  tiltGroup,
  isMounted,
  isDirectionalOverhead,
  rotation,
  heaterData,
}: {
  model: string
  mountStyle: { style: string; option: { label: string } }
  size: number
  tubeLength: number
  root: THREE.Object3D
  tiltGroup: THREE.Object3D
  isMounted: boolean
  isDirectionalOverhead: boolean
  rotation: THREE.Vector3
  heaterData: { boxWidthB: number; boxDepthF: number; boxHeightA: number }[]
}) {
  // Pivot 2.0 is a special case because it can be considered overhead or directional, depending on configuration
  const modelName = model === 'Pivot 2.0' && isDirectionalOverhead ? OVERHEAD_PIVOT_2_0 : model
  const gltf = await getMeshAsset(new URL(`/src/assets/gltf/products/${modelName}.glb`, import.meta.url).href)
  const scene = gltf.scene.clone(true)
  if (OVERHEAD_3D_MODELS.has(modelName) || modelName === OVERHEAD_PIVOT_2_0) {
    if (modelName === OVERHEAD_PIVOT_2_0) {
      scene.getObjectByName('tilt_group-default')?.rotateX(tiltGroup.rotation.x - (Math.PI * 3) / 2)
    }
    setUpOverheadFan(scene, { tubeLength, diameter: size })
  } else if (DIRECTIONAL_3D_MODELS.has(modelName)) {
    const isTilting = modelName !== 'Black Jack' && modelName !== 'Sidekick'
    setUpDirectionalFan(scene, {
      tiltGroup: isTilting ? tiltGroup : undefined,
      diameter: size,
      variation: mountStyle?.style,
      isMounted,
      isDirectionalOverhead,
      tubeLength,
    })
  } else if (HEIGHT_VARIABLE_DIRECTIONAL_3D_MODELS.has(modelName)) {
    setUpVariableHeightDirectionalFan(scene, {
      diameter: size,
      tubeLength,
      isMounted,
      isDirectionalOverhead,
      tiltGroup,
    })
  } else if (modelName === 'Unit Heater') {
    setUpUnitHeater(scene, { heaterData: heaterData[0] })
  } else if (modelName === 'IRH Straight' || modelName === 'IRH U-Tube') {
    const parent = setUpIRH(scene, { rotation, size })
    root.add(parent)
    return { ...gltf, scene }
  }
  root.add(scene)
  return { ...gltf, scene }
}

export async function _buildObstructionMesh({ model, root }: { model: string; root: THREE.Object3D }) {
  try {
    // Load gltf asset
    const gltf = await getMeshAsset(`/gltf/${model}.gltf`)

    // Get meshes from model
    let modelMeshes = getModelMeshes(model, gltf).filter(
      mesh => mesh.type !== 'Group'
    )

    // Get parts from mesh
    const parts = {} as Partial<Record<string, THREE.Mesh | THREE.Group>>
    modelMeshes.forEach(mesh => {
      parts[mesh.name] = mesh.clone() as THREE.Mesh | THREE.Group
    })

    // Combine parts with root node
    Object.keys(parts).forEach(function(key) {
      root.add(parts[key]!.clone())
    })

    scaleObstructionBasedOnModel(model, root)

    return gltf
  } catch (err) {
    throw err
  }
}

function scaleObstructionBasedOnModel(model: string, root: THREE.Object3D) {
  const { scale, rotation } =
    DETAILED_OBSTRUCTIONS.find(obs => obs.obstructionType === model) || {}
  if (scale) {
    root.scale.set(scale.x, scale.y, scale.z)
  }
  if (rotation) {
    root.rotation.set(rotation.x, rotation.y, rotation.z)
  }
}

if (window.Map) {
  memoize.Cache = window.Map
}
export const buildProductMesh = memoize(_buildProductMesh, (...args: any) =>
  JSON.stringify(args)
)

export const buildObstructionMesh = memoize(_buildObstructionMesh, (...args: any) =>
  JSON.stringify(args)
)

function getModelMeshes(model: string, gltf: GLTF) {
  let meshes: THREE.Object3D[] = []
  let meshNames = []
  model = model.split('.').join('')

  gltf.scene.traverse(e => {
    if (e instanceof THREE.Mesh || e instanceof THREE.Group) {
      e.name = e.name.split('_').join(' ')
      e.name = e.name.split('.').join('')

      if ('material' in e && e.material && e.material instanceof THREE.MeshStandardMaterial) {
        e.material = e.material.clone() as THREE.MeshStandardMaterial
        if (!(e.material instanceof THREE.MeshStandardMaterial))
          throw new Error("invalid material after cloning")
        if (e.material.normalScale) {
          e.material.normalScale = new THREE.Vector2(1, 1)
        }
        if (e.material.map) e.material.map.needsUpdate = true
        e.material.needsUpdate = true
      }

      if ('geometry' in e && e.geometry) e.castShadow = true
      meshes.push(e)
      meshNames.push(e.name)
    }
  })

  const modelMeshes = meshes.slice(0).filter(e => e.name.indexOf(model) === 0)

  return modelMeshes
}
