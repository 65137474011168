export const LayerKeys = {
  AIRFLOW: 'AIRFLOW',
  BACKGROUND_IMAGE: 'BACKGROUND_IMAGE',
  CEILINGS: 'CEILINGS',
  CFD: 'CFD',
  COLUMNS: 'COLUMNS',
  COMFORT_ZONES: 'COMFORT_ZONES',
  DIMENSION_LINES: 'DIMENSION_LINES',
  DIMENSIONS: 'DIMENSIONS',
  DOORS: 'DOORS',
  ELEVATION_LINE: 'ELEVATION_LINE',
  ELEVATION_POINT: 'ELEVATION_POINT',
  EXTERIOR_WALLS: 'EXTERIOR_WALLS',
  GRID_BOX: 'GRID_BOX',
  INTERIOR_WALLS: 'INTERIOR_WALLS',
  NOTES: 'NOTES',
  OBSTRUCTIONS: 'OBSTRUCTIONS',
  PRIMARY_MOUNTING_STRUCTURE: 'PRIMARY_MOUNTING_STRUCTURE',
  PRODUCT_HEIGHTS: 'PRODUCT_HEIGHTS',
  PRODUCTS: 'PRODUCTS',
  ROOF_SECTIONS: 'ROOF_SECTIONS',
  ROOFS: 'ROOFS',
  SECONDARY_MOUNTING_STRUCTURE: 'SECONDARY_MOUNTING_STRUCTURE',
  UTILITY_BOXES: 'UTILITY_BOXES',
  FACILITY: 'FACILITY',
  HEAT_MAP: 'HEAT_MAP',
  PRODUCTS_OVERHEAD: 'PRODUCTS_OVERHEAD',
  PRODUCTS_DIRECTIONAL: 'PRODUCTS_DIRECTIONAL',
  PRODUCTS_HEATERS: 'PRODUCTS_HEATERS',
  PRODUCTS_EVAP: 'PRODUCTS_EVAP',
  MOUNTING_STRUCTURE_GUIDELINES: 'MOUNTING_STRUCTURE_GUIDELINES',
} as const
export type LayerKeys = (typeof LayerKeys)[keyof typeof LayerKeys]
export const AllLayerKeys = Object.freeze(Object.keys(LayerKeys) as LayerKeys[])
export default LayerKeys
